import { Component, OnInit } from '@angular/core';
import { MenuItem, MessageService } from 'primeng/api';
import { TranslateService } from "@ngx-translate/core";
import { Subject, takeUntil } from 'rxjs';
import { UserModel } from "../share-modul/models/user/user.model";
import { AuthenticationService } from "../share-modul/services/authentication/authentication.service";
import { ProductModel } from "../share-modul/models/product/product-model";
import { DropdownHelperModel } from "../share-modul/models/helpers/dropdown-helper.model";
import {AccessProjectsService} from "../socialite-manager-panel/services/products/access-projects.service";

@Component({
  selector: 'app-navbar',
  templateUrl: './socialite-manager-navbar.component.html',
  styleUrls: ['./socialite-manager-navbar.component.scss']
})
export class SocialiteManagerNavbarComponent implements OnInit {

  //#region Fields
  public items: MenuItem[];
  public unsubscribe: Subject<void> = new Subject<void>()
  public venues: Array<DropdownHelperModel> = new Array<DropdownHelperModel>();
  public imagePath: string = './assets/images/logo/shop icon.png';
  public user: UserModel | null;
  public inMenu: boolean = true;
  public productList: Array<ProductModel> = new Array<ProductModel>();
  //#endregion

  //#region Constructor
  constructor(
    public translate: TranslateService,
    public messageService: MessageService,
    public authenticationService: AuthenticationService,
    private accessProjectsService: AccessProjectsService,
  ) {
    this.authenticationService.showHideMenu.subscribe(response => {
      this.inMenu = response;
    })
  }

  ngOnInit(): void {
    this.authenticationService.loginUser.pipe(takeUntil(this.unsubscribe)).subscribe(user => {
      this.user = user;
    });
    this.productList =  this.accessProjectsService.getAllProjectsDetails();
  }
  //#endregion

  //#region Public Methods
  //#endregion
}
