import { Component, OnInit } from '@angular/core';
import { Subject, takeUntil } from "rxjs";
import { Message, MessageService } from "primeng/api";
import { AuthenticationService } from "../../services/authentication/authentication.service";
import { ResetPasswordModel } from "../../models/reset-password/reset-password.model";
import { NgbTooltip } from "@ng-bootstrap/ng-bootstrap";
import { ActivatedRoute, Router } from "@angular/router";
import { UserModel } from "../../models/user/user.model";
import { JwtHelperService } from "@auth0/angular-jwt";
import { VerifyEmailModel } from '../../models/verify-email/verify-email.model';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  //#region Fields
  public unsubscribe: Subject<void> = new Subject<void>()
  public resetPasswordModel: ResetPasswordModel = new ResetPasswordModel();
  public errorModel: { [k: string]: any } = {};
  public errorMsg: Message[];
  public spinner = false;
  public forgotEmailToken = '';
  public verifyEmailToken = '';
  public requiredFieldErrorMessage = "INFO.REQUIRED_FIELD";
  public invalidPasswordErrorMessage = "INFO.INVALID_PASSWORD";
  public passwordMatchErrorMessage = "INFO.PASSWORD_MATCH";
  //#endregion
  //#region Constructor
  constructor(
    public authenticationService: AuthenticationService,
    public messageService: MessageService,
    private route: ActivatedRoute,
    private _router: Router,
    private _jwtHelperService: JwtHelperService
  ) {
    this.route.queryParams.subscribe(params => {
      if (params['confirmation_token']) {
        this.forgotEmailToken = params['confirmation_token'];
      } else if(params['verify_token']){
        this.verifyEmailToken = params['verify_token'];
      }
    });
  }

  ngOnInit(): void {
    if(this.verifyEmailToken != ''){
      this.verifyEmail(this.verifyEmailToken);
    }
  }
  //#endregion

  //#region Public Methods 
  resetPassword(model: ResetPasswordModel): void {
    this.errorModel = {};
    this.errorMsg = [];

    model.forgotEmailToken = this.forgotEmailToken;
    model.languageId = this.languageIdFromLocalStorage();

    if (model && (!model.password || model.password.trim() == "")) {
      this.errorModel['Password'] = this.requiredFieldErrorMessage;
    } else if (!this.isPasswordValid()) {
      this.errorModel['Password'] = this.invalidPasswordErrorMessage;
    }
    if (model && (!model.confirmPassword || model.confirmPassword.trim() == "")) {
      this.errorModel['ConfirmPassword'] = this.requiredFieldErrorMessage;
    }
    if (model && model.confirmPassword !== model.password) {
      this.errorModel['ConfirmPassword'] = this.passwordMatchErrorMessage;
    }

    if (Object.keys(this.errorModel).length === 0) {
      this.authenticationService.confirmPassword(model)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe({
          next: (response) => {
            localStorage.setItem('access_token', response.jwtToken);
            localStorage.setItem('refresh_token', response.refreshToken);
            const decodedToken = this._jwtHelperService.decodeToken(response.jwtToken);
            const loggedInUser = new UserModel(decodedToken.id, decodedToken.rlnm.split(',').map((x: string) => +x));
            this.authenticationService.loginUser.next(loggedInUser);
            const cmid = decodedToken.cmid;
            const isAdvertiserPaid = decodedToken.iap != undefined ? JSON.parse(decodedToken.iap.toLowerCase()) : false;
            const isResyPaid = decodedToken.irp != undefined ? JSON.parse(decodedToken.irp.toLowerCase()) : false
            const IsPOSPaid = decodedToken.ipp != undefined ? JSON.parse(decodedToken.ipp.toLowerCase()) : false
          
            if (cmid) {
              if (isAdvertiserPaid || isResyPaid || IsPOSPaid) {
                this._router.navigate(['manage-venues']);
              } else {
                this._router.navigate(['products']);
              }
            }
            else {
              this._router.navigate(['company-info']);
            }
          },
          error: errorResponse => {
            if (errorResponse.error && errorResponse.error.Message) {
              this.messageService.add({severity: 'error', summary: errorResponse.error.Message, life: 2000});
            } else if (errorResponse.error && !errorResponse.error.errors) {
              this.messageService.add({severity: 'error', summary: errorResponse.error.title, life: 2000});
            }
          }
        });
    }
  }

  public hasUppercaseLetter(): boolean {
    return this.resetPasswordModel.password !== undefined && (/[A-Z]/).test(this.resetPasswordModel.password);
  }

  public hasLowercaseLetter(): boolean {
    return this.resetPasswordModel.password !== undefined && (/[a-z]/).test(this.resetPasswordModel.password);
  }

  public hasSpecialCharacter(): boolean {
    return this.resetPasswordModel.password !== undefined && (/[!$%@#£€*?&.,:]/).test(this.resetPasswordModel.password);
  }

  public has8Characters(): boolean {
    return this.resetPasswordModel.password !== undefined && (/.{8,}/).test(this.resetPasswordModel.password);
  }

  public openTooltip(tipContent: NgbTooltip): void {
    if (!tipContent.isOpen()) {
      tipContent.open();
    }
  }

  public closeTooltip(tipContent: NgbTooltip): void {
    tipContent.close();
  }

  public isPasswordValid(): boolean {
    return this.hasLowercaseLetter() && this.hasUppercaseLetter() && this.hasSpecialCharacter();
  }

  verifyEmail(verifyToken:string) {
    this.spinner = true;
    const verifyEmailModel = new VerifyEmailModel();
    verifyEmailModel.verificationToken = verifyToken;
    verifyEmailModel.languageId = this.languageIdFromLocalStorage();

    this.authenticationService.verifyEmail(verifyEmailModel)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe({
          next: (response) => {
            localStorage.setItem('access_token', response.jwtToken);
            localStorage.setItem('refresh_token', response.refreshToken);
            const decodedToken = this._jwtHelperService.decodeToken(response.jwtToken);
            const loggedInUser = new UserModel(decodedToken.id, decodedToken.rlnm.split(',').map((x: string) => +x));
            this.authenticationService.loginUser.next(loggedInUser);
            const cmid = decodedToken.cmid;
            const isAdvertiserPaid = decodedToken.iap != undefined ? JSON.parse(decodedToken.iap.toLowerCase()) : false;
            const isResyPaid = decodedToken.irp != undefined ? JSON.parse(decodedToken.irp.toLowerCase()) : false
            const IsPOSPaid = decodedToken.ipp != undefined ? JSON.parse(decodedToken.ipp.toLowerCase()) : false
          
            if (cmid) {
              if (isAdvertiserPaid || isResyPaid || IsPOSPaid) {
                this._router.navigate(['manage-venues']);
              } else {
                this._router.navigate(['products']);
              }
            }
            else {
              this._router.navigate(['company-info']);
            }
          },
          error: errorResponse => {
            if (errorResponse.error && errorResponse.error.Message) {
              this.messageService.add({severity: 'error', summary: errorResponse.error.Message, life: 2000});
            } else if (errorResponse.error && !errorResponse.error.errors) {
              this.messageService.add({severity: 'error', summary: errorResponse.error.title, life: 2000});
            }
          }
        });
  }

  public languageIdFromLocalStorage(): number {
    const langIdFromStorage = localStorage.getItem('languageId');

    if (langIdFromStorage) {
      return parseInt(langIdFromStorage);
    } else {
      return 1;
    }
  }
  //#endregion
}
