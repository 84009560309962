export class SignupModel {
  public firstName: string;
  public lastName: string;
  public password: string;
  public confirmPassword: string;
  public email: string;
  public phone: string;
  public address: string;
  public country: string;
  public languageId: number;
  public termsAndConditionsAccepted: boolean;
  public termsAndConditionsVersion: string;
}
