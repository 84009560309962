import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlight_hashtags'
})
export class HighlightHashtagsPipe implements PipeTransform {
  transform(value: string): string {
    const regex = new RegExp('#.*');
    return value.replace(regex, '<span class="highlight-hashtags">$&</span>');
  }
}
