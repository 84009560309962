import {Component, OnInit} from '@angular/core';
import {Subject, takeUntil} from "rxjs";
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {UploadImageModel} from "../../../models/image/upload-image.model";
import {ManageVenuesService} from "../../../../socialite-manager-panel/services/venues/venues.service";
import {MessageService} from "primeng/api";
import {DeleteGaleryImageModel} from "../../../../socialite-manager-panel/models/venues/delete-galery-image.model";

@Component({
  selector: 'app-delete-images-confirm',
  templateUrl: './delete-images-confirm.component.html',
  styleUrls: ['./delete-images-confirm.component.scss']
})
export class DeleteImagesConfirmComponent implements OnInit {

  //#region Fields
  public venueId: string;
  public imagesToDelete: Array<UploadImageModel> = [];
  public spinner: boolean = false;
  public unsubscribe: Subject<void> = new Subject<void>();
  //#endregion

  //#region Constructor
  constructor(
    private config: DynamicDialogConfig,
    private ref: DynamicDialogRef,
    private venueService: ManageVenuesService,
    private messageService: MessageService) {
    if (this.config.data != undefined && this.config.data.images && this.config.data.venueId) {
      this.imagesToDelete = this.config.data.images;
      this.venueId = this.config.data.venueId;
    }
  }

  public ngOnInit(): void {
  }

  //#endregion

  //#region Public Methods
  public deleteImages(): void {
    this.spinner = true;
    const model = new DeleteGaleryImageModel();
    model.venueId = this.venueId;
    model.venueGaleryImageIds = this.imagesToDelete.map(x => x.imageId);
    // this.venueService.deleteImagesFromVenueGalery(model)
    //   .pipe(takeUntil(this.unsubscribe))
    //   .subscribe({
    //     next: _ => {
    //       this.spinner = false;
    //       this.ref.close(this.imagesToDelete);
    //     },
    //     error: _ => {
    //       this.spinner = false;
    //       this.messageService.add({severity: 'error', summary: "System error", life: 2000});
    //     }
    //   });
  }

  public close(): void {
    this.ref.close(false);
  }

  //#endregion
}
