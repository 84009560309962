import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subject, takeUntil } from 'rxjs';
import { ManageVenuesService } from "../../../services/venues/venues.service";

@Component({
  selector: 'app-delete-venue',
  templateUrl: './delete-venue.component.html',
  styleUrls: ['./delete-venue.component.scss']
})
export class DeleteVenueComponent implements OnInit {

  //#region Fields
  public venueId: string;
  public spinner: boolean = false;
  public unsubscribe: Subject<void> = new Subject<void>();
  //#endregion

  //#region Constructor
  constructor(
    private config: DynamicDialogConfig,
    private ref: DynamicDialogRef,
    private venueService: ManageVenuesService,
    private messageService: MessageService
  ) {
    if (this.config.data != undefined && this.config.data.id) {
      this.venueId = this.config.data.id;
    }
   }

  ngOnInit(): void {
  }
  //#endregion

  //#region Public Methods
  deleteVenue(venueId: string) {
    this.spinner = true;
    this.venueService.deletevenue(venueId)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: _ => {
          this.ref.close(true)
        },
        error: _ => {
          this.spinner = false;
          this.messageService.add({ severity: 'error', summary: "System error", life: 2000 });
        }
      });
  }

  cancel(): void {
    this.ref.close(false);
  }
  //#endregion
}
