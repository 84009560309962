<div class="div-products-container">
  <div class="back-to-products"><a (click)="goBack()">
    <i class="pi pi-arrow-left me-2"></i><span>{{'PRODUCT.BACK' | translate}}</span></a>
  </div>
  <div style="width: 70vw; align-self: center;">
    <div class="products-header">
      <span class="title">{{productDetails.name | uppercase}}</span>
      <span class="desc">{{productDetails.description}}</span>
    </div>
    <ng-container *ngIf="isTrialActive">
      <div class="go-to-app-container">
        <a href="{{productDetails.url}}" target="_self" class="go-to-app-text">
          <span>{{'PRODUCT.GO_TO_APP' | translate}}</span>
        </a>
      </div>
      <div class="div-trial-plan">
        <label ><span>{{'PRODUCT.CURRENT_PLAN' | translate}}</span> : Trial</label>
        <label ><span>{{'PRODUCT.MEMBER_SINCE' | translate}}</span>: {{TrialMemberSince | date: 'dd-MM-YYYY'}}</label>
      </div>
    </ng-container>
    <ng-container *ngIf="activePlan == undefined">
      <div class="w-100 special-offer-container" *ngIf="specialOffer">
        <app-special-offer [specialOffer]="specialOffer"></app-special-offer>
      </div>
      <div class="div-list-plans">
        <div *ngFor="let p of productDetails.plans" class="vertical-component-card">
          <div class="component-list-card h-100 d-flex flex-column">
            <div class="text-center plan-text mb-2">
              <label class="lbl-name">{{p.name}}</label>
              <label class="lbl-price">${{p.defaultMonthlyPrice}}</label>
              <label class="lbl-price">${{p.defaultYearlyPrice}}</label>
              <div class="min-h-title">
                <label class="lbl-title  w-100">{{p.title}}</label>
                <label class="lbl-desc">{{p.description}}</label>
              </div>
              <label class="lbl-num">{{'PRODUCT.VENUES' | translate | titlecase}}</label>
              <label class="lbl-num-of-ven">{{p.minNumberOfVenues}} - {{p.maxNumberOfVenues}}</label>
              <div *ngFor="let country of p.countryNames">
                <label class="lbl-title w-100">{{country}}</label>
              </div>
            </div>
            <div class="mt-auto">
              <button class="create-btn buy-btn" (click)="openPaymentDialog(p)">{{'PRODUCT.BUY' |translate}}</button>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="activePlan ">
      <div class="go-to-app-container">
        <a href="{{productDetails.url}}" target="_self" class="go-to-app-text">
          <span>{{'PRODUCT.GO_TO_APP' | translate}}</span>
        </a>
      </div>
      <div  class="div-payment-info-container mt-4">
        <div class="div-current-plan">
            <label class="lbl-title">{{'PRODUCT.CURRENT_PLAN' | translate}}</label>
            <div class="current-plan">
                <div class="d-flex flex-row">
                    <label class="lbl-info">{{'PRODUCT.WEEKLY' | translate}}</label>
                    <label class="ps-2 current-plan-price">{{paymentInformation.currentPlan}}</label>
                </div>
                <div class="d-flex flex-row mob-m w-45">
                    <div class="ms-4 me-4 w-50">
                        <button class="btn-gradient" (click)="goToUpgradePlans(productId)">{{'PRODUCT.UPGRADE' | translate}}</button>
                    </div>
                    <div class="w-50">
                        <button class="btn-outline-transparent cyan-color" (click)="openCancelSubscription()">{{'PRODUCT.CANCEL_SUBSCRIPTION' | translate}}</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="div-status">
            <label class="lbl-title">{{'PRODUCT.STATUS' | translate}}</label>
            <div class="d-flex flex-column mt-2 mb-2">
              <label class="lbl-info">{{'PRODUCT.MEMBER_SINCE' | translate}}: {{paymentInformation.memberDate | date: 'dd-MM-YYYY'}}</label>
              <label class="lbl-info">{{'PRODUCT.VALID_UNTIL' | translate}}: {{paymentInformation.validUntil | date: 'dd-MM-YYYY'}}</label>
              <label class="lbl-info">{{'PRODUCT.COUNTRY' | translate}}: {{paymentInformation.country}}</label>
            </div>
        </div>
        <div *ngIf="paymentInformation.invoices" class="div-invoices">
            <label class="lbl-title">{{'PRODUCT.INVOICES' | translate}}</label>
            <div class="invoice-list mt-2 mb-2">
                <div *ngFor="let invoice of paymentInformation.invoices" class="invoice-card d-flex flex-row justify-content-between align-items-center mt-2">
                    <div>
                        <div><label class="lbl-info">{{invoice.currencyCode}} {{invoice.amount}} / <span class="gray-info">{{invoice.date | date: 'dd-MM-YYYY'}}</span></label></div>
                        <div><label class="lbl-info green-info">{{invoice.status}}</label></div>
                    </div>
                    <div class="w-20">
                        <button class="btn-outline-transparent magenta-color">{{'PRODUCT.VIEW' | translate}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </ng-container>
  </div>
</div>
