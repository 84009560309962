<div *ngIf="forgotEmailToken" class="bg_login d-flex">
  <div class="w-100 h-100 login_div flex-column">
    <div class="w-100 logo_div">
      <div class="logo"></div>
    </div>
    <div class="login_form">
      <h3 class="sign_up d-flex justify-content-between mb-3">{{'INFO.RESET_PASSWORD' | translate}}</h3>
      <p class="m-0 mb-1 text-center">{{'INFO.RESET_PASSWORD_MESSAGE' | translate}}</p>
      <div class="login_field">
        <label class="lbl_field">
          <i *ngIf="errorModel!=null && errorModel['Password']!=null"
             class="pi pi-exclamation-circle float-end me-1 error_color pt-1"
             pTooltip="{{errorModel['Password']|translate}}" tooltipPosition="top"></i>
        </label>
        <p-password [(ngModel)]="resetPasswordModel.password"
                    [toggleMask]="true"
                    [feedback]="false"
                    [styleClass]="'p-password p-component p-inputwrapper p-input-icon-right'"
                    [id]="'sign_up_password_filed'"
                    (click)="openTooltip(t1)"
                    (mouseleave)="closeTooltip(t1)"
                    (mouseenter)="openTooltip(t1)"
                    [inputStyleClass]="'input_field password-input-field'"
                    [ngbTooltip]="passwordInfoTooltipContent"
                    [animation]="false"
                    placeholder="{{'SIGN_UP.PASSWORD'|translate}}"
                    placement="end"
                    triggers="manual"
                    tooltipClass="signup-password-tooltip" #t1="ngbTooltip" [autoClose]="false"></p-password>

        <label class="lbl_field">
          <i *ngIf="errorModel!=null && errorModel['ConfirmPassword']!=null"
             class="pi pi-exclamation-circle float-end me-1 error_color pt-1"
             pTooltip="{{errorModel['ConfirmPassword']|translate}}" tooltipPosition="top"></i>
        </label>
        <p-password [(ngModel)]="resetPasswordModel.confirmPassword"
                    [feedback]="false"
                    [toggleMask]="true"
                    [styleClass]="'p-password p-component p-inputwrapper p-input-icon-right'"
                    [id]="'sign_up_password_filed'"
                    placeholder="{{'SIGN_UP.CONFIRM_PASSWORD'|translate}}"
                    [inputStyleClass]="'input_field password-input-field'">
        </p-password>
        <button class="btn_login_manager mt-3" (click)="resetPassword(resetPasswordModel)">{{'INFO.RESET_PASSWORD_BTN'|translate}}</button>
      </div>
    </div>
  </div>
</div>

<ng-template #passwordInfoTooltipContent>
  <div class="password-tooltip-content">
    <span>{{'INFO.PASSWORD_PATTERN_MESSAGE_1' | translate}} </span>
    <span [class.password-rule-checked]="has8Characters()">{{'INFO.PASSWORD_PATTERN_MESSAGE_2' | translate}} </span>
    <span>{{'INFO.PASSWORD_PATTERN_MESSAGE_3' | translate}}</span>
    <ul class="m-0">
      <li [class.password-rule-checked]="hasUppercaseLetter()">{{'INFO.PASSWORD_1_UPPERCASE' | translate}}</li>
      <li [class.password-rule-checked]="hasLowercaseLetter()">{{'INFO.PASSWORD_1_LOWERCASE' | translate}}</li>
      <li [class.password-rule-checked]="hasSpecialCharacter()">{{'INFO.PASSWORD_SPECIAL_CHAR' | translate}}
      </li>
    </ul>
  </div>
</ng-template>
