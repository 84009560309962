import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'remove_highlight'
})
export class RemoveHighlightPipe implements PipeTransform {
  transform(value: string): string {
    const regex = new RegExp('\\bSOCIALITE\\b', 'gi');
    return value.replace(regex, '<span class="remove-highlight">$&</span>');
  }
}
