import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PrimeNgModule } from '../prime-ng/prime-ng.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoginComponent } from 'src/app/share-modul/components/login/login.component';
import { PageNotFoundComponent } from 'src/app/share-modul/components/page-not-found/page-not-found.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { HeaderComponent } from 'src/app/share-modul/components/layout/header/header.component';
import { ChangeLanguageComponent } from 'src/app/share-modul/components/change-language/change-language.component';
import { SignupComponent } from "../../share-modul/components/signup/signup.component";
import { ResetPasswordComponent } from "../../share-modul/components/reset-password/reset-password.component";
import { ForgotPasswordComponent } from "../../share-modul/components/forgot-password/forgot-password.component";
import { ImageCropperModule } from "ngx-image-cropper";
import {
  CropAndCompressImageDialogComponent
} from "../../share-modul/components/crop-and-compress-image-dialog/crop-and-compress-image-dialog.component";
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import {
  ChangePasswordDialogComponent
} from "../../share-modul/components/change-password-dialog/change-password-dialog.component";
import { CompanyInfoFormComponent } from "../../share-modul/components/company-info-form/company-info-form.component";
import { RemoveHighlightPipe } from "../../share-modul/pipes/remove-highlight.pipe";
import {
  MultipleImagesUploadComponent
} from "../../share-modul/components/multiple-images-upload/multiple-images-upload.component";
import { DragNDropDirective } from "../../share-modul/directives/drag-n-drop.directive";
import {
  DeleteImagesConfirmComponent
} from "../../share-modul/components/multiple-images-upload/delete-images-confirm/delete-images-confirm.component";
import { EditProfileComponent } from "../../share-modul/components/edit-profile/edit-profile.component";
import { DeleteAccountDialogComponent } from 'src/app/share-modul/components/deactive-account-dialog/deactive-account-dialog.component';
import { LoginWithRefreshTokenComponent } from 'src/app/share-modul/components/login-with-refresh-token/login-with-refresh-token.component';
import { TermsAndConditionsDialogComponent } from 'src/app/share-modul/components/terms-and-conditions-dialog/terms-and-conditions-dialog.component';
import {HighlightHashtagsPipe} from "../../share-modul/pipes/highlight-hashtags.pipe";

// Http Loader for ngx-translate
export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
@NgModule({
  declarations: [
    LoginComponent,
    LoginWithRefreshTokenComponent,
    PageNotFoundComponent,
    HeaderComponent,
    ChangeLanguageComponent,
    SignupComponent,
    ResetPasswordComponent,
    ForgotPasswordComponent,
    CropAndCompressImageDialogComponent,
    ChangePasswordDialogComponent,
    DeleteAccountDialogComponent,
    CompanyInfoFormComponent,
    RemoveHighlightPipe,
    HighlightHashtagsPipe,
    MultipleImagesUploadComponent,
    DragNDropDirective,
    DeleteImagesConfirmComponent,
    EditProfileComponent,
    TermsAndConditionsDialogComponent,
  ],
  imports: [
    ReactiveFormsModule,
    FormsModule,
    PrimeNgModule,
    CommonModule,
    NgbModule  ,
    ImageCropperModule,
    InfiniteScrollModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        deps: [HttpClient],
        useFactory: createTranslateLoader
      }
    }),
  ],
  exports: [
    LoginComponent,
    LoginWithRefreshTokenComponent,
    PageNotFoundComponent,
    HeaderComponent,
    ChangeLanguageComponent,
    ReactiveFormsModule,
    FormsModule,
    PrimeNgModule,
    NgbModule,
    TranslateModule,
    CommonModule,
    ImageCropperModule,
    InfiniteScrollModule,
    CropAndCompressImageDialogComponent,
    RemoveHighlightPipe,
    HighlightHashtagsPipe,
    MultipleImagesUploadComponent,

  ]
})
export class SharedModule {}
