<div class="panel-wrapper">
  <div class="display-block-mbl d-flex flex-row justify-content-between pb-2">
    <div class="w-50 w-100-mbl">
      <label class="lbl_field_small col-12"> 
        <span class="lbl_field_name">{{'COMPANY_INFO.NAME_LABEL' | translate}}</span>
        <i *ngIf="errorModel != null && errorModel['Name'] != null" class="invalid_input_text">
          {{errorModel['Name'] | translate}}
        </i>
      </label>
      <input type="text" class="soc-manager-input-field" [(ngModel)]="companyModel.companyName"
        placeholder="{{'COMPANY_INFO.NAME' | translate}}">
    </div>
    <div class="w-50 w-100-mbl">
      <label class="lbl_field_small col-12">
        <span class="lbl_field_name">{{'COMPANY_INFO.ADDRESS_LABEL' | translate}}</span>
        <i *ngIf="errorModel != null && errorModel['Address'] != null" class="invalid_input_text">
          {{errorModel['Address'] | translate}}
        </i>
      </label>
      <input type="text" class="soc-manager-input-field" [(ngModel)]="companyModel.address"
        placeholder="{{'COMPANY_INFO.ADDRESS' | translate}}">
    </div>
  </div>
  <div class="display-block-mbl d-flex flex-row justify-content-between pb-2">
    <div class="w-50 w-100-mbl">
      <label class="lbl_field_small col-12">
        <span class="lbl_field_name">{{'COMPANY_INFO.EMAIL_LABEL' | translate}}</span>
        <i *ngIf="(errorModel != null && errorModel['Email'] != null) && (!email.touched || !email.errors?.pattern)"
          class="invalid_input_text">
          {{errorModel['Email'] | translate}}
        </i>
        <i *ngIf="email.touched && email.errors?.pattern" class="invalid_input_text">
          {{'INFO.INVALID_EMAIL' | translate}}
        </i>
      </label>
      <input type="text" class="soc-manager-input-field" [(ngModel)]="companyModel.email"
        placeholder="{{'COMPANY_INFO.EMAIL'| translate}}" #email=ngModel required
        pattern="^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$">
    </div>

    <div class="w-50 w-100-mbl">
      <label class="lbl_field_small col-12">
        <span class="lbl_field_name">{{'COMPANY_INFO.PHONE_LABEL' | translate}}</span>
        <i *ngIf="errorModel != null && errorModel['Phone'] != null" class="invalid_input_text">
          {{errorModel['Phone'] | translate}}
        </i>
      </label>
      <input type="text" class="soc-manager-input-field" [(ngModel)]="companyModel.phone"
        placeholder="{{'COMPANY_INFO.PHONE' | translate}}">
    </div>
  </div>
  <div class="display-block-mbl d-flex flex-row justify-content-between pb-2">
    <div class="w-50 w-100-mbl">
      <label class="lbl_field_small col-12">
        <span class="lbl_field_name">{{'COMPANY_INFO.COUNTRY_LABEL' | translate}}</span>
        <i *ngIf="errorModel != null && errorModel['Country'] != null" class="invalid_input_text">
          {{errorModel['Country'] | translate}}
        </i>
      </label>
      <p-dropdown class="src_dropdown country_dropdown m-0" [autoDisplayFirst]=false [options]="countries"
        placeholder="{{'COMPANY_INFO.COUNTRY' | translate}}" [styleClass]="'m-0'" [(ngModel)]="companyModel.countryId"
        optionLabel="countryName" optionValue="id"></p-dropdown>
    </div>

    <div class="w-50 w-100-mbl">
      <label class="lbl_field_small col-12">
        <span class="lbl_field_name">{{'COMPANY_INFO.BUSINESS_TYPE_LABEL' | translate}}</span>
        <i *ngIf="errorModel != null && errorModel['BusinessType'] != null" class="invalid_input_text">
          {{errorModel['BusinessType'] | translate}}
        </i>
      </label>
      <p-multiSelect class="men_dropdown" [options]="businessTypes" [styleClass]="'business_type mb-4'"
        [(ngModel)]="companyModel.businessTypesCompanies" optionLabel="name" optionValue="id" [showHeader]="false"
        [maxSelectedLabels]="1" placeholder="{{'COMPANY_INFO.BUSINESS_TYPE' | translate}}"
        selectedItemsLabel="{0} items selected"></p-multiSelect>
    </div>
  </div>
  <div class="display-block-mbl d-flex flex-row justify-content-between pb-2 mt-3">
    <button class="create-btn w-50 w-100-mbl" (click)="editCompanyInfo(companyModel)">{{'SAVE' | translate}}</button>
    <button class="btn-outline-transparent w-50 w-100-mbl mt-4-mlb" (click)="cancel()">{{'CANCEL' | translate}}</button>
  </div>
</div>
<p-toast position="bottom-center" [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'"></p-toast>