import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TranslationHelperService } from '../../services/translation-helper/translation-helper.service';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { Subject, takeUntil } from 'rxjs';
import { TermsAndConditionModel } from '../../models/terms-and-conditions/terms-and-condtions.model';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-terms-and-conditions-dialog',
  templateUrl: './terms-and-conditions-dialog.component.html',
  styleUrls: ['./terms-and-conditions-dialog.component.scss']
})
export class TermsAndConditionsDialogComponent implements OnInit {

  //#region Fields
  public customStyle: string = "";
  public TermsAndConditionsText: SafeHtml;
  public unsubscribe: Subject<void> = new Subject<void>();
  public spinner: boolean = false;
  //#endregion

  //#region Constructor
  constructor(
    public authenticationService: AuthenticationService,
    private translationHelperService: TranslationHelperService,
    private sanitizer: DomSanitizer,
    public messageService: MessageService,
  ) { }

  ngOnInit(): void {
    this.getTermsAndConditions();
  }
  //#endregion

  //#region Public Methods
  public getTermsAndConditions(): void {
    const getTermsAndConditionModel = new TermsAndConditionModel();
    getTermsAndConditionModel.languageId = this.translationHelperService.languageIdFromLocalStorage;
    getTermsAndConditionModel.countryId = this.translationHelperService.languageFromLocalStorage;
    this.spinner = true;
    this.authenticationService.getTermsAndConditions(getTermsAndConditionModel)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: (response) => {
          this.spinner = false;
          this.TermsAndConditionsText = this.sanitizer.bypassSecurityTrustHtml(this.customStyle + response.textContent);
        },
        error: errorResponse => {
          this.spinner = false;
          this.messageService.add({ severity: 'error', summary: errorResponse.error.Message, life: 2000 });
        }
      });
    
  }
  //#endregion

}
