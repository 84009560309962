import { Component, OnInit } from '@angular/core';
import {InstagramPostModel} from "../../../models/venues/instagram-post.model";
import {ManageVenuesService} from "../../../services/venues/venues.service";
import {ActivatedRoute, Router} from "@angular/router";
import {VenueModel} from "../../../models/venues/venue.model";
import {JwtHelperService} from "@auth0/angular-jwt";

@Component({
  selector: 'app-instagram-posts',
  templateUrl: './instagram-posts.component.html',
  styleUrls: ['./instagram-posts.component.scss']
})
export class InstagramPostsComponent implements OnInit {

  private token = localStorage.getItem('access_token');
  public venue: VenueModel;
  public instagramPosts: Array<InstagramPostModel>;

  constructor( private route: ActivatedRoute,
               private _jwtHelperService: JwtHelperService,
               private router: Router,
               private venueService: ManageVenuesService) {

    this.route.params.subscribe(async params => {
      this.venueService.getvenuedetails(params['venue-id']).subscribe(response => {
        this.venue = response;
        this.instagramPosts = [];
        if(this.venue.instagramId && this.venue.instagramToken) {
          this.getInstagramPosts(this.venue.instagramId, this.venue.instagramToken);
        }
      })
    });
  };

  ngOnInit(): void {
  }

  getInstagramPosts(instagramId: string, instagramToken: string, after?: string){
    this.venueService.getInstagramPostsForUser(instagramId, instagramToken, 25, after).subscribe(r => {
      if(Object.keys(r).includes('paging')){
        this.getInstagramPosts(instagramId, instagramToken, r['paging']['cursors']['after']);
      }
      let filteredResponse = r['data'].filter((post: InstagramPostModel) => post.caption.includes("#socialite"));
      this.instagramPosts = this.instagramPosts.concat(filteredResponse);
    })
  }
  public goBack(): void {
    if (this.token && !this._jwtHelperService.isTokenExpired(this.token)) {
      const decodedToken = this._jwtHelperService.decodeToken(this.token);
      if((!!(decodedToken.ippe as boolean) || !(decodedToken.itpe as boolean))){
        this.router.navigate(['manage-venues']);
      } else {
        this.router.navigate(['products']);
      }
    }
  }

}
