import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, Subscription, takeUntil } from "rxjs";
import { Message, MessageService } from "primeng/api";
import { DropdownHelperModel } from "src/app/share-modul/models/helpers/dropdown-helper.model";
import { UserModel } from "../../models/user/user.model";
import { AuthenticationService } from "../../services/authentication/authentication.service";
import { Router } from "@angular/router";
import { AddCompanyInfoModel } from "../../models/company/add-company-info.model";
import { BusinessType } from "../../models/company/business-type.model";
import { JwtHelperService } from '@auth0/angular-jwt';
const _jwtHelper = new JwtHelperService();
@Component({
  selector: 'app-company-info-form',
  templateUrl: './company-info-form.component.html',
  styleUrls: ['./company-info-form.component.scss']
})
export class CompanyInfoFormComponent implements OnInit, OnDestroy {

  //#region Fields
  public unsubscribe: Subject<void> = new Subject<void>()
  public addCompanyInfoModel: AddCompanyInfoModel = new AddCompanyInfoModel();
  public errorModel: { [k: string]: any } = {};
  public errorMsg: Message[];
  public countries: Array<DropdownHelperModel> = new Array<DropdownHelperModel>();
  public requiredFieldErrorMessage = "INFO.REQUIRED_FIELD";
  public user: UserModel;
  public subscription: Subscription;
  public spinner: boolean = false;
  public businessTypes: Array<BusinessType> = [];
  //#endregion

  //#region Constructor
  constructor(
    public authenticationService: AuthenticationService,
    public router: Router,
    public messageService: MessageService) {
  }

  ngOnInit(): void {
    this.subscription = this.authenticationService.loginUser.subscribe(user => {
      this.user = user!;
    })
    this.getAllCountryNames();
    this.getAllBusinessTypes();
  }
  //#endregion

  //#region Public Methods
  public addCompanyInfo(model: AddCompanyInfoModel): void {

    model.languageId = this.languageIdFromLocalStorage();

    this.errorModel = {};
    this.errorMsg = [];

    if (!model.companyName || model.companyName == "") {
      this.errorModel['Name'] = this.requiredFieldErrorMessage;
    }
    if (!model.address || model.address == "") {
      this.errorModel['Address'] = this.requiredFieldErrorMessage;
    }
    if (!model.countryId) {
      this.errorModel['Country'] = this.requiredFieldErrorMessage;
    }
    if (!model.email || model.email == "") {
      this.errorModel['Email'] = this.requiredFieldErrorMessage;
    }
    if (!model.phone || model.phone == "") {
      this.errorModel['Phone'] = this.requiredFieldErrorMessage;
    }
    if (!model.businessTypesCompanies || model.businessTypesCompanies?.length === 0) {
      this.errorModel['BusinessType'] = this.requiredFieldErrorMessage;
    }

    if (Object.keys(this.errorModel).length === 0) {
      model.phone = model.phone.toString();

      this.spinner = true;
      this.authenticationService.addCompanyInfo(model)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe({
          next: response => {
            localStorage.setItem('access_token', response.token);
            const token = localStorage.getItem('access_token');
            const decodedToken = _jwtHelper.decodeToken(token as string);
            const cmid = decodedToken.cmid;
            const isAdvertiserPaid = decodedToken.iap != undefined ? JSON.parse(decodedToken.iap.toLowerCase()) : false;
            const isResyPaid = decodedToken.irp != undefined ? JSON.parse(decodedToken.irp.toLowerCase()) : false
            const IsPOSPaid = decodedToken.ipp != undefined ? JSON.parse(decodedToken.ipp.toLowerCase()) : false
            this.spinner = false;

            if (isAdvertiserPaid || isResyPaid || IsPOSPaid) {
              this.router.navigate(['manage-venues']);
            } else {
              this.router.navigate(['products']);
            }



          },
          error: _ => {
            this.spinner = false;
            this.messageService.add({ severity: 'error', summary: "System error", life: 2000 });
          }
        });
    }
  }

  public getAllCountryNames(): void {
    this.authenticationService.getAllCountryNames()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: (response) => {
          this.countries = response;
        }
      }
      )
  }

  public getAllBusinessTypes(): void {
    this.authenticationService.getAllBusinessTypes()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: (response) => {
          this.businessTypes = response.data;
        }
      }
      );
  }

  public languageIdFromLocalStorage(): number {
    const langIdFromStorage = localStorage.getItem('languageId');

    if (langIdFromStorage) {
      return parseInt(langIdFromStorage);
    } else {
      return 1;
    }
  }

  public ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  //#endregion
}
