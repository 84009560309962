import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Subject, takeUntil } from 'rxjs';
import { LoginModel } from '../../models/login/login.model';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { JwtHelperService } from "@auth0/angular-jwt";
import { UserModel } from "../../models/user/user.model";
import { Router } from "@angular/router";
import { ProductModel } from "../../models/product/product-model";
import { SocialAuthenticationHelperService } from '../../services/social-authentication-helper/social-authentication-helper.service';
import { SocialLoginModel } from '../../models/login/social-login.model';
import { HttpErrorResponse } from '@angular/common/http';
import { ProductService } from "../../../socialite-manager-panel/services/products/products.service";
import { AccessProjectsService } from "../../../socialite-manager-panel/services/products/access-projects.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})

export class LoginComponent implements OnInit {

  //#region Fields
  public unsubscribe: Subject<void> = new Subject<void>();
  public loginModel: LoginModel = new LoginModel();
  public errorModel: { [k: string]: any } = {};
  public requiredFieldErrorMessage = "INFO.REQUIRED_FIELD";
  public productList: Array<ProductModel> = new Array<ProductModel>();
  //#endregion

  //#region Constructor
  constructor(
    public _router: Router,
    public authenticationService: AuthenticationService,
    public messageService: MessageService,
    private _jwtHelperService: JwtHelperService,
    public socialAuthenticationHelperService: SocialAuthenticationHelperService,
    public accessProjectsService: AccessProjectsService
  ) {
    var token = localStorage.getItem('access_token')
    if (token) {
      const decodedToken = this._jwtHelperService.decodeToken(token as string);
      const loggedInUser = new UserModel(decodedToken.id, decodedToken.rlnm.split(',').map((x: string) => +x));
      this.authenticationService.loginUser.next(loggedInUser);
      const isAdvertiserPaid = decodedToken.iap != undefined ? JSON.parse(decodedToken.iap.toLowerCase()) : false;
      const isResyPaid = decodedToken.irp != undefined ? JSON.parse(decodedToken.irp.toLowerCase()) : false
      const IsPOSPaid = decodedToken.ipp != undefined ? JSON.parse(decodedToken.ipp.toLowerCase()) : false
      if (decodedToken.cmid == undefined) {

        this._router.navigateByUrl('company-info');
      }
      else {
        if (isAdvertiserPaid || isResyPaid || IsPOSPaid) {
          this._router.navigate(['manage-venues']);
        } else {
          this._router.navigate(['products']);
        }
      }
    }
  }

  ngOnInit(): void {
    this.productList = this.accessProjectsService.getAllProjectsDetails();
  }
  //#endregion

  //#region Public Methods
  public login(model: LoginModel): void {
    this.errorModel = {};

    if (!model.username || model.username.trim() === "") {
      this.errorModel['Username'] = this.requiredFieldErrorMessage;
    }

    if (!model.password || model.password === "") {
      this.errorModel['Password'] = this.requiredFieldErrorMessage;
    }

    if (Object.keys(this.errorModel).length === 0) {
      this.authenticationService.login(model)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe({
          next: (response) => {
            localStorage.setItem('access_token', response.jwtToken)
            localStorage.setItem('refresh_token', response.refreshToken);
            const decodedToken = this._jwtHelperService.decodeToken(response.jwtToken);
            const loggedInUser = new UserModel(decodedToken.id, decodedToken.rlnm.split(',').map((x: string) => +x));
            this.authenticationService.loginUser.next(loggedInUser);
            const isAdvertiserPaid = decodedToken.iap != undefined ? JSON.parse(decodedToken.iap.toLowerCase()) : false;
            const isResyPaid = decodedToken.irp != undefined ? JSON.parse(decodedToken.irp.toLowerCase()) : false
            const IsPOSPaid = decodedToken.ipp != undefined ? JSON.parse(decodedToken.ipp.toLowerCase()) : false
            if (decodedToken.cmid == undefined) {

              this._router.navigateByUrl('company-info');
            }
            else {
              if (isAdvertiserPaid || isResyPaid || IsPOSPaid) {
                this._router.navigate(['manage-venues']);
              } else {
                this._router.navigate(['products']);
              }
            }
          },
          error: errorResponse => {
            if (errorResponse.error.errors && errorResponse.error.errors.Password) {
              errorResponse.error.errors.Password.forEach((error: string) => {
                this.errorModel['Password'] = error;
              });
            }
            if (errorResponse.error.errors && errorResponse.error.errors.Username) {
              errorResponse.error.errors.Username.forEach((error: string) => {
                this.errorModel['Username'] = error;
              });
            }
            if (errorResponse.error && errorResponse.error.Message) {
              this.messageService.add({ severity: 'error', summary: errorResponse.error.Message, life: 2000 });
            }
            else if (errorResponse.error && !errorResponse.error.errors) {
              this.messageService.add({ severity: 'error', summary: errorResponse.error.title, life: 2000 });
            }
          }
        });
    }
  }

  public signInWithFacebook(): void {
    this.socialAuthenticationHelperService.facebookAuth().then((result) => {
      const socialCredentials = new SocialLoginModel();
      //@ts-ignore
      socialCredentials.token = result.user._delegate.accessToken;
      this.authenticationService.authenticateSocialLogin(socialCredentials)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe({
          next: (response) => {
            localStorage.setItem('access_token', response.jwtToken)
            localStorage.setItem('refresh_token', response.refreshToken);
            const decodedToken = this._jwtHelperService.decodeToken(response.jwtToken);
            const loggedInUser = new UserModel(decodedToken.id, decodedToken.rlnm.split(',').map((x: string) => +x));
            this.authenticationService.loginUser.next(loggedInUser);
            this._router.navigateByUrl('manage-venues');
          },
          error: (errorResponse) => {
            if (errorResponse.error && errorResponse.error.Message) {
              this.messageService.add({ severity: 'error', summary: errorResponse.error.Message, life: 2000 });
            }
            else if (errorResponse.error && !errorResponse.error.errors) {
              this.messageService.add({ severity: 'error', summary: errorResponse.error.title, life: 2000 });
            }
          }
        })
    }, (error: HttpErrorResponse) => {
      if (!error.message.includes('popup has been closed by the user')) {
        this.messageService.add({ severity: 'warn', summary: error.message, life: 4000 });
      }
    });

  }

  public signInWithApple(): void {

  }
  //#endregion
}


