import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs";
import { HttpService } from "src/app/share-modul/services/common/http-service";
import { environment } from "src/environments/environment";
import { AddOrEditHostModel } from "../../models/hosts/add-or-edit-host.model";
import { AssignHostsModel } from "../../models/hosts/assign-hosts.model";

const HOST_API = environment.apiURL + '/api/business/Host/';
const HST_API = environment.apiURL + '/api/hst/Host/';

@Injectable({
  providedIn: 'root'
})

export class HostService {

  //#region Fields
  //#endregion

  //#region Constructor
  constructor(private _httpService: HttpService, private _httpClient: HttpClient) {
  }

  //#endregion

  //#region Public Methods
  getAllHosts(companyId: string) {
    let url = HOST_API + "getallhosts?CompanyId=" + companyId;
    return this._httpService.httpGet(url)
      .pipe(map(resposne => {
        return resposne;
      }));
  }

  getAllNotAssignedHosts(companyId: string) {
    let url = HOST_API + "getallnotassignedhosts?CompanyId=" + companyId;
    return this._httpService.httpGet(url)
      .pipe(map(resposne => {
        return resposne;
      }));
  }

  assignHosts(model: AssignHostsModel){
    let url = HOST_API + "assignhosts";
    return this._httpService.httpPost(url, model)
      .pipe(map(resposne => {
        return resposne;
      }));
  }

  createHost(model: AddOrEditHostModel) {
    let url = HST_API + "createhost";
    return this._httpService.httpPost(url, model)
      .pipe(map(resposne => {
        return resposne;
      }));
  }

  updateHost(model: AddOrEditHostModel) {
    let url = HST_API + "updatehost";
    return this._httpService.httpPost(url, model)
      .pipe(map(resposne => {
        return resposne;
      }));
  }

  deactivateHost(hostId: string) {
    let url = HST_API + 'deactivatehost';
    return this._httpService.httpPost(url, {hostId})
      .pipe(map(resposne => {
        return resposne;
      }));
  }
  //#endregion
}
