<p-progressSpinner class="soc-manager-spinner spinner-in-front" *ngIf="spinner"></p-progressSpinner>
<div class="panel-wrapper scroll-panel">
  <div class="d-flex flex-row justify-content-between pb-2">
    <div class="d-flex flex-row">
      <input (change)="onFileSelected($event)" class="open-file" style="display: none;" type="file" id="openFile"
             #openFile name="openFile" accept="image/png, image/jpg, image/jpeg"/>
      <img class="edit-picture" (click)="openAddImage()"
           [src]="venueModel.pictureUrl? venueModel.pictureUrl : imagePath  " alt="profile-picture">
      <div class="align-self-center">
        <p class="hide-mobile white-medium-text-16 m-0">{{'VENUE.UPLOAD_IMAGE_MESSAGE' | translate}}
          <label *ngIf="pictureIsWrongFormat">
            <i class="pi pi-exclamation-circle me-1 error-color wrong-format-tooltip"
               pTooltip="{{'VENUE.WRONG_FORMAT' | translate}}" tooltipPosition="top"></i>
          </label>
          <label *ngIf="errorModel!=null && errorModel['image']!=null">
            <i class="pi pi-exclamation-circle me-1 mt-1 error-color float-end"
               pTooltip="{{errorModel['image'] | translate}}" tooltipPosition="top"></i>
          </label>
        </p>
        <p class="show-mobile white-medium-text-16 m-0">{{'VENUE.UPLOAD_IMAGE_MESSAGE' | translate}}
          <label *ngIf="pictureIsWrongFormat">
            <i class="pi pi-exclamation-circle me-1 error-color wrong-format-tooltip"
               pTooltip="{{'VENUE.WRONG_FORMAT' | translate}}" tooltipPosition="top"></i>
          </label>
        </p>
      </div>
    </div>
  </div>
  <div class="display-block-mbl d-flex flex-row justify-content-between pb-2">
    <div class="w-100-mbl w-50">
      <label class="lbl-soc-manager-field">{{'VENUE.VENUE_NAME' | translate}}
        <i *ngIf="errorModel!=null && errorModel['name']!=null"
           class="pi pi-exclamation-circle me-1 mt-1 error-color float-end"
           pTooltip="{{errorModel['name']|translate}}" tooltipPosition="top"></i>
      </label>
      <input type="text" class="soc-manager-input-field" [(ngModel)]="venueModel.name">
    </div>
    <div class="w-100-mbl w-50">
      <label class="lbl-soc-manager-field">{{'VENUE.VENUE_ADDRESS' | translate}}
        <i *ngIf="errorModel!=null && errorModel['adress']!=null"
           class="pi pi-exclamation-circle me-1 mt-1 error-color float-end"
           pTooltip="{{errorModel['adress']|translate}}" tooltipPosition="top"></i>
      </label>
      <input #search type="text" class="soc-manager-input-field mt-1" autocapitalize="off"
             spellcheck="false" [(ngModel)]="venueModel.address" placeholder="" (input)="onAddressManualChange()">
    </div>
  </div>
  <div class="display-block-mbl d-flex flex-row justify-content-between pb-2">
    <div class="w-100-mbl w-50">
      <label class="lbl-soc-manager-field">{{'VENUE.PHONE_NUMBER' | translate}}
        <i *ngIf="errorModel!=null && errorModel['phone']!=null"
           class="pi pi-exclamation-circle me-1 mt-1 error-color float-end"
           pTooltip="{{errorModel['phone']|translate}}" tooltipPosition="top"></i>
      </label>
      <input type="tel" class="soc-manager-input-field custom_number_input" [(ngModel)]="venueModel.phone">
    </div>
    <div class="w-100-mbl w-50">
      <label class="lbl-soc-manager-field">{{'VENUE.VENUE_EMAIL' | translate}}
        <i *ngIf="errorModel!=null && errorModel['email']!=null"
           class="pi pi-exclamation-circle me-1 mt-1 error-color float-end"
           pTooltip="{{errorModel['email']|translate}}" tooltipPosition="top"></i>
      </label>
      <input type="email" class="soc-manager-input-field custom_number_input" [(ngModel)]="venueModel.email">
    </div>
  </div>
  <div class="display-block-mbl d-flex flex-row justify-content-between pb-2">
    <div class="w-100-mbl w-50">
      <label class="lbl-soc-manager-field">{{'VENUE.BUSINESS_TYPE' | translate}}
        <i *ngIf="errorModel!=null && errorModel['businessType']!=null"
           class="pi pi-exclamation-circle me-1 mt-1 error-color float-end"
           pTooltip="{{errorModel['businessType']|translate}}" tooltipPosition="top"></i>
      </label>
      <p-multiSelect class="men_dropdown" [options]="businessTypes" [styleClass]="'business_type mt-1'"
                     [(ngModel)]="venueModel.businessTypeIds" optionLabel="name" optionValue="id" [showHeader]="false"
                     [maxSelectedLabels]="1" placeholder="" selectedItemsLabel="{0} items selected"></p-multiSelect>
    </div>
    <div class="w-100-mbl w-50">
      <div class="d-flex justify-content-between">
        <label class="lbl-soc-manager-field">{{'VENUE.RULES' | translate}}</label>
        <label class="lbl-soc-manager-field text-end" *ngIf="venueModel.userRules">{{1000 - venueModel.userRules.length}}/1000</label>
      </div>
      <textarea rows="1" cols="50" class="venue-rules-text-area mt-1" maxlength="1000"
                [(ngModel)]="venueModel.userRules"></textarea>
    </div>
  </div>
  <div class="display-block-mbl d-flex flex-row justify-content-between pb-1">
    <div class="w-100">
      <label class="lbl-soc-manager-field">{{'VENUE.DESCRIPTION' | translate}}
        <i *ngIf="errorModel != null && errorModel['description'] != null"
           class="pi pi-exclamation-circle me-1 mt-1 error-color float-end"
           pTooltip="{{errorModel['description'] | translate}}" tooltipPosition="top"></i>
      </label>
      <textarea rows="4" cols="50" class="dsc-text-area mt-1"
                [(ngModel)]="venueModel.description"></textarea>
    </div>
  </div>
  <div class="display-block-mbl d-flex flex-row justify-content-between pb-1 mt-2">
    <div class="w-100 instagram-placeholder">
      <button class="connect-instagram-btn" (click)="modifyConnectionToInstagram()">
        <i class="pi pi-instagram instagram-icon"></i> {{(venueModel.instagramId != undefined ? 'VENUE.DISCONNECT_FROM_INSTAGRAM' : 'VENUE.CONNECT_TO_INSTAGRAM') | translate}}
      </button>
    </div>
    <div class="w-100 d-flex flex-row justify-content-between">
      <label class="lbl-soc-manager-field w-50">{{'VENUE.USE_GEOFENCE' | translate}}</label>
      <div class="w-50 d-flex justify-content-end">
        <p-inputSwitch [(ngModel)]="venueModel.sendLocationNotifications" styleClass="soc-manager-switch"></p-inputSwitch>
      </div>
    </div>
  </div>
  <div class="display-block-mbl d-flex flex-row justify-content-between pb-2 mt-2 mb-1" *ngIf="enableEdit">
    <div class="add-photos-or-hosts">
      <label class="lbl-soc-manager-field">{{'VENUE.MY_PHOTOS' | translate}}</label>
      <button class="create-btn w-75 magenta" (click)="uploadImagesDialog()">
        {{'ADD' | translate}}
        <i class="pi pi-plus text-white"></i>
      </button>
    </div>
    <div class="add-photos-or-hosts">
      <label class="lbl-soc-manager-field">{{'VENUE.MY_HOSTS' | translate}}</label>
      <button class="create-btn w-75 cyan" (click)="openListAndEditHostsPopup()">
        {{'ADD' | translate}}
        <i class="pi pi-plus text-white"></i>
      </button>
    </div>
  </div>
  <div class="display-block-mbl d-flex flex-row justify-content-between pb-1 mt-2">
    <div class="w-100">
      <label class="lbl-soc-manager-field">{{'VENUE.CHOOSE_APP_1' | translate}}
        <span class="white-medium-text-16">({{'VENUE.CHOOSE_APP_2' | translate}})</span></label>
      <ng-container *ngFor="let product of productList">
        <div class="d-flex align-items-center">
          <p-checkbox styleClass="soc-manager-checkbox" name="products-group" [(ngModel)]="venueModel.applicationIds" (ngModelChange)="isResySelect(venueModel.applicationIds)"
                      [value]="product.id" [inputId]="product.id"></p-checkbox>
          <label [for]="product.id" class="ms-3 lbl-checkbox">{{product.name}}</label>
        </div>
      </ng-container>
    </div>
  </div>
  <div *ngIf="showAddWebSites" class="display-block-mbl d-flex flex-row justify-content-between pb-2">
    <div class="w_100_mbl w-100">
      <div class="d-flex flex-row justify-content-between pb-2">
        <label class="lbl-soc-manager-field">{{'VENUE.ADD_WEBSITE' | translate}}
          <span class="white-medium-text-16">{{'VENUE.ADD_WEBSITE_DESC' | translate}}</span></label>   
          <button class="website_form_icon_wrapper" (click)="addVenueWebsite()" >
            <img src="assets/icons/add_circle_24px.svg"></button>
      </div>
      <div class="d-flex flex-column justify-content-between">
        <ng-container *ngFor="let website of venueModel.webSites; index as i; trackBy:changeTrackBy">
          <div class="d-flex flex-row justify-content-between mb-2">
            <input type="text" class="add_website_field" [(ngModel)]="venueModel.webSites[i]">
            <button class="website_form_icon_wrapper" (click)="removeWebsite(i)">
              <img src="assets/icons/delete_outline_24px.svg"></button>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="d-flex flex-row justify-content-between mt-3">
    <button class="create-btn w-50" *ngIf="!enableEdit" (click)="addVenue(venueModel)">{{'VENUE.CONTINUE' | translate}}</button>
    <button class="create-btn w-50" *ngIf="enableEdit" (click)="updateVenue(venueModel)">{{'SAVE' | translate}}</button>
    <button class="btn-outline-transparent w-50 m-0" (click)="cancel()">{{ 'CANCEL' | translate}}</button>
  </div>
</div>
<p-toast position="bottom-center" [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'"></p-toast>
