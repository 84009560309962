import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'primeng/dynamicdialog';
import { UserModel } from 'src/app/share-modul/models/user/user.model';
import { AuthenticationService } from 'src/app/share-modul/services/authentication/authentication.service';
import { TrialDialogComponent } from '../trial-dialog/trial-dialog.component';

@Component({
  selector: 'app-trial-offer',
  templateUrl: './trial-offer.component.html',
  styleUrls: ['./trial-offer.component.scss']
})
export class TrialOfferComponent implements OnInit {

  constructor(
    public _router: Router,
    public authenticationService: AuthenticationService,
    private dialogService: DialogService,
    public translate: TranslateService,
    private _jwtHelperService: JwtHelperService,
  ) { }

  ngOnInit() {
  }
  public openTrialDialog(): void {
    const ref = this.dialogService.open( TrialDialogComponent, {      
      header: this.translate.instant('PRODUCT.START_TRIAL'),
      width: '480px',
      styleClass: 'dialog_long , ml_200, mb_15, dialog-cyan-header-text'
    });
    ref.onClose.subscribe((item: any) => {
      if (item) {        
        localStorage.setItem('access_token', item.jwtToken)
        localStorage.setItem('refresh_token', item.refreshToken);
        const decodedToken = this._jwtHelperService.decodeToken(item.jwtToken);
        const loggedInUser = new UserModel(decodedToken.id, decodedToken.rlnm.split(',').map((x: string) => +x));
        this.authenticationService.loginUser.next(loggedInUser);          
        const isAdvertiserPaid = decodedToken.iap != undefined ? JSON.parse(decodedToken.iap.toLowerCase()) : false;
        const isResyPaid = decodedToken.irp != undefined ? JSON.parse(decodedToken.irp.toLowerCase()) : false
        const IsPOSPaid = decodedToken.ipp != undefined ? JSON.parse(decodedToken.ipp.toLowerCase()) : false
        if (decodedToken.cmid == undefined) {

          this._router.navigateByUrl('company-info');
        }
        else {
          if (isAdvertiserPaid || isResyPaid || IsPOSPaid) {
            this._router.navigate(['manage-venues']);
          } else {
            this._router.navigate(['products']);
          }
        }
      }
    });
  }
}
