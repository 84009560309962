import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from "primeng/api";
import { AngularFireAuth } from '@angular/fire/compat/auth';
import firebase from 'firebase/compat/app';

@Injectable({
    providedIn: 'root'
})
export class SocialAuthenticationHelperService {
    //#region Fields

    //#endregion

    //#region Constructor
    constructor(
        private fireAuth: AngularFireAuth,
    ) { }

    //#endregion

    //#region Public Methods
    public socialAuthLogin(provider: any) {
        return this.fireAuth.signInWithPopup(provider);
    }

    public facebookAuth() {
        return this.socialAuthLogin(new firebase.auth.FacebookAuthProvider());
    }

    public googleAuth() {
        return this.socialAuthLogin(new firebase.auth.GoogleAuthProvider());
    }
    //#endregion
}
