import { Component, ElementRef, OnInit } from '@angular/core';
import { CompressImageService } from "../../services/common/compress-image.service";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { base64ToFile, ImageCroppedEvent, ImageTransform } from "ngx-image-cropper";
import { take } from "rxjs";
import { CroppedImage } from "../../models/image/cropped-image.model";
import 'hammerjs';

@Component({
  selector: 'app-crop-and-compress-image-dialog',
  templateUrl: './crop-and-compress-image-dialog.component.html',
  styleUrls: ['./crop-and-compress-image-dialog.component.scss']
})
export class CropAndCompressImageDialogComponent implements OnInit {

  //#region Fields
  public pictureFormat: Array<string>;
  public pictureIsWrongFormat: boolean = false;
  // maxSize: number = 3000000;  //3 MB = 3000000 Bytes (in decimal)
  public imageChangedEvent: any = '';
  public croppedImage: any = '';
  public showCropper = false;
  public imagePath: string | ArrayBuffer;
  public imageName: string;
  public canvasRotation = 0;
  public transform: ImageTransform = {};

  public compressedImage: File;
  //#endregion

  //#region Constructor
  constructor(
    private compressImage: CompressImageService,
    private openFile: ElementRef,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
  ) {
    this.pictureFormat = ['image/png', 'image/jpg', 'image/jpeg'];
    if (this.config.data != undefined && this.config.data.imagePath) {
      this.imagePath = this.config.data.imagePath;
    }
  }

  ngOnInit(): void {}
  //#endregion

  //#region Public Methods
  save(): void {
    const result = new CroppedImage();
    result.imageFile = this.compressedImage;
    result.imagePath = this.imagePath;
    this.ref.close(result);
  }

  cancel(): void {
    this.ref.close();
  }

  onSelectFile(event: any): void {
    this.imageChangedEvent = event;
    this.pictureIsWrongFormat = false;
    if (event.target.files && event.target.files[0]) {

      if (!this.pictureFormat.includes(event.target.files[0].type)) {
        this.pictureIsWrongFormat = true;
        this.showCropper = false;
      } else {
        this.imageName = event.target.files[0].name;
        this.showCropper = true;
      }
    }
  }

  openAddImage(): void {
    var element = this.openFile.nativeElement.querySelector(".open-file");
    element.click();
  }

  imageCropped(event: ImageCroppedEvent): void {
    this.croppedImage = event.base64;
    let myBlob = base64ToFile(this.croppedImage)
    const myFile = new File([myBlob], this.imageName, {
      type: myBlob.type,
    });
    var reader = new FileReader();
    reader.readAsDataURL(myFile);// read file as data url
    this.compressImage.compress(myFile)
      .pipe(take(1))
      .subscribe(compressedImage => {
        this.compressedImage = compressedImage;
      })
    reader.onload = (item) => { // called once readAsDataURL is completed
      if (item.target?.result) {
        this.imagePath = item.target?.result;
      }
    }
  }

  imageLoaded(): void {
    // show cropper
  }
  cropperReady(): void {
    // cropper ready
  }

  loadImageFailed(): void {
  }

  rotateRight(): void {
    this.canvasRotation++;
    this.flipAfterRotate();
  }

  flipAfterRotate(): void {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH
    };
  }
  //#endregion
}
