import {Injectable} from "@angular/core";
import {HttpService} from "../../../share-modul/services/common/http-service";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {map} from "rxjs";
import {StripeCheckoutModel} from "../../models/products/stripe-checkout.model";
import {UnsubscribeFromProductDto} from "../../models/products/unsubscribe-from-product-dto";

const PAYMENT_API = environment.apiURL + '/api/business/Payment/';


@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(private _httpService: HttpService, private _httpClient: HttpClient) {
  }

  getSessionForPayment(model: StripeCheckoutModel) {
    var url = PAYMENT_API + "getsessionforpayment";
    return this._httpService.httpPost(url, model)
      .pipe(map(response => {
        return response;
      }));
  };
  getSessionForSubscription(model: StripeCheckoutModel) {
    var url = PAYMENT_API + "getsessionforsubscription";
    return this._httpService.httpPost(url, model)
      .pipe(map(response => {
        return response;
      }));
  };

  unsubscribeFromProduct(model: UnsubscribeFromProductDto) {
    var url = PAYMENT_API + "unsubscribefromproduct";
    return this._httpService.httpPost(url, model)
      .pipe(map(response => {

        return response;
      }));
  };
  getMembershipPlanForProject(projectId: string, pageSize?: number, pageNumber?: number) {
    var url = PAYMENT_API + "getmembershipplanforproject?ProjectId=" + projectId;
    if(pageNumber!=undefined && pageSize!=undefined){
      url += "&PageSize=" + pageSize + "&PageNumber=" + pageNumber;
    }
    return this._httpService.httpGet(url)
      .pipe(map(response => {
        return response;
      }));
  }

  getPaymentsForProject(projectId: string, pageSize?: number, pageNumber?: number) {
    var url = PAYMENT_API + "getpaymentsforproject?ProjectId=" + projectId;
    if(pageNumber != undefined && pageSize != undefined){
      url += "&PageSize=" + pageSize + "&PageNumber=" + pageNumber;
    }
    return this._httpService.httpGet(url)
      .pipe(map(response => {
        return response;
      }));
  }

  getSubscriptionInfoForProject(projectId: string, companyId: string){
    var url = PAYMENT_API + "getsubscriptioninfo?ProjectId=" + projectId + "&CompanyId=" + companyId;
    return this._httpService.httpGet(url)
      .pipe(map(response => {
        return response;
      }));
  }

}
