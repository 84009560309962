<div class="special-offer-div">
  <div class="background-image">
    <img src="assets/icons/logo-transparent-special-offer.svg">
  </div>
  <div class="background-image">
    <img src="assets/icons/special-offer-icon-2.svg" style="margin-left: 320px;">
  </div>
  <div class="background-image">
    <img src="assets/icons/special-offer-icon.svg" style="margin-left: 497px;
    margin-top: 10px;">
  </div>
  <div class="text-overlay d-flex justify-content-between flex-row">
    <div class="w-50">
      <div class="offer-name">{{specialOffer.name | uppercase}}</div>
      <div class="valid-until-text">{{'PRODUCT.OFFER_VALID_UNTIL' | translate}} {{specialOffer.validUtilDate | date: 'dd.MM.YYYY'}}</div>
      <button class="create-btn float-left" (click)="openPaymentDialog()">{{'PRODUCT.BUY_NOW' | translate | uppercase}}</button>
    </div>
    <div class="w-50 offer">
      <div class="question">{{'PRODUCT.WHAT_YOU_GET' | translate}}</div>
      <div *ngFor="let p of specialOffer.specialProductProjects" class="project-name">
        <ng-container *ngIf="p.isPrimaryProject">{{p.projectName}}</ng-container>
      </div>
      <div class="price">${{specialOffer.yearlyPrice}}</div>
      <div class="number-venues">{{specialOffer.maxNumberOfVenuesForSecondaryProjects}} {{'PRODUCT.VENUES' | translate}}</div>
      <div class="free">{{'PRODUCT.FREE' | translate}}</div>
      <div *ngFor="let p of specialOffer.specialProductProjects" class="project-name">
        <ng-container *ngIf="!p.isPrimaryProject">{{p.projectName}}</ng-container>
      </div>
    </div>
  </div>
</div>
