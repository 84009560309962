<div>
  <img src="./assets/images/assortment-with-sad-emoji-card.png" style="filter: brightness(50%);"/>
  <div class="pb-4 text-center delete-message p-0">
    <h4 class="fw-bolder mt-4">{{'PRODUCT.CANCEL_MESSAGE'|translate}}</h4>
    <h5 class="mt-2">{{'PRODUCT.CANCEL_CONFIRMATION'|translate}}</h5>
  </div>
  <div class="d-flex flex-column align-items-center">
    <button class="btn-outline-transparent magenta-color w-50 mb-4" (click)="cancelPlan()">{{'PRODUCT.YES' | translate }}</button>
    <button class="create-btn w-50 mb-4" (click)="chooseOtherPlan()">{{'PRODUCT.CHOSE_ANOTHER' | translate}}</button>
    <button class="btn-cancel-no-border magenta-color w-50" (click)="close()">{{'PRODUCT.CANCEL_CANCEL' | translate}}</button>
  </div>
</div>
