import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs";
import { HttpService } from "src/app/share-modul/services/common/http-service";
import { environment } from "src/environments/environment";

const COMPANY_API = environment.apiURL + '/api/adv/Company/';

@Injectable({
  providedIn: 'root'
})

export class CompanyService {
  //#region Fields
  //#endregion

  //#region Constructor
  constructor(private _httpService: HttpService, private _httpClient: HttpClient) {
  }
  //#endregion

  //#region Public Methods
  getCompanyDetials() {
    let url = COMPANY_API + "getcompanydetails";
    return this._httpService.httpGet(url)
      .pipe(map(resposne => {
        return resposne;
      }));
  }
  //#endregion
}
