<p-dropdown [options]="countries" id="chg_lang" (onChange)="switchActiveLang(selectedCountry)"
    [(ngModel)]="selectedCountry" [autoDisplayFirst]=false optionLabel="name" [showClear]="false"
    placeholder="Select a Country">

    <ng-template pTemplate="selectedItem">
        <div class="country-item country-item-value d-flex" *ngIf="selectedCountry">
            <img src={{selectedCountry.icon}} alt="">
            <div *ngIf="!ShowFullName" class="ms-2 country_name">{{selectedCountry.translateValue |translate | uppercase}}</div>
            <div *ngIf="ShowFullName" class="ms-2 country_name">{{selectedCountry.name |translate | uppercase}}</div>
        </div>
    </ng-template>
    <ng-template let-country pTemplate="item">
        <div class="country-item d-flex">
            <img src={{country.icon}} />
            <div *ngIf="!ShowFullName" class="ms-2 country_name">{{country.translateValue | translate | uppercase}}</div>
            <div *ngIf="ShowFullName" class="ms-2 country_name">{{country.name |translate | uppercase}}</div>
        </div>
    </ng-template>
</p-dropdown>