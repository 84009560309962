import { Component, Input, OnInit } from '@angular/core';
import { TranslationHelperService } from '../../services/translation-helper/translation-helper.service';

@Component({
  selector: 'app-change-language',
  templateUrl: './change-language.component.html',
  styleUrls: ['./change-language.component.scss']
})

export class ChangeLanguageComponent implements OnInit {
  @Input() ShowFullName: boolean=false;
  //#region Fields
  countries: any[];
  selectedCountry: any;
  //#endregion
  //#region Constructor
  constructor(
    private translationHelperService: TranslationHelperService
  ) {
    this.countries = [
      { id: 1, name: 'LANGUAGE.ENGLISH', translateValue: 'en', icon: 'assets/images/logo/en_flag.png' },
      { id: 2, name: 'LANGUAGE.MACEDONIAN', translateValue: 'mk', icon: 'assets/images/logo/mkd_flag.png' },
    ]
    const langIdFromStorage = localStorage.getItem('languageId')
    this.selectedCountry = this.countries.find(i => i.id === parseInt(langIdFromStorage as string))
  }

  ngOnInit(): void {
  }
  //#endregion

  //#region Public Methods
  switchActiveLang(language: any): void {
    this.translationHelperService.setActiveLanguage(language.translateValue, language.id);
  }
  //#endregion
}
