import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MenuItem, MessageService } from 'primeng/api';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { AuthenticationService } from 'src/app/share-modul/services/authentication/authentication.service';
import { DialogService } from "primeng/dynamicdialog";
import { EditProfileComponent } from "../../edit-profile/edit-profile.component";
import { TranslateService } from "@ngx-translate/core";
import { UserModel } from "../../../models/user/user.model";
import { Router } from "@angular/router";
import { CompanyInfoFormComponent } from "../../company-info-form/company-info-form.component";
import { EditCompanyInfoComponent } from "../../edit-company-info/edit-company-info.component";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  //#region Fields
  @Input() showSwitch: boolean = true;
  public unsubscribe: Subject<void> = new Subject<void>()
  public items: MenuItem[];
  public imagePath: string = './assets/images/logo/profile-picture-placeholder.png'
  public firstNameLastName: string;
  public email: string;
  public user: UserModel | null;
  public inMenu: boolean = false;
  public authSubscription: Subscription;
  //#endregion

  //#region Constructor
  constructor(
    public dialogService: DialogService,
    public messageService: MessageService,
    public authenticationService: AuthenticationService,
    public translate: TranslateService,
    public _router: Router
  ) {
    this.translate.stream('HEADER.APP_LANGUAGE').subscribe(() => {
      this.addFunctionToDropdownItems();
    });
  }

  public ngOnInit(): void {
    this.authSubscription = this.authenticationService.loginUser.pipe(takeUntil(this.unsubscribe)).subscribe(user => {
      this.user = user;
    });
    this.loadProfileInfo();
  }
  //#endregion

  //#region Public Methods
  public logOut(): void {

    this.authenticationService.logout()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: _ => {
          localStorage.removeItem('access_token');
          localStorage.removeItem('refresh_token');
          this.authenticationService.loginUser.next(null);
          this._router.navigate(['login']);
        },
        error: _ => {
          localStorage.removeItem('access_token');
          localStorage.removeItem('refresh_token');
        }
      });
  }

  public editProfile(): void {
    const ref = this.dialogService.open(EditProfileComponent, {
      header: this.translate.instant('HEADER.EDIT_PROFILE'),
      styleClass: 'dialog_long , ml_200, mb_15'
    });
    ref.onClose.subscribe((item: any) => {
      this.loadProfileInfo();
      if (item && item != "Delete") {
        let summary = this.translate.instant("HEADER.PROFILE_UPDATED");
        this.messageService.add({ severity: 'success', summary, life: 3000 });
      }
      if (item === "Delete") {
        let summary = this.translate.instant("HEADER.PROFILE_DELETED");
        this.messageService.add({ severity: 'success', summary, life: 3000 });
        this._router.navigate(['login']);
      }
    });
  }
  public editCompanyInfo(): void {
    const ref = this.dialogService.open(EditCompanyInfoComponent, {
      header: this.translate.instant('HEADER.EDIT_COMPANY_INFO'),
      width: '650px',
      styleClass: 'dialog_long , ml_200, mb_15'
    });
    ref.onClose.subscribe((item: any) => {
      if (item) {
        this.messageService.add({
          severity: 'success',
          summary: this.translate.instant('COMPANY_INFO.COMPANY_EDITED'),
          life: 3000
        });
      }
    });
  }

  public loadProfileInfo(): void {
    this.authenticationService.getProfile()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: (response) => {
          this.firstNameLastName = response.user.firstName + ' ' + response.user.lastName;
          this.email = response.user.name;
          this.imagePath = response.user.profilePictureUrl ? response.user.profilePictureUrl : this.imagePath;
        }
      });
  }

  public addFunctionToDropdownItems(): void {
    this.items = [
      {
        label: this.translate.instant('HEADER.EDIT_PROFILE'),
        command: () => {
          this.editProfile();
        }
      },
      {
        label: this.translate.instant('HEADER.EDIT_COMPANY_INFO'),
        command: () => {
          this.editCompanyInfo();
        }
      },
      {
        label: this.translate.instant('HEADER.LOGOUT'),
        command: () => {
          this.logOut();
          // this._router.navigate(['login']);
        }
      }
    ];
  }

  public showHideMenu(): void {
    this.inMenu = !this.inMenu;
    this.authenticationService.showHideMenu.emit(!this.inMenu);
  }

  public ngOnDestroy(): void {
    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
    }
  }
  //#endregion
}
