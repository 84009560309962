import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from "rxjs";
import { VenueModel } from "../../../models/venues/venue.model";
import { UploadImageModel } from "src/app/share-modul/models/image/upload-image.model";
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { ManageVenuesService } from "../../../services/venues/venues.service";
import { MessageService } from "primeng/api";
import { TranslateService } from "@ngx-translate/core";
import { AuthenticationService } from "src/app/share-modul/services/authentication/authentication.service";
import {
  MultipleImagesUploadComponent
} from "src/app/share-modul/components/multiple-images-upload/multiple-images-upload.component";
import { EditHostsComponent } from "../../hosts/edit-hosts/edit-hosts.component";
import { CompanyService } from 'src/app/socialite-manager-panel/services/company/company.service';
import { CreateGaleryImageModel } from 'src/app/socialite-manager-panel/models/venues/create-gallery.model';
import { UpdateGaleryImageModel } from 'src/app/socialite-manager-panel/models/venues/update-gallery.model';

@Component({
  selector: 'app-add-or-edit-venues-step-two',
  templateUrl: './add-or-edit-venues-step-two.component.html',
  styleUrls: ['./add-or-edit-venues-step-two.component.scss']
})
export class AddOrEditVenuesStepTwoComponent implements OnInit, OnDestroy {

  //#region Fields
  public unsubscribe: Subject<void> = new Subject<void>();
  public venueId: any;
  public companyId: any;
  public venueImageGaleryPhotos: Array<UploadImageModel> = [];
  public spinner: boolean = false;
  //#endregion

  //#region Constructor
  constructor(
    private config: DynamicDialogConfig,
    private ref: DynamicDialogRef,
    private dialogService: DialogService,
    private venueService: ManageVenuesService,
    public companyService: CompanyService,
    private messageService: MessageService,
    public translate: TranslateService,
    public authenticationService: AuthenticationService
  ) {
    if (this.config.data != undefined && this.config.data.venueId) {
      this.venueId = this.config.data.venueId;
    }
    if (this.config.data != undefined && this.config.data.companyId) {
      this.companyId = this.config.data.companyId;
    }
  }

  ngOnInit(): void {
    // this.addVenue(this.venueModel);
  }
  //#endregion

  //#region Public Methods


  skip(): void {
    this.ref.close(true);
  }

  cancel(): void {
    this.ref.close(false);
  }

  uploadImagesDialog(): void {
    // if (!this.venueModel.galeryImages) {
    //   this.venueModel.galeryImages = [];
    // }
    const ref = this.dialogService.open(MultipleImagesUploadComponent, {
      data: {
        venueId: this.venueId,
      },
      showHeader: true,
      closable: false,
      styleClass: 'dialog-long dialog-img-cropper'
    });
    ref.onClose.subscribe((response: any[]) => {
      if (response) {
        this.messageService.add({ severity: 'success', summary: this.translate.instant('VENUE.VENUE_GALERRY_ADDED'), life: 3000 });
      }
    });
  }

  public openListAndEditHostsPopup(): void {
    const ref = this.dialogService.open(EditHostsComponent, {
      data: {
        venueId: this.venueId,
        companyId: this.companyId
      },
      styleClass: 'small-header-dialog',
      width: '650px',
      closable: false
    });
    ref.onClose.subscribe((item: any) => {
      if (item) {
        // this.venueModel.hostsids = item;
      }
    });
  }

  public ngOnDestroy(): void {
  }
  //#endregion
}
