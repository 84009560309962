import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { JwtHelperService } from "@auth0/angular-jwt";
import { AuthenticationService } from "../share-modul/services/authentication/authentication.service";
const _jwtHelper = new JwtHelperService();
const TOKEN_KEY = 'access_token';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    //#region Fields
    //#endregion

    //#region Constructor
    constructor(private _router: Router , private authenticationService: AuthenticationService) {
    }
    //#endregion

    //#Private Methods
    canActivate() {
      if (window.localStorage.getItem(TOKEN_KEY) && !_jwtHelper.isTokenExpired(window.localStorage.getItem(TOKEN_KEY) as string)) {
        return true;
      } else {
        this.authenticationService.loginUser.next(null);
        this.authenticationService._isLoggedIn$.next(false);
        this.authenticationService._isSuperAdmin$.next(false);
        localStorage.removeItem(TOKEN_KEY);
        this._router.navigate(['login']);
        return false;
      }

    }
    //#endregion
  }
