import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { PrimeNgModule } from './modules/prime-ng/prime-ng.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './modules/shared/shared.module';

import { HttpClient, HttpClientModule } from '@angular/common/http';
import '@angular/compiler';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { NgImageFullscreenViewModule } from 'ng-image-fullscreen-view';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { DialogService } from 'primeng/dynamicdialog';
import { JwtModule } from '@auth0/angular-jwt';
import { SocialiteManagerNavbarComponent } from './socialite-manager-navbar/socialite-manager-navbar.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SocialiteManagerModule } from "./modules/socialite-manager/socialite-manager-panel.module";
import { NgxStripeModule } from 'ngx-stripe';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { environment } from 'src/environments/environment';
import { InstagramRegisterSupportComponent } from './socialite-manager-panel/components/manage-venues/instagram-register-support/instagram-register-support.component';
import { InstagramPostsComponent } from './socialite-manager-panel/components/manage-venues/instagram-posts/instagram-posts.component';
import { EditCompanyInfoComponent } from './share-modul/components/edit-company-info/edit-company-info.component';




// Http Loader for ngx-translate
export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    SocialiteManagerNavbarComponent,
    InstagramRegisterSupportComponent,
    InstagramPostsComponent,
    EditCompanyInfoComponent
  ],
  imports: [
    DragDropModule,
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    InfiniteScrollModule,
    PrimeNgModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedModule,
    NgImageFullscreenViewModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireMessagingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        deps: [HttpClient],
        useFactory: createTranslateLoader
      }
    }),
    JwtModule.forRoot({
      config: {
        tokenGetter: () => {
          return localStorage.getItem("access_token");
        },
      },
    }),
    NgxStripeModule.forRoot(environment.stripe.publishableKey),
    SocialiteManagerModule
  ],
  exports: [],
  providers: [DialogService ],
  bootstrap: [AppComponent]
})
export class AppModule { }
