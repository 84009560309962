import { Component, OnInit } from '@angular/core';
import { Subject, takeUntil } from "rxjs";
import { SpecialOfferModel } from "../../../models/special-offer/special-offer.model";
import { TranslateService } from "@ngx-translate/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ProductPlanModel } from "../../../models/products/product-plan.model";
import { ProductModel } from "../../../../share-modul/models/product/product-model";
import {
  CancelSubscriptionConfirmationDialogComponent
} from "../cancel-subcription-confirmation-dialog/cancel-subscription-confirmation-dialog.component";
import { DialogService } from "primeng/dynamicdialog";
import { PaymentDialogComponent } from '../payment-dialog/payment-dialog.component';
import { PaymentInformationModel } from 'src/app/socialite-manager-panel/models/products/payment-information-model';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ProductService } from "../../../services/products/products.service";
import { AuthenticationService } from "../../../../share-modul/services/authentication/authentication.service";
import { UserModel } from "../../../../share-modul/models/user/user.model";
import { PaymentService } from "../../../services/payments/payment.service";
import { InvoiceModel } from "../../../models/products/invoice.model";
import { ProductPaymentInfoDto } from "../../../models/products/product-payment-info-dto";
import { InvoiceInfoDto } from "../../../models/products/invoice-info-dto";
import { AccessProjectsService } from "../../../services/products/access-projects.service";
import { MessageService } from "primeng/api";

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss']
})
export class ProductDetailsComponent implements OnInit {

  //#region Fields
  private token = localStorage.getItem('access_token');
  public unsubscribe: Subject<void> = new Subject<void>();
  public spinner: boolean;
  public productId: string;
  public productDetails: ProductModel;
  public specialOffer: SpecialOfferModel;
  public activePlan: ProductPlanModel;
  public isTrialActive: boolean = false;
  public user: UserModel;
  public result: string = "";
  public paymentInformation: PaymentInformationModel;
  public TrialMemberSince: string | null
  //#endregion

  //#region Constructor
  constructor(
    private translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private dialogService: DialogService,
    private _jwtHelperService: JwtHelperService,
    private authService: AuthenticationService,
    private productService: ProductService,
    private messageService: MessageService,
    private accessProjectsService: AccessProjectsService,
    private paymentService: PaymentService
  ) {
    const token = localStorage.getItem('access_token');
    const decodedToken = _jwtHelperService.decodeToken(token as string);
    const isTrialOver = decodedToken.itpe != undefined ? JSON.parse(decodedToken.itpe.toLowerCase()) : false;
    const isAdvertiserPaid = decodedToken.iap != undefined ? JSON.parse(decodedToken.iap.toLowerCase()) : false;
    const isResyPaid = decodedToken.irp != undefined ? JSON.parse(decodedToken.irp.toLowerCase()) : false
    const IsPOSPaid = decodedToken.ipp != undefined ? JSON.parse(decodedToken.ipp.toLowerCase()) : false
    if ((isAdvertiserPaid || isResyPaid || IsPOSPaid) && !isTrialOver) {
      this.isTrialActive = true;
    }
  }

  ngOnInit(): void {
    this.route.params.subscribe(async params => {
      this.productId = params['id'];
      this.productDetails = this.accessProjectsService.getProjectDetails([this.productId])[0];
      this.authService.loginUser.subscribe(response => {
        if (response) {
          this.user = response;
          this.fetchProductDetails();
        }
      })
    });
    this.getSpecialOffer();
    if (this.isTrialActive) {
      this.getTrialPlanForProject();
    }
  }
  //#endregion

  //#region Public Methods
  public fetchProductDetails(): void {
    const search = '/';
    const replaceWith = '%2F';
    let refreshToken = localStorage.getItem("refresh_token");
    if (refreshToken) {
      this.result = (localStorage.getItem("refresh_token") as string).split(search).join(replaceWith);
    }

    this.productService.getProductDetails(this.productId, this.user.id)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
        let plans: Array<ProductPlanModel> = response;
        this.productDetails.plans = plans;
        this.productDetails.url += this.result;
        this.activePlan = this.productDetails.plans?.filter(plan => plan.userActivated)[0];
        if (this.activePlan) {
          this.getPaymentInformation();
        }
      });
  };

  public getSpecialOffer(): void {
    this.productService.getSpecialOffer()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => this.specialOffer = response)
  }

  public getPaymentInformation() {
    this.paymentInformation = new PaymentInformationModel();
    this.getActivePlanForProject();
    this.getInvoicesForProject();
  };

  getActivePlanForProject() {
    this.paymentService.getMembershipPlanForProject(this.productId).subscribe(response => {
      let activePlanInfo = response as ProductPaymentInfoDto;
      this.paymentInformation.id = activePlanInfo.id;
      this.paymentInformation.country = activePlanInfo.company.countryName;
      this.paymentInformation.currentPlan = activePlanInfo.productName;
      this.paymentInformation.memberDate = activePlanInfo.startMembershipDate;
      this.getProjectSubscriptionInfo(activePlanInfo.productId, activePlanInfo.companyId)
    });
  };

  getTrialPlanForProject() {
    this.paymentService.getMembershipPlanForProject(this.productId).subscribe(response => {
      if (response.isTrial) {
        this.TrialMemberSince = response.startMembershipDate;
      }
    });
  };

  getInvoicesForProject() {
    this.paymentService.getPaymentsForProject(this.productId, 20, 1).subscribe(response => {
      this.paymentInformation.invoices = new Array<InvoiceModel>();
      response.data.forEach((invoice: InvoiceInfoDto) => {
        let invoiceTemp = new InvoiceModel();
        invoiceTemp.amount = invoice.amount;
        invoiceTemp.currencyCode = invoice.currencyCode;
        invoiceTemp.status = invoice.status;
        invoiceTemp.date = invoice.paymentDate;
        this.paymentInformation.invoices.push(invoiceTemp);
      });
    });
  };

  getProjectSubscriptionInfo(planId: string, companyId: string) {
    this.paymentService.getSubscriptionInfoForProject(planId, companyId).subscribe(response => {
      this.paymentInformation.validUntil = response.validUntilDate;
    });
  }

  public openPaymentDialog(productPlan: any): void {
    const ref = this.dialogService.open(PaymentDialogComponent, {
      data: {
        productPlan: productPlan,
        isSpecialOffer: false
      },
      header: this.translate.instant('PRODUCT.PAYMENT_OPTIONS'),
      styleClass: 'dialog_long , ml_200, mb_15, dialog-cyan-header-text'
    });
    ref.onClose.subscribe((item: any) => {
      if (item) {
        // let summary = this.translate.instant("HEADER.PROFILE_UPDATED");
        // this.messageService.add({severity: 'success', summary, life: 3000});
      }
    });
  }

  public goToUpgradePlans(id: string): void {
    this.router.navigate(['/products', id, 'upgrade-plan']);
  }

  public openCancelSubscription(): void {
    const ref = this.dialogService.open(CancelSubscriptionConfirmationDialogComponent, {
      data: {
        planId: this.activePlan.id,
        productId: this.productId
      },
      width: '451px',
      showHeader: false,
      styleClass: 'no-header-dialog'
    });
    ref.onClose.subscribe(response => {
      if (response) {
        if (response.error) {
          this.messageService.add({ severity: 'error', summary: 'System Error!', life: 2000 });
        } else {
          this.messageService.add({ severity: 'success', summary: 'Subscription cancelled!', life: 2000 });
        }
      }
    });
  }

  public goBack(): void {
    if (this.token && !this._jwtHelperService.isTokenExpired(this.token)) {
      const decodedToken = this._jwtHelperService.decodeToken(this.token);
      if ((!!(decodedToken.ippe as boolean) || !(decodedToken.itpe as boolean))) {
        this.router.navigate(['manage-venues']);
      } else {
        this.router.navigate(['products']);
      }
    }
  }
  //#endregion
}
