import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './share-modul/components/login/login.component';
import { SignupComponent } from "./share-modul/components/signup/signup.component";
import { ForgotPasswordComponent } from "./share-modul/components/forgot-password/forgot-password.component";
import { ResetPasswordComponent } from "./share-modul/components/reset-password/reset-password.component";

import { CompanyInfoFormComponent } from "./share-modul/components/company-info-form/company-info-form.component";
import { AuthGuard } from "./guard/guard.component";
// import { CmidRelatedGuard } from "./guard/add-company-related.guard";
import { LoginWithRefreshTokenComponent } from './share-modul/components/login-with-refresh-token/login-with-refresh-token.component';

const routes: Routes = [

  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'login/:refresh-token', component: LoginWithRefreshTokenComponent },
  { path: 'signup', component: SignupComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'reset-password', component: ResetPasswordComponent }, 
  { path: 'company-info', component: CompanyInfoFormComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
