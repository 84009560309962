import { Component, ElementRef, OnInit } from '@angular/core';
import { Subject, take, takeUntil, tap } from "rxjs";
import { DialogService, DynamicDialogComponent, DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { MessageService } from "primeng/api";
import { AddHostComponent } from "../add-host/add-host.component";
import { TranslateService } from "@ngx-translate/core";
import { HostModel } from "../../../models/hosts/host.model";
import { HostService } from "../../../services/hosts/hosts.service";
import { AddOrEditHostModel } from "../../../models/hosts/add-or-edit-host.model";
import { VenueModel } from "../../../models/venues/venue.model";
import { AssignHostsModel } from 'src/app/socialite-manager-panel/models/hosts/assign-hosts.model';

@Component({
  selector: 'app-edit-hosts',
  templateUrl: './edit-hosts.component.html',
  styleUrls: ['./edit-hosts.component.scss']
})
export class EditHostsComponent implements OnInit {

  //#region Fields
  public unsubscribe: Subject<void> = new Subject<void>();
  public errorModel: { [k: string]: any } = {};
  public spinner: boolean = false;
  public requiredFieldErrorMessage = "INFO.REQUIRED_FIELD";
  public venueId: string;
  public selectedVenue: VenueModel = new VenueModel();
  public selectedHosts: Array<any> = new Array<any>();
  public companyId: string;
  public hosts: Array<HostModel> = new Array<HostModel>();
  public clonedHosts: { [s: string]: HostModel; } = {};
  public hostsUpdated: boolean = false;
  //#endregion

  //#region Constructor
  constructor(private openFile: ElementRef,
    public dialogService: DialogService,
    public translate: TranslateService,
    private refDialogComponent: DynamicDialogComponent,
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    public hostService: HostService,
    public messageService: MessageService) {
  }

  public ngOnInit(): void {
    if (this.config.data != undefined && this.config.data.venue && this.config.data.companyId) {
      this.venueId = this.config.data.venue.id;
      this.selectedVenue = this.config.data.venue;
      this.companyId = this.config.data.companyId;
      this.loadHosts();
    }
  }
  //#endregion

  //#region Public Methods
  public loadHosts(): void {
    this.spinner = true;
    this.hostService.getAllNotAssignedHosts(this.companyId)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: (response) => {
          this.spinner = false;
          this.hosts = response;
          if(this.selectedVenue.id){
            this.selectedHosts = response.filter((o1:any) => o1.venueIds.some((o2:any) => o2 === this.selectedVenue.id));
          }
        },
        error: _ => {
          this.spinner = false;
          this.messageService.add({ severity: 'error', summary: "System error", life: 2000 });
        }
      });
  }

  public updateHost(model: HostModel): void {
    this.errorModel = {};

    if (!model.firstName || model.firstName == "") {
      this.errorModel['FirstName'] = this.requiredFieldErrorMessage;
    }
    if (!model.lastName || model.lastName == "") {
      this.errorModel['LastName'] = this.requiredFieldErrorMessage;
    }
    if (!model.phone || model.phone == "") {
      this.errorModel['Phone'] = this.requiredFieldErrorMessage;
    }

    if (Object.keys(this.errorModel).length === 0) {
      delete this.clonedHosts[model.id];
      this.spinner = true;
      const editModel = new AddOrEditHostModel();
      editModel.firstName = model.firstName;
      editModel.lastName = model.lastName;
      editModel.hostId = model.id;
      editModel.phone = model.phone.toString();
      editModel.venueIds = model.venueIds;

      this.hostService.updateHost(editModel)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe({
          next: (response) => {
            this.loadHosts();
            this.hostsUpdated = true;
          },
          error: errorResponse => {
            this.spinner = false;
            this.messageService.add({ severity: 'error', summary: "System error", life: 2000 });
          }
        });
    }
  }

  public assignHosts(): void {
    const assignHostsModel = new AssignHostsModel();
    assignHostsModel.hostIds = this.selectedHosts.map(x => x.id);
    assignHostsModel.venueId = this.venueId;
    this.spinner = true;
    this.hostService.assignHosts(assignHostsModel)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: (response) => {
          this.spinner = false;
          this.loadHosts();
          this.ref.close(this.hostsUpdated);
        },
        error: errorResponse => {
          this.spinner = false;
          this.messageService.add({ severity: 'error', summary: "System error", life: 2000 });
        }
      })
  }

  public deactivateHost(hostId: string): void {
    this.spinner = true;
    this.hostService.deactivateHost(hostId)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: (response) => {
          this.loadHosts();
          this.hostsUpdated = true;
        },
        error: errorResponse => {
          this.spinner = false;
          this.messageService.add({ severity: 'error', summary: "System error", life: 2000 });
        }
      });
  }

  public cancel(): void {
    this.ref.close(this.hostsUpdated);
  }

  public onRowEditInit(host: HostModel): void {
    this.clonedHosts[host.id] = { ...host };
  }

  public onRowEditSave(host: HostModel): void {
    this.updateHost(host);
  }

  public onRowEditCancel(host: HostModel, index: number): void {
    this.hosts[index] = this.clonedHosts[host.id];
    delete this.clonedHosts[host.id];
  }

  public openAddHostsPopup(): void {
    this.refDialogComponent.unbindDocumentKeydownListener();

    const ref = this.dialogService.open(AddHostComponent, {
      data: {
        id: this.venueId,
        companyId: this.companyId
      },
      header: this.translate.instant('HOST.ADD_HOSTS'),
      width: '650px'
    });

    ref.onClose.subscribe((item: any) => {
      if (item) {
        this.loadHosts();
        this.hostsUpdated = true;
      }
    });

    ref.onDestroy.pipe(
      take(1),
      tap(() => this.refDialogComponent.bindDocumentKeydownListener())).subscribe();
  }
  //#endregion
}
