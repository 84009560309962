import { Component, Input, OnInit } from '@angular/core';
import { SpecialOfferModel } from "../../../models/special-offer/special-offer.model";
import { DialogService } from 'primeng/dynamicdialog';
import { PaymentDialogComponent } from '../payment-dialog/payment-dialog.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-special-offer',
  templateUrl: './special-offer.component.html',
  styleUrls: ['./special-offer.component.scss']
})
export class SpecialOfferComponent implements OnInit {

  @Input() specialOffer: SpecialOfferModel;

  constructor(
    private dialogService: DialogService,
    public translate: TranslateService,
  ) { }

  ngOnInit(): void {
  }

  public openPaymentDialog(): void {
    const ref = this.dialogService.open(PaymentDialogComponent, {
      data: {
        productPlan: this.specialOffer,
        isSpecialOffer: true
      },
      header: this.translate.instant('PRODUCT.PAYMENT_OPTIONS'),
      styleClass: 'dialog_long , ml_200, mb_15, dialog-cyan-header-text'
    });
    ref.onClose.subscribe((item: any) => {
      if (item) {
        // let summary = this.translate.instant("HEADER.PROFILE_UPDATED");
        // this.messageService.add({severity: 'success', summary, life: 3000});
      }
    });
  }

}
