import { Component, OnInit, ViewChild } from '@angular/core';
import { AddCompanyInfoModel } from "../../models/company/add-company-info.model";
import { Subject, Subscription, takeUntil } from "rxjs";
import { EditCompanyInfoModel } from "../../models/company/edit-company-info";
import { AuthenticationService } from "../../services/authentication/authentication.service";
import { Message, MessageService } from "primeng/api";
import { DropdownHelperModel } from "../../models/helpers/dropdown-helper.model";
import { BusinessType } from "../../models/company/business-type.model";
import { UserModel } from "../../models/user/user.model";
import { Router } from "@angular/router";
import { DynamicDialogRef } from "primeng/dynamicdialog";

@Component({
  selector: 'app-edit-company-info',
  templateUrl: './edit-company-info.component.html',
  styleUrls: ['./edit-company-info.component.scss']
})
export class EditCompanyInfoComponent implements OnInit {

  //#region Fields
  @ViewChild('email') emailField: any;

  public companyModel: EditCompanyInfoModel = new EditCompanyInfoModel();
  public countries: Array<DropdownHelperModel> = new Array<DropdownHelperModel>();
  public businessTypes: Array<BusinessType> = [];
  public errorModel: { [k: string]: any } = {};
  public errorMsg: Message[];
  public unsubscribe: Subject<void> = new Subject<void>()
  public user: UserModel;
  public subscription: Subscription;
  public requiredFieldErrorMessage = "INFO.REQUIRED_FIELD";
  public invalidFieldErrorMessage = "INFO.INVALID_EMAIL";
  public spinner: boolean = false;
  //#endregion
  //#region Constructor
  constructor(private ref: DynamicDialogRef,
    public authenticationService: AuthenticationService,
    public router: Router,
    public messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.subscription = this.authenticationService.loginUser.subscribe(user => {
      this.user = user!;
    });
    this.getAllCountryNames();
    this.getAllBusinessTypes();
    this.getCompanyDetails();
  }
  //#endregion

  //#region Public Methods

  public getCompanyDetails(): void {
    this.spinner = true;
    this.authenticationService.getCompanyDetails()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: (response) => {
          this.spinner = false;        
           this.companyModel = response;
           this.companyModel.businessTypesCompanies = response.businessTypesCompanies?.map((x: BusinessType) => x.id);
        },
        error: _ => {
          this.spinner = false;
          this.messageService.add({ severity: 'error', summary: "System error", life: 2000 });
        }
      });
  }

  public editCompanyInfo(model: EditCompanyInfoModel): void {

    this.errorModel = {};
    this.errorMsg = [];

    if (!model.companyName || model.companyName == "") {
      this.errorModel['Name'] = this.requiredFieldErrorMessage;
    }
    if (!model.address || model.address == "") {
      this.errorModel['Address'] = this.requiredFieldErrorMessage;
    }
    if (!model.countryId) {
      this.errorModel['Country'] = this.requiredFieldErrorMessage;
    }
    if (!model.email || model.email == "") {
      this.errorModel['Email'] = this.requiredFieldErrorMessage;
    }
    if (this.emailField.touched && this.emailField.errors?.pattern) {
      this.errorModel['Email'] = this.invalidFieldErrorMessage;
    }
    if (!model.phone || model.phone == "") {
      this.errorModel['Phone'] = this.requiredFieldErrorMessage;
    }
    if (!model.businessTypesCompanies || model.businessTypesCompanies?.length === 0) {
      this.errorModel['BusinessType'] = this.requiredFieldErrorMessage;
    }

    if (Object.keys(this.errorModel).length === 0) {
      model.phone = model.phone.toString();     
      this.spinner = true;
      this.authenticationService.updateCompany(model)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe({
          next: response => {           
            this.spinner = false;
            this.ref.close(true);
          },
          error: _ => {           
            this.spinner = false;
            this.messageService.add({severity: 'error', summary: "System error", life: 2000});
          }
        });
    }
  }

  public getAllCountryNames(): void {
    this.authenticationService.getAllCountryNames()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: (response) => {
          this.countries = response;
        }
      }
      )
  }
  public getAllBusinessTypes(): void {
    this.authenticationService.getAllBusinessTypes()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: (response) => {
          this.businessTypes = response.data;
        }
      }
      );
  }
  public cancel(): void {
    this.ref.close();
  }

  public ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  //#endregion
}
