<p-progressSpinner *ngIf="spinner"></p-progressSpinner>
<div class="div-payment ps-4 pe-4">
    <label class="lbl-name">{{'PAYMENT.ONE_TIME'|translate}}</label>
    <div class="p-field-radiobutton d-flex p-2 m-2">
        <p-radioButton name="basis" value="1" [(ngModel)]="basis" inputId="basis1">
        </p-radioButton>
        <label class="ms-2" for="basis1">{{productPlan.defaultMonthlyPrice}} {{'PAYMENT.MONTH'|translate}}</label>
    </div>
    <div class="p-field-radiobutton d-flex p-2 m-2">
        <p-radioButton name="basis" value="2" [(ngModel)]="basis" inputId="basis2">
        </p-radioButton>
        <label class="ms-2" for="basis2">{{productPlan.defaultYearlyPrice}} {{'PAYMENT.YEAR'|translate}} </label>
    </div>
    <label class="lbl-name">{{'PAYMENT.SUBSCRIBE'|translate}}</label>
    <div class="p-field-radiobutton d-flex p-2 m-2">
        <p-radioButton name="basis" value="3" [(ngModel)]="basis" inputId="basis3">
        </p-radioButton>
        <label class="ms-2" for="basis3">{{productPlan.defaultMonthlyPrice}} {{'PAYMENT.PER_MONTH'|translate}}</label>
    </div>
    <div class="p-field-radiobutton d-flex p-2 m-2">
        <p-radioButton name="basis" value="4" [(ngModel)]="basis" inputId="basis4">
        </p-radioButton>
        <label class="ms-2" for="basis4">{{productPlan.defaultYearlyPrice}}   {{'PAYMENT.PER_YEAR'|translate}} </label>
    </div>
    <div>
        <button class="create-btn w-100 mt-3" type="submit" (click)="checkout()">
            {{'PAYMENT.PROCEED_CHECKOUT'|translate}}
        </button>
    </div>
</div>
