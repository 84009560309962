import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-instagram-register-support',
  templateUrl: './instagram-register-support.component.html',
  styleUrls: ['./instagram-register-support.component.scss']
})
export class InstagramRegisterSupportComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
  ) {
    this.route.queryParams.subscribe( queryParams => {
        let venueId = queryParams['venueId'];
        this.route.fragment.subscribe(fragment => {
          if (fragment) {
            let longLivedToken = new URLSearchParams(fragment).get("long_lived_token");
            let accessToken = new URLSearchParams(fragment).get("access_token");
            if (longLivedToken) {
              localStorage.setItem("instagram_long_lived_token_" + venueId, longLivedToken);
            }
            if (accessToken) {
                localStorage.setItem("instagram_access_token_"+ venueId, accessToken);
            }

          }
           window.close()
        });
      })
  }

  ngOnInit(): void {
  }

}
