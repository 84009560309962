import { Component, OnInit } from '@angular/core';
import {Subject, takeUntil} from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { ProductModel } from "../../../share-modul/models/product/product-model";
import { SpecialOfferModel } from "../../models/special-offer/special-offer.model";
import {ProductService} from "../../services/products/products.service";
import {ProjectEnum} from "../../enums/project-ids.enum";
import {AccessProjectsService} from "../../services/products/access-projects.service";


@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {

  //#region Fields
  public unsubscribe: Subject<void> = new Subject<void>();
  public spinner: boolean;
  public productList: Array<ProductModel> = new Array<ProductModel>();
  public specialOffer: SpecialOfferModel;
  public ProjectEnum = ProjectEnum;
  //#endregion

  //#region Constructor
  constructor(
    public translate: TranslateService,
    public productService: ProductService,
    public accessProjectsService: AccessProjectsService
  ) {
  }


  ngOnInit():void {
    this.getSpecialOffer();
    this.getAllProducts();
  }
  //#endregion

  //#region Public Methods
  public getAllProducts(): void {
    this.productList = this.accessProjectsService.getAllProjectsDetails();
  };

  public getSpecialOffer(): void {
    this.productService.getSpecialOffer()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
        this.specialOffer = response;
      });
  }
  //#endregion
}
