import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subject, takeUntil } from 'rxjs';
import { AuthenticationService } from '../../services/authentication/authentication.service';

@Component({
  selector: 'app-deactive-account-dialog',
  templateUrl: './deactive-account-dialog.component.html',
  styleUrls: ['./deactive-account-dialog.component.scss']
})
export class DeleteAccountDialogComponent implements OnInit {

  //#region Fields
  public venueId: string;
  public spinner: boolean = false;
  public unsubscribe: Subject<void> = new Subject<void>();
  //#endregion
  //#region Constructor
  constructor(
    private config: DynamicDialogConfig,
    private ref: DynamicDialogRef,
    private authenticationService: AuthenticationService,
    private messageService: MessageService
  ) {
    if (this.config.data != undefined && this.config.data.id) {
      this.venueId = this.config.data.id;
    }
  }

  ngOnInit(): void {
  }
  //#endregion
  //#region Public Methods
  deleteAccount() {
    this.spinner = true;
    this.authenticationService.deleteProfile()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: _ => {
          this.spinner = false;
          this.authenticationService.loginUser.next(null);
          localStorage.removeItem('access_token');
          localStorage.removeItem('refresh_token');
          this.ref.close(true);
        },
        error: _ => {
          this.spinner = false;
          this.messageService.add({ severity: 'error', summary: "System error", life: 2000 });
          this.ref.close(false);
        }
      });
  }

  cancel(): void {
    this.ref.close(false);
  }
  //#endregion


}
