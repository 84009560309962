export class SpecialOfferModel {
  name: string;
  description: string;
  maxNumberOfVenuesForPrimaryProjects: number;
  maxNumberOfVenuesForSecondaryProjects: number;
  monthlyPrice: number;
  monthlyProcessorProductId: string;
  yearlyPrice: number;
  yearlyProcessorProductId: string;
  validUtilDate: Date;
  countriesId: number[];
  specialProductProjects: SpecialProductProject[];
  boughtByTheUser: boolean;
}

export class SpecialProductProject {
  projectId: string;
  projectName: string;
  isPrimaryProject: boolean;
}
