<div class="bg_login d-flex">
  <div class="w-100 company_info_div">
    <div class="w-100 logo_div">
      <div class="logo"></div>
    </div>
    <div class="input_fields row">
      <h3 class="sign_up d-flex justify-content-center w-100">{{'INFO.SIGN_UP' | translate}}</h3>
      <div class="col-6">
        <label class="lbl_field_small col-12">
          <div *ngIf="errorModel!=null && errorModel['FirstName']!=null" class="invalid_input_text">
            {{errorModel['FirstName'] | translate}}
          </div>
        </label>
        <input type="text" class="input_field w-100" [(ngModel)]="signupModel.firstName"
               placeholder="{{'SIGN_UP.NAME' |translate}}">
      </div>
      <div class="col-6">
        <label class="lbl_field_small col-12">
          <div *ngIf="errorModel!=null && errorModel['LastName']!=null" class="invalid_input_text">
            {{errorModel['LastName'] | translate}}
          </div>
        </label>
        <input type="text" class="input_field w-100" [(ngModel)]="signupModel.lastName"
               placeholder="{{'SIGN_UP.SURNAME' |translate}}">
      </div>
      <div class="col-6">
        <label class="lbl_field_small col-12">
          <div *ngIf="errorModel!=null && errorModel['Email']!=null" class="invalid_input_text">
            {{errorModel['Email'] | translate}}
          </div>
          <div *ngIf="email.touched && email.errors?.pattern && (errorModel==null || errorModel['Email']==null)"
               class="invalid_input_text">
            {{'INFO.INVALID_EMAIL' | translate}}
          </div>
        </label>
        <input type="email" class="input_field col-12" [(ngModel)]="signupModel.email"
               placeholder="{{'SIGN_UP.EMAIL'|translate}}"
               #email=ngModel required pattern="^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$">
      </div>
      <div class="col-6">
        <label class="lbl_field_small col-12">
          <div *ngIf="errorModel!=null && errorModel['Phone']!=null" class="invalid_input_text">
            {{errorModel['Phone'] | translate}}
          </div>
        </label>
        <input type="number" class="input_field w-100 custom_number_input" [(ngModel)]="signupModel.phone"
               placeholder="{{'SIGN_UP.PHONE'|translate}}">
      </div>
      <div class="col-6">
        <label class="lbl_field_small col-12">
          <div *ngIf="errorModel!=null && errorModel['Password']!=null" class="invalid_input_text">
            {{errorModel['Password'] | translate}}
          </div>
          <div
            *ngIf="(signupModel.password !== undefined && !isPasswordValid()) && (errorModel==null || errorModel['Password']==null)"
            class="invalid_input_text">
            {{'INFO.INVALID_PASSWORD' | translate}}
          </div>
        </label>
        <p-password [(ngModel)]="signupModel.password" [toggleMask]="true" [feedback]="false"
                    placeholder="{{'SIGN_UP.PASSWORD'|translate}}"
                    [styleClass]="'p-password p-component p-inputwrapper p-input-icon-right col-12'"
                    [id]="'sign_up_password_filed'" (click)="openTooltip(t1)" (mouseleave)="closeTooltip(t1)"
                    (mouseenter)="openTooltip(t1)" [inputStyleClass]="'input_field password-input-field'"
                    [ngbTooltip]="passwordInfoTooltipContent" [animation]="false" [placement]="['right', 'left']"
                    triggers="manual"
                    tooltipClass="signup-password-tooltip" #t1="ngbTooltip" [autoClose]="false"></p-password>
      </div>
      <div class="col-6">
        <label class="lbl_field_small col-12">
          <div *ngIf="errorModel!=null && errorModel['ConfirmPassword']!=null" class="invalid_input_text">
            {{errorModel['ConfirmPassword'] | translate}}
          </div>
        </label>
        <p-password [(ngModel)]="signupModel.confirmPassword" [feedback]="false" [toggleMask]="true"
                    placeholder="{{'SIGN_UP.CONFIRM_PASSWORD'|translate}}"
                    [styleClass]="'p-password p-component p-inputwrapper p-input-icon-right'"
                    [id]="'sign_up_password_filed'"
                    [inputStyleClass]="'input_field password-input-field'">
        </p-password>
      </div>
      <div class="col-6 mb-4">
        <button class="btn_login_manager btn_sign_up w-100" (click)="signup(signupModel)"
                [disabled]="spinner || !isPasswordValid() || email.errors?.pattern">{{'INFO.SIGN_UP' | translate}}</button>
        <div class="small_spinner_wrapper_no_auth mb-3 mt-2" *ngIf="spinner">
          <p-progressSpinner class="w-100 text-center spinner_small"></p-progressSpinner>
        </div>
      </div>
      <div class="col-6 d-flex align-self-center mb-3">
        <div>
          <label class="lbl_field_small col">
            <div *ngIf="errorModel!=null && errorModel['TermsAndConditions']!=null" class="invalid_input_text">
              {{errorModel['TermsAndConditions'] | translate}}
            </div>
          </label>
        </div>

        <p-checkbox [binary]="true" [(ngModel)]="signupModel.termsAndConditionsAccepted" styleClass="terms-and-conditions-checkbox"></p-checkbox>
        <span class="p_sign_up ms-2">{{'INFO.AGREE_TO' | translate}}
          <span class="p_sign_up terms-and-conditions" (click)="openTermsAndConditionDialog()"> {{'INFO.TERMS_AND_CONDITIONS' | translate}}</span></span>
      </div>
      <div class="col-6">
        <p class="p_sign_up">{{'INFO.ALREADY_REGISTERED' | translate}}
          <a type="button" routerLink="/login" class="signup_link text-center">{{'INFO.SIGN_IN' | translate }}</a></p>
      </div>
      <div class="col-6 mb-4">
        <button class="btn-outline-transparent" (click)="signUpWithFacebook()" [disabled]="spinner">
          <i class="pi social-icon fb-icon me-3"></i>
          {{'INFO.SIGN_UP_FB' | translate}}</button>
      </div>
      <!-- <div class="col-6 mb-4">
        <button class="btn-outline-transparent" (click)="signUpWithApple()"  [disabled]="spinner">
          <i class="pi social-icon apple-icon me-3"></i>
          {{'INFO.SIGN_UP_APPLE' | translate}}</button>
      </div> -->
    </div>
  </div>
</div>

<ng-template #passwordInfoTooltipContent>
  <div class="password-tooltip-content">
    <span>{{'INFO.PASSWORD_PATTERN_MESSAGE_1' | translate}} </span>
    <span [class.password-rule-checked]="has8Characters()">{{'INFO.PASSWORD_PATTERN_MESSAGE_2' | translate}} </span>
    <span>{{'INFO.PASSWORD_PATTERN_MESSAGE_3' | translate}}</span>
    <ul class="m-0">
      <li [class.password-rule-checked]="hasUppercaseLetter()">{{'INFO.PASSWORD_1_UPPERCASE' | translate}}</li>
      <li [class.password-rule-checked]="hasLowercaseLetter()">{{'INFO.PASSWORD_1_LOWERCASE' | translate}}</li>
      <li [class.password-rule-checked]="hasSpecialCharacter()">{{'INFO.PASSWORD_SPECIAL_CHAR' | translate}}
      </li>
    </ul>
  </div>
</ng-template>
