<p-progressSpinner class="w-100 text-center soc-manager-spinner" *ngIf="spinner"></p-progressSpinner>
<div class="panel-wrapper">
  <div class="pb-4 text-center delete-message">
    <h4>{{'EDIT_PROFILE.CONFRIM_DELETE_PROFILE_MESSAGE' | translate}}</h4>
  </div>
  <div class="d-flex flex-row justify-content-between">
    <button class="create-btn w-45 mt-0" (click)="deleteAccount()">{{'DELETE' | translate}}</button>
    <button class="btn-cancel-no-border w-45" (click)="cancel()">{{'CANCEL' | translate}}</button>
  </div>
</div>
