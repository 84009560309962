<p-progressSpinner class="soc-manager-spinner spinner-in-front" *ngIf="spinner"></p-progressSpinner>
<div class="d-flex justify-content-between ps-4 pe-4 pb-4">
  <div class="hosts-title">{{'HOST.HOSTS' | translate}}</div>
  <div class="w-25 ms-2 d-flex justify-content-center">
    <button class="btn-outline-transparent magenta-color m-0" (click)="openAddHostsPopup()">{{'ADD' | translate}} +</button>
  </div>
</div>
<div *ngIf="spinner && hosts.length === 0" class="hosts-spinner-helper"></div>
<div class="hosts-table-container">
  <p-table [value]="hosts" dataKey="id" editMode="row" styleClass="p-datatable-sm" responsiveLayout="stack"
           [breakpoint]="'550px'" [tableStyle]="{'width':'100%', 'cursor': 'pointer'}"
           [(selection)]="selectedHosts">
    <ng-template pTemplate="body" let-host let-editing="editing" let-ri="rowIndex">
      <tr [pEditableRow]="host" [class.lighter]="editing" [pSelectableRow]="host">
        <td class="table-checkbox-wrapper">
          <p-tableCheckbox [value]="host"></p-tableCheckbox>
        </td>
        <td [class.lighter]="editing" class="hosts-table-first-column" title="{{host.firstName}}">
          <b class="p-column-title">{{'HOST.NAME' | translate}}</b>
          <p-cellEditor>
            <ng-template pTemplate="input">
              <input pInputText type="text" [(ngModel)]="host.firstName" required>
            </ng-template>
            <ng-template pTemplate="output">
              {{host.firstName}}
            </ng-template>
          </p-cellEditor>
        </td>
        <td [class.lighter]="editing" title="{{host.lastName}}">
          <b class="p-column-title">{{'HOST.SURNAME' | translate}}</b>
          <p-cellEditor>
            <ng-template pTemplate="input">
              <input pInputText type="text" [(ngModel)]="host.lastName" required>
            </ng-template>
            <ng-template pTemplate="output">
              {{host.lastName}}
            </ng-template>
          </p-cellEditor>
        </td>
        <td [class.lighter]="editing" title="{{host.email}}">
          <b class="p-column-title">{{'HOST.EMAIL' | translate}}</b>{{host.email}}
        </td>
        <td [class.lighter]="editing" title="{{host.phone}}">
          <b class="p-column-title">{{'HOST.PHONE' | translate}}</b>
          <p-cellEditor>
            <ng-template pTemplate="input">
              <input pInputText type="number" class="custom_number_input" [(ngModel)]="host.phone" required>
            </ng-template>
            <ng-template pTemplate="output">
              {{host.phone}}
            </ng-template>
          </p-cellEditor>
        </td>
        <td [class.lighter]="editing" class="hosts-table-last-column">
          <b class="p-column-title"></b>
          <div class="flex align-items-center justify-content-center gap-2">
            <button *ngIf="!editing" pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil"
                    (click)="onRowEditInit(host)" class="p-button-rounded table-btn"></button>
            <button *ngIf="!editing" pButton pRipple type="button" icon="pi pi-trash"
                    (click)="deactivateHost(host.id)"
                    class="p-button-rounded table-btn"></button>
            <button *ngIf="editing" pButton pRipple type="button" pSaveEditableRow icon="pi pi-check"
                    (click)="onRowEditSave(host)"
                    class="p-button-rounded table-btn magenta-color mr-2"></button>
            <button *ngIf="editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times"
                    (click)="onRowEditCancel(host, ri)" class="p-button-rounded table-btn"></button>
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
<div class="d-flex flex-row justify-content-between pt-3 mt-3" style="padding: 0 20px 0 20px;">
  <button class="create-btn w-50 me-4" (click)="assignHosts()">{{'SAVE' |translate}}</button>
  <div class="w-50 ms-2 d-flex justify-content-center">
    <button class="btn-outline-transparent m-0" (click)="cancel()">{{'CLOSE' | translate}}</button>
  </div>
</div>
<p-toast position="bottom-center" [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'"></p-toast>
