import {InvoiceModel} from "./invoice.model";

export class PaymentInformationModel {
    public id: string;
    public currentPlan: string;
    public memberDate: any;
    public validUntil: any;
    public country: string;
    public paymentMethod: Array<any>;
    public invoices: Array<InvoiceModel>;
  }
