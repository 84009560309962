import { Component, OnInit } from '@angular/core';
import {Subject, takeUntil} from "rxjs";
import { ProductModel } from "../../../../share-modul/models/product/product-model";
import { SpecialOfferModel } from "../../../models/special-offer/special-offer.model";
import { TranslateService } from "@ngx-translate/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ProductPlanModel } from "../../../models/products/product-plan.model";
import { PaymentDialogComponent } from '../payment-dialog/payment-dialog.component';
import { DialogService } from 'primeng/dynamicdialog';
import {ProductService} from "../../../services/products/products.service";
import {AuthenticationService} from "../../../../share-modul/services/authentication/authentication.service";
import {UserModel} from "../../../../share-modul/models/user/user.model";
import {AccessProjectsService} from "../../../services/products/access-projects.service";


@Component({
  selector: 'app-upgrade-plan',
  templateUrl: './upgrade-plan.component.html',
  styleUrls: ['./upgrade-plan.component.scss']
})
export class UpgradePlanComponent implements OnInit {

  //#region Fields
  public unsubscribe: Subject<void> = new Subject<void>();
  public spinner: boolean;
  public productId: string;
  public productDetails: ProductModel;
  public specialOffer: SpecialOfferModel;
  public currentPlanId: string;
  public user: UserModel;
  //#endregion

  //#region Constructor
  constructor(
    private translate: TranslateService,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private router: Router,
    private authService: AuthenticationService,
    private productService: ProductService,
    private accessProjectsService: AccessProjectsService
  ) {
  }

  ngOnInit(): void {
    this.route.params.subscribe(async params => {
      this.productId = params['id'];
      this.productDetails = this.accessProjectsService.getProjectDetails([this.productId])[0];
      this.authService.loginUser.subscribe(response => {
        if(response) {
          this.user =  response;
          this.getProductDetails();
          this.productDetails.plans?.forEach(plan => {
            if (plan.userActivated){
            this.currentPlanId = plan.id;
          }});
        }
      });
    });
    this.getSpecialOffer();
  }
  //#endregion
  public getProductDetails(){
    this.productService.getProductDetails(this.productId, this.user.id)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
        let plans: Array<ProductPlanModel> = response;
        this.productDetails.plans = plans;
      })
  }

  //#region Public Methods

  public getSpecialOffer(): void {
    this.productService.getSpecialOffer()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
        const product: SpecialOfferModel = new SpecialOfferModel();
        product.name = response.name;
        product.maxNumberOfVenuesForPrimaryProjects = response.maxNumberOfVenuesForPrimaryProjects;
        product.maxNumberOfVenuesForSecondaryProjects = response.maxNumberOfVenuesForSecondaryProjects;
        product.monthlyPrice = response.mounthlyPrice;
        product.monthlyProcessorProductId = response.mounthlyProcessorProductId;
        product.yearlyPrice = response.yearlyPrice;
        product.yearlyProcessorProductId = response.yearlyProcessorProductId;
        product.validUtilDate = new Date(response.validUtilDate);
        product.specialProductProjects = response.specialProductProjects;
        product.boughtByTheUser = response.boughtByTheUser;
        this.specialOffer = product;
      });
  }

  public openPaymentDialog(productPlan: any): void {
    const ref = this.dialogService.open(PaymentDialogComponent, {
      data: {
        productPlan: productPlan,
        isSpecialOffer: false
      },
      header: this.translate.instant('PRODUCT.PAYMENT_OPTIONS'),
      styleClass: 'dialog_long , ml_200, mb_15, dialog-cyan-header-text'
    });
    ref.onClose.subscribe((item: any) => {
      if (item) {
        // let summary = this.translate.instant("HEADER.PROFILE_UPDATED");
        // this.messageService.add({severity: 'success', summary, life: 3000});
      }
    });
  }

  public goBackToProduct() {
    this.router.navigate(['/products', this.productId, 'details']);
  }
  //#endregion
}
