import { Component, ElementRef, OnInit } from '@angular/core';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { Subject, take, takeUntil } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { DeleteImagesConfirmComponent } from "./delete-images-confirm/delete-images-confirm.component";
import { UploadImageModel } from "../../models/image/upload-image.model";
import { CompressImageService } from "../../services/common/compress-image.service";
import { ManageVenuesService } from 'src/app/socialite-manager-panel/services/venues/venues.service';
import { MessageService } from 'primeng/api';
import { UpdateGaleryImageModel } from 'src/app/socialite-manager-panel/models/venues/update-gallery.model';
import { CreateGaleryImageModel } from 'src/app/socialite-manager-panel/models/venues/create-gallery.model';


@Component({
  selector: 'app-multiple-images-upload',
  templateUrl: './multiple-images-upload.component.html',
  styleUrls: ['./multiple-images-upload.component.scss']
})
export class MultipleImagesUploadComponent implements OnInit {

  //#region Fields
  public venueId: string;
  public pictureFormat: Array<string>;
  public pictureIsWrongFormat: boolean = false;
  public tooManyFiles: boolean = false;
  // maxSize: number = 3000000;  //3 MB = 3000000 Bytes (in decimal)
  public images: UploadImageModel[] = [];
  public imagesToDelete: UploadImageModel[] = [];
  public deleteImages: boolean = false;
  public unsubscribe: Subject<void> = new Subject<void>();
  public spinner: boolean = false;
  //#endregion

  //#region Constructor
  constructor(
    private compressImage: CompressImageService,
    private openFile: ElementRef,
    public dialogService: DialogService,
    public venueService: ManageVenuesService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public translate: TranslateService,
    private messageService: MessageService,

  ) {
    this.pictureFormat = ['image/png', 'image/jpg', 'image/jpeg'];

    if (this.config.data != undefined && this.config.data.venueId) {
      this.venueId = this.config.data.venueId;
    }
  }

  public ngOnInit(): void {
    this.viewImageGallery();

  }
  //#endregion

  //#region Public Methods
  public viewImageGallery() {
    this.spinner = true;
    this.venueService.viewVenueGallery(this.venueId)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: (response: any) => {
          response.forEach((image: any) => {
            this.images.push({
              uploaded: true,
              imagePath: image.imagePath,
              progress: 100,
              imageId: image.id
            })
          });
          this.spinner = false;
        },
        error: () => {
          this.spinner = false;
          this.messageService.add({ severity: 'error', summary: "System error", life: 2000 });
        }
      })
  }

  public saveImageGallery(){
    
    if (this.images) {
      let filteredImages = new Array<any>();
      let ExistingPictureUrls = new Array<any>();
      filteredImages = this.images.filter(image => !image.uploaded).map(image => image.filePicture);
      ExistingPictureUrls = this.images.filter(image => image.uploaded).map(image => image.imagePath);
      if (ExistingPictureUrls.length > 0) {
        const updateGaleryImageModel = new UpdateGaleryImageModel();
        updateGaleryImageModel.venueId = this.venueId;
        updateGaleryImageModel.ExistingPictureUrls = ExistingPictureUrls;
        updateGaleryImageModel.Attachments = filteredImages;
        this.spinner = true;
        this.venueService.updateVenueGalery(updateGaleryImageModel)
          .pipe(takeUntil(this.unsubscribe))
          .subscribe({
            next: (response: any) => {
              this.spinner = false;
              this.ref.close(true);
            },
            error: () => {
              this.spinner = false;
              this.messageService.add({ severity: 'error', summary: "System error", life: 2000 });
            }
          });
      } else {
        const createGaleryImageModel = new CreateGaleryImageModel();
        createGaleryImageModel.venueId = this.venueId;
        createGaleryImageModel.Attachments = filteredImages;
        this.spinner = true;
        this.venueService.addVenueGalery(createGaleryImageModel)
          .pipe(takeUntil(this.unsubscribe))
          .subscribe({
            next: (response: any) => {
              this.spinner = false;
              this.ref.close(true);
            },
            error: () => {
              this.spinner = false;
              this.messageService.add({ severity: 'error', summary: "System error", life: 2000 });
            }
          });
      }

    }
  }

  public close(): void {
    this.ref.close(false);
  }

  public onSelectFile(event: any): void {
    this.processImages(event.target.files);
  }

  public onDropFile(files: any[]): void {
    this.processImages(files);
  }

  public processImages(files: any[]): void {
    this.tooManyFiles = files.length + this.images.length > 10;
    if (!this.fileFormatValid(files) || this.tooManyFiles) {
      return;
    }

    if (files) {
      for (let i = 0; i < files.length; i++) {
        let file = files[i];
        this.images.push({
          imagePath: '',
          progress: 0,
          imageName: file.name,
          filePicture: '',
          uploaded: false
        });
        let reader = new FileReader();
        this.compressImage.compress(file)
          .pipe(take(1))
          .subscribe(compressedImage => {
            let imageIndex = this.images.findIndex(x => x.imageName === file.name);
            this.images[imageIndex].filePicture = compressedImage;
          });
        reader.onload = (item) => {
          let imageIndex = this.images.findIndex(x => x.imageName === file.name);
          this.images[imageIndex].imagePath = item.target?.result;
          this.images[imageIndex].progress = 100;
        }
        reader.onprogress = (event) => {
          let imageIndex = this.images.findIndex(x => x.imageName === file.name);
          this.images[imageIndex].progress = (event.loaded / event.total) * 100;
        }
        reader.readAsDataURL(file);
      }
    }
  }

  public openAddImage(): void {
    var element = this.openFile.nativeElement.querySelector(".open-file");
    element.click();
  }

  public fileFormatValid(files: any[]): boolean {
    this.pictureIsWrongFormat = false;
    for (let i = 0; i < files.length; i++) {
      if (!this.pictureFormat.includes(files[i].type)) {
        this.pictureIsWrongFormat = true;
        return false;
      }
    }
    return true;
  }

  public deleteSelectedImages(image: any): void {
    const indexOfImage = this.images.findIndex((img) => {
      return img.imagePath === image.imagePath;
    });
    if (indexOfImage !== -1) {
      this.images.splice(indexOfImage, 1);
    }
    // if (this.imagesToDelete.length === 0) {
    //   return;
    // }
    // const ref = this.dialogService.open(DeleteImagesConfirmComponent, {
    //   data: {
    //     venueId: this.venueId,
    //     images: this.imagesToDelete.filter(image => image.uploaded)
    //   },
    //   width: '380px',
    //   showHeader: false,
    //   styleClass: 'dialog_long'
    // });
    // ref.onClose.subscribe((item: any) => {
    //   if (item) {
    //     this.images = this.images.filter((image: UploadImageModel) => !this.imagesToDelete.includes(image));
    //   }
    //   this.deleteImages = false;
    //   this.imagesToDelete = [];
    //   this.tooManyFiles = this.images.length > 10;
    // });
  }
  //#endregion
}
