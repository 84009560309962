<div class="div-venue">
  <div class="w-100 venues-header-container">
    <label class="lbl-top-venues">{{'VENUE.ALL_VENUES' | translate | uppercase}}</label>
    <button *ngIf="listAllVenues.length > 0" class="create-btn create-venue-btn" (click)="createVenue()">
      <span>{{'VENUE.ADD_VENUE' | translate}}</span>
    </button>
  </div>
  <div class="venues-container"
       [class.unset-grid-settings]="spinner"
       [class.no-venues-screen]="!spinner && listAllVenues.length === 0"
       style="overflow: auto;box-sizing: border-box;max-height: 100%;"
       infiniteScroll [infiniteScrollDistance]="1" [alwaysCallback]="true"
    [immediateCheck]="true"
    [infiniteScrollUpDistance]="1000" (scrolled)="loadMoreVenues()" [scrollWindow]="false">
    <p-progressSpinner class="w-100 text-center spinner-relative" *ngIf="spinner"></p-progressSpinner>
    <ng-container *ngIf="!spinner && listAllVenues.length === 0; else list">
      <div class="w-100"><img src="assets/icons/venue-icon.svg" alt="Venue icon"></div>
      <div class="w-100 add-venue-message">{{'Add your first venue' | translate}}</div>
      <button class="w-100 create-btn add-venue-btn" (click)="createVenue()">
        <span>{{'ADD'| translate}}</span>
      </button>
    </ng-container>
    <ng-template #list>
      <!-- <div class="inventory-items-container" > -->
      <ng-container *ngFor="let venues of listAllVenues">
        <div>
        <div [ngClass]="venues.instagramId ? 'div-card-venue card-bottom-radius-none' : 'div-card-venue'">
        <div>
            <div class="menu-dropdown-helper pe-0 pt-0" #icon (click)="menu.toggle($event)"></div>
            <i class="pi pi-ellipsis-v spam-menu venue-menu pe-0 pt-0" (click)="icon.click()"></i>
            <p-menu #menu class="venues-menu" [popup]="true" [model]="items[venues.id]"></p-menu>
        </div>
        <div>
          <div class="w-100 d-flex flex-wrap justify-content-center venue-logo-container">
            <img class="mb-2 venue-logo"
                 [src]="venues.pictureUrl ? venues.pictureUrl : imagePath"
                 alt="profile-picture">
            <div class="w-100"></div>
            <span class="venue-name" title="{{venues.name}}">{{venues.name}}</span>
          </div>
          <div class="w-100 d-flex flex-wrap">
            <div class="w-100 venue-info-container">
              <label class="lbl-venue">
                <img src="assets/icons/phone_bluetooth_speaker_24px.svg" alt="Phone icon">
              </label>
              <label class="lbl-venue-value">{{venues.phone}}</label>
            </div>
            <div class="w-100 venue-info-container">
              <label class="lbl-venue">
                <img src="assets/icons/add_location_24px.svg" alt="Location icon">
              </label>
              <label class="lbl-venue-value" title="{{venues.address}}">{{venues.address}}</label>
            </div>
          </div>
        </div>
        </div>
          <button *ngIf="venues.instagramId" class="instagram-posts-btn" (click)="showInstagramPosts(venues.id)">{{'VENUE.INSTAGRAM_POSTS' | translate}}</button>
        </div>
      </ng-container>
    </ng-template>
  </div>
</div>
