import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from "primeng/api";

@Injectable({
  providedIn: 'root'
})
export class TranslationHelperService {
  constructor(private translateService: TranslateService,
              private primeNGConfig: PrimeNGConfig) { }

  setActiveLanguage(value: string, id: null | number): void {
    this.translateService.use(value);
    this.translateService.get('primeng').subscribe(res => this.primeNGConfig.setTranslation(res));
    localStorage.setItem('language', value);
    let languageId: string = "";
    if (id != null) {
      languageId = id.toString()
    }
    localStorage.setItem('languageId', languageId);
  }

  initializeTranslate(): void {
    this.setActiveLanguage(this.languageFromLocalStorage, this.languageIdFromLocalStorage);
  }

  public get languageFromLocalStorage(): string {
    const langFromStorage = localStorage.getItem('language');

    if (langFromStorage) {
      return langFromStorage;
    } else {
      return 'en';
    }
  }

  public get languageIdFromLocalStorage(): number {
    const langIdFromStorage = localStorage.getItem('languageId');

    if (langIdFromStorage) {
      return parseInt(langIdFromStorage);
    } else {
      return 1;
    }
  }
}
