import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { MessageService } from 'primeng/api';
import { JwtHelperService } from '@auth0/angular-jwt';
import { RefreshTokenModel } from '../../models/refresh-token/refresh-token.model';
import { UserModel } from '../../models/user/user.model';
import { RoleNameEnum } from '../../enums/role-name.enum';

@Component({
  selector: 'app-login-with-refresh-token',
  templateUrl: './login-with-refresh-token.component.html',
  styleUrls: ['./login-with-refresh-token.component.scss']
})
export class LoginWithRefreshTokenComponent implements OnInit {

  //#region Fields
  public unsubscribe: Subject<void> = new Subject<void>();
  // public loginModel: LoginModel = new LoginModel();
  //#endregion

  //#region Constructor
  constructor(
    public _router: Router,
    public authenticationService: AuthenticationService,
    public messageService: MessageService,
    private _jwtHelperService: JwtHelperService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(async params => {
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      if (params['refresh-token'].length >= 86) {
        const refreshToken = new RefreshTokenModel();
        refreshToken.token = params['refresh-token'] + "==";
        this.authenticationService.refreshToken(refreshToken)
          .pipe(takeUntil(this.unsubscribe))
          .subscribe({
            next: (response) => {
              const decodedToken = this._jwtHelperService.decodeToken(response.jwtToken);
              const loggedInUser = new UserModel(decodedToken.id, decodedToken.rlnm.split(',').map((x: string) => +x));
              if(loggedInUser.roles.includes(RoleNameEnum.AdminGeneralManager || RoleNameEnum.PosGeneralManager || RoleNameEnum.ResyManager)){
                localStorage.setItem('access_token', response.jwtToken)
                localStorage.setItem('refresh_token', response.refreshToken);
                this.authenticationService.loginUser.next(loggedInUser);               
                       
                const cmid = decodedToken.cmid;
                const isAdvertiserPaid = decodedToken.iap != undefined ? JSON.parse(decodedToken.iap.toLowerCase()) : false;
                const isResyPaid = decodedToken.irp != undefined ? JSON.parse(decodedToken.irp.toLowerCase()) : false
                const IsPOSPaid = decodedToken.ipp != undefined ? JSON.parse(decodedToken.ipp.toLowerCase()) : false
              
                if (cmid) {
                  if (isAdvertiserPaid || isResyPaid || IsPOSPaid) {
                    this._router.navigate(['manage-venues']);
                  } else {
                    this._router.navigate(['products']);
                  }
                }
                else {
                  this._router.navigate(['company-info']);
                }

              } else {
                this._router.navigateByUrl('login');
              }
            },
            error: (error) => {
              this._router.navigateByUrl('login');
            }
          })
      } else {
        this._router.navigateByUrl('login');
      }
    });
  }

  //#endregion


}
