export class EditProfileModel {
  public id: string;
  public firstName: string = '';
  public lastName: string = '';
  public phone: string = '';
  public email: string = '';
  public dateOfBirth: any = '';
  public address: string = '';
  public profilePictureUrl: string = '';
}
	