export class AddOrEditHostModel {
  public hostId: string;
  public firstName: string;
  public lastName: string;
  public email: string;
  public phone: string;
  public companyId: string;
  public venueIds: Array<string> = new Array<string>();
  public languageId: number;
}
