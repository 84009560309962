<div class="soc_manager_header d-flex justify-content-between align-items-center">
    <div class="menu_bar" (click)="showHideMenu()" *ngIf="showSwitch">
        <i class="pi c-icons-black menu-icon" *ngIf="!inMenu"></i>
        <i class="pi pi-angle-double-right ms-1" *ngIf="inMenu"></i>
    </div>
    <div class="header_user">
        <div class="user_info">
            <label class="user_lbl_name">{{firstNameLastName}}</label>
            <label class="user_lbl_email">{{email}}</label>
        </div>
        <div>
            <img src="{{imagePath}}" class="user_image" alt="" style="cursor: pointer;"
                (click)="menu_user.toggle($event)">
        </div>
    </div>
    <p-menu #menu_user class="edit-menu" id="user_menu" [popup]="true" [model]="items"></p-menu>
</div>
