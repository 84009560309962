<p-progressSpinner class="soc-manager-spinner spinner-in-front" *ngIf="spinner"></p-progressSpinner>
<div class="custom-dialog-header">
  <div class="custom-dialog-close-container">
    <button type="button" class="custom-dialog-close-btn" (click)="close()">
      <span class="pi pi-times"></span>
    </button>
  </div>
</div>
<div class="panel-wrapper">
  <div class="upload-images-wrapper">
    <div class="upload-your-images-div">{{'VENUE.UPLOAD_IMAGES_MESSAGE_1' | translate}}</div>
    <div class="choose-images-div pb-4 m-0">{{'VENUE.UPLOAD_IMAGES_MESSAGE_2' | translate}}</div>
    <div class="upload-div d-flex flex-column" appDragNDrop (fileDropped)="onDropFile($event)">
      <img src="assets/icons/upload_24px.svg" style="height: 80px;">
      <div class="choose-images-div">
        {{'VENUE.UPLOAD_IMAGES_MESSAGE_3' | translate}}
      </div>
      <div class="choose-images-div">
        {{'VENUE.OR' | translate}}
      </div>
      <div class="choose-images-div d-flex justify-content-center">
        <button class="create-btn w-50" (click)="openAddImage()">{{'VENUE.BROWSE' | translate}}</button>
      </div>
      <div class="choose-images-div">{{images.length}}/10
        <label *ngIf="pictureIsWrongFormat && !tooManyFiles">
          <i class="pi pi-exclamation-circle me-1 error-color"
             pTooltip="{{'VENUE.WRONG_FORMAT' | translate}}" tooltipPosition="top"></i>
        </label>
        <label *ngIf="tooManyFiles">
          <i class="pi pi-exclamation-circle me-1 error-color"
             pTooltip="{{'VENUE.TOO_MANY_FILES' | translate}}" tooltipPosition="top"></i>
        </label>
      </div>
    </div>
    <input style="display: none;" type="text" #eventImagePath/>
    <input (change)="onSelectFile($event)" class="open-file" style="display: none;" type="file" id="openFile" #openFile
           name="openFile" accept="image/png, image/jpg, image/jpeg" multiple>
  </div>
  <div *ngIf="images.length > 0" class="d-flex flex-column mt-3">
    <div class="d-flex flex-column all-images-div">
      <ng-container *ngFor="let image of images">
        <div class="mt-1 d-flex flex-row" *ngIf="image.progress === 100">
          <div class="w-100 d-flex justify-content-between flex-row align-items-center loaded-img-container">
            <div class="d-flex align-items-center">
              <img [src]="image.imagePath" class="loaded-image">
              <span class="progress-bar-custom-label-loaded" title="{{image.imageName}}">{{image.imageName}}</span>
            </div>
            <div>
              <button pButton pRipple type="button" icon="pi pi-trash" class="delete-image-btn"
                      (click)="deleteSelectedImages(image);"></button>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngFor="let image of images">
        <div class="mt-1 w-100 mb-3 position-relative" *ngIf="image.progress < 100">
          <span class="progress-bar-custom-label" title="{{image.imageName}}">{{image.imageName}}</span>
          <p-progressBar [showValue]="false" [value]="image.progress" class="mt-1"></p-progressBar>
          <span class="progress-bar-custom-label percentage">{{image.progress | number: '1.0-0'}}%</span>
        </div>
      </ng-container>    
    </div>
  </div>
  <button class="create-btn w-100 mt-3" (click)="saveImageGallery()">{{'SAVE' |translate}}</button>
</div>
<p-toast position="bottom-center" [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'"></p-toast>

