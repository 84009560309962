import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { ToastModule } from 'primeng/toast';
import { CalendarModule } from 'primeng/calendar';
import { TabViewModule } from 'primeng/tabview';
import { TabMenuModule } from 'primeng/tabmenu';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { MenuModule } from 'primeng/menu';
import { ContextMenuModule } from 'primeng/contextmenu';
import { MenubarModule } from 'primeng/menubar';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { TableModule } from 'primeng/table';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { SelectButtonModule } from 'primeng/selectbutton';
import { PanelMenuModule } from 'primeng/panelmenu';
import { PasswordModule } from "primeng/password";
import { CardModule } from "primeng/card";
import { CheckboxModule } from "primeng/checkbox";
import { OverlayPanelModule } from "primeng/overlaypanel";
import { DragDropModule } from 'primeng/dragdrop';
//import { FullCalendarModule } from '@fullcalendar/angular';
//import dayGridPlugin from '@fullcalendar/daygrid';
//import timeGridPlugin from '@fullcalendar/timegrid';
//import interactionPlugin from '@fullcalendar/interaction';
import { InputTextModule } from 'primeng/inputtext';
import { ConfirmPopupModule } from "primeng/confirmpopup";
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ProgressBarModule } from "primeng/progressbar";
import { InputSwitchModule } from "primeng/inputswitch";
import { RippleModule } from "primeng/ripple";
import { RadioButtonModule } from 'primeng/radiobutton';

/*FullCalendarModule.registerPlugins([
  dayGridPlugin,
  timeGridPlugin,
  interactionPlugin
]);*/

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ButtonModule,
    ToastModule,
    CalendarModule,
    TabViewModule,
    TabMenuModule,
    DropdownModule,
    MultiSelectModule,
    MenuModule,
    ContextMenuModule,
    MenubarModule,
    DynamicDialogModule,
    TableModule,
    ProgressSpinnerModule,
    MessagesModule,
    MessageModule,
    SelectButtonModule,
    PanelMenuModule,
    PasswordModule,
    CardModule,
    CheckboxModule,
    OverlayPanelModule,
    DragDropModule,
    //FullCalendarModule,
    InputTextModule,
    //CalendarModule,
    CheckboxModule,
    ButtonModule,
    TabViewModule,
    ConfirmPopupModule,
    AutoCompleteModule,
    ProgressBarModule,
    InputSwitchModule,
    RippleModule,
    RadioButtonModule,
  ],
  exports: [
    ButtonModule,
    ToastModule,
    CalendarModule,
    TabViewModule,
    TabMenuModule,
    DropdownModule,
    MultiSelectModule,
    MenuModule,
    ContextMenuModule,
    MenubarModule,
    DynamicDialogModule,
    TableModule,
    ProgressSpinnerModule,
    MessagesModule,
    MessageModule,
    SelectButtonModule,
    PanelMenuModule,
    PasswordModule,
    CardModule,
    CheckboxModule,
    OverlayPanelModule,
    DragDropModule,
    //FullCalendarModule,
    InputTextModule,
    ConfirmPopupModule,
    AutoCompleteModule,
    ProgressBarModule,
    InputSwitchModule,
    RippleModule,
    RadioButtonModule,
  ],
  providers: [MessageService, ConfirmationService]
})
export class PrimeNgModule { }
