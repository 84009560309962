import { NgModule } from '@angular/core';
import { SocialiteManagerRoutingModule } from "./socialite-manager-routing.module";
import { SharedModule } from "../shared/shared.module";
import { ManageVenuesComponent } from "../../socialite-manager-panel/components/manage-venues/manage-venues.component";
import {
  AddOrEditVenuesComponent
} from "../../socialite-manager-panel/components/manage-venues/add-or-edit-venues/add-or-edit-venues.component";
import {
  DeleteVenueComponent
} from "../../socialite-manager-panel/components/manage-venues/delete-venue/delete-venue.component";
import { EditHostsComponent } from "../../socialite-manager-panel/components/hosts/edit-hosts/edit-hosts.component";
import { AddHostComponent } from "../../socialite-manager-panel/components/hosts/add-host/add-host.component";
import {
  AddOrEditVenuesStepTwoComponent
} from "../../socialite-manager-panel/components/manage-venues/add-or-edit-venues-step-two/add-or-edit-venues-step-two.component";
import {
  CancelSubscriptionConfirmationDialogComponent
} from "../../socialite-manager-panel/components/products/cancel-subcription-confirmation-dialog/cancel-subscription-confirmation-dialog.component";
import { ProductsComponent } from "../../socialite-manager-panel/components/products/products.component";
import {
  ProductDetailsComponent
} from "../../socialite-manager-panel/components/products/product-details/product-details.component";
import {
  UpgradePlanComponent
} from "../../socialite-manager-panel/components/products/upgrade-plan/upgrade-plan.component";
import {
  PaymentDialogComponent
} from "../../socialite-manager-panel/components/products/payment-dialog/payment-dialog.component";
import {
  SpecialOfferComponent
} from "../../socialite-manager-panel/components/products/special-offer/special-offer.component";
import { TrialOfferComponent } from 'src/app/socialite-manager-panel/components/products/trial-offer/trial-offer.component';
import { TrialDialogComponent } from 'src/app/socialite-manager-panel/components/products/trial-dialog/trial-dialog.component';


@NgModule({
  declarations: [
    ManageVenuesComponent,
    AddOrEditVenuesComponent,
    DeleteVenueComponent,
    AddHostComponent,
    EditHostsComponent,
    AddOrEditVenuesStepTwoComponent,
    CancelSubscriptionConfirmationDialogComponent,
    ProductsComponent,
    ProductDetailsComponent,
    UpgradePlanComponent,
    PaymentDialogComponent,
    SpecialOfferComponent,
    TrialOfferComponent,
    TrialDialogComponent
  ],
  imports: [
    SocialiteManagerRoutingModule,
    SharedModule,
  ],
  exports: []
})
export class SocialiteManagerModule { }
