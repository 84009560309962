<section class="app-outlet">
  <app-header *ngIf="!isSuperAdmin && isLoggedin && !isCompanyInfoRelated()"
              [showSwitch]="!isProductsRelated()"></app-header>
  <main class="h-100">
    <div class="h-100 d-flex">
      <app-navbar *ngIf="!isSuperAdmin && isLoggedin && !isCompanyInfoRelated() && !isProductsRelated()"></app-navbar>
      <div class="w-100" [ngClass]="[(!isSuperAdmin && isLoggedin && !isCompanyInfoRelated())? 'div_router_outlet'  : '', ]">
        <router-outlet>
        </router-outlet>
      </div>
    </div>
  </main>
</section>
<p-toast position="bottom-center" [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'"></p-toast>
