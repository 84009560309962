import { Component, OnInit } from '@angular/core';
import {Subject, takeUntil} from "rxjs";
import { Message, MessageService } from "primeng/api";
import { AuthenticationService } from "../../services/authentication/authentication.service";
import { ForgotPasswordModel } from "../../models/forgot-password/forgot-password.model";

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  //#region Fields
  public unsubscribe: Subject<void> = new Subject<void>()
  public forgotPasswordModel: ForgotPasswordModel = new ForgotPasswordModel();
  public errorModel: { [k: string]: any } = {};
  public errorMsg: Message[];
  public requiredFieldErrorMessage = "INFO.REQUIRED_FIELD";
  public spinner: boolean = false;
  //#endregion
  //#region Constructor
  constructor(
    public authenticationService: AuthenticationService,
    public messageService: MessageService
  ) { }

  ngOnInit(): void {

  }
  //#endregion

  //#region Public Methods
  sendForgotPasswordRequest(model: ForgotPasswordModel) {
    this.errorModel = {};
    this.errorMsg = [];

    model.languageId = this.languageIdFromLocalStorage();

    if (model && (!model.email || model.email.trim() == "")) {
      this.errorModel['Email'] = this.requiredFieldErrorMessage;
    }

    if (Object.keys(this.errorModel).length === 0) {
      this.spinner = true;

      this.authenticationService.forgotPassword(model)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe({
          next: (response) => {
            this.messageService.add({severity: 'success', summary: response.message, life: 2000});
            this.spinner = false;
          },
          error: errorResponse => {
            if (errorResponse.error && errorResponse.error.Message) {
              this.messageService.add({severity: 'error', summary: errorResponse.error.Message, life: 2000});
            } else if (errorResponse.error && !errorResponse.error.errors) {
              this.messageService.add({severity: 'error', summary: errorResponse.error.title, life: 2000});
            }
            this.spinner = false;
          }
        });
    }
  }

  public languageIdFromLocalStorage(): number {
    const langIdFromStorage = localStorage.getItem('languageId');

    if (langIdFromStorage) {
      return parseInt(langIdFromStorage);
    } else {
      return 1;
    }
  }
  //#endregion
}


