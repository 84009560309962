import { Component, OnInit } from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {PaymentService} from "../../../services/payments/payment.service";
import {UnsubscribeFromProductDto} from "../../../models/products/unsubscribe-from-product-dto";
import {MessageService} from "primeng/api";
import {Router} from "@angular/router";

@Component({
  selector: 'app-cancel-subscription-confirmation-dialog',
  templateUrl: './cancel-subscription-confirmation-dialog.component.html',
  styleUrls: ['./cancel-subscription-confirmation-dialog.component.scss']
})
export class CancelSubscriptionConfirmationDialogComponent implements OnInit {

  //#region Fields
  //#endregion
  private productId: string;
  private planId: string;
  //#region Constructor
  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private paymentService: PaymentService,
    private router: Router,
  ) {
    if (this.config.data != undefined && this.config.data.productId) {
        this.productId = this.config.data.productId;
        this.planId = this.config.data.planId;

    }
  }

  public ngOnInit(): void {
  }
  //#endregion

  //#region Public Methods
  public cancelPlan(): void {
    let request = new UnsubscribeFromProductDto();
    request.productId = this.planId;
    this.paymentService.unsubscribeFromProduct(request)
      .subscribe(response => {
          this.ref.close(response);
      },
        error => {
          this.ref.close(error);
        });
  }

  public chooseOtherPlan(): void {
    this.router.navigate(['/products', this.productId, 'upgrade-plan']);
    this.ref.close();
  }

  public close(): void {
    this.ref.close(false);
  }
  //#endregion
}
