export enum RoleNameEnum {
  Admin = 1,
  Manager = 2,
  Host = 3,
  SiteLocationManager = 4,
  AdminGeneralManager = 5,
  Subscriber = 6,
  PosGeneralManager = 7,
  PosManager = 8,
  Waiter = 9,
  Techsupport = 10,
  ResyHost = 11,
  ResyManager = 12,
  AppMobileUser = 13,
  AppService = 14,
}
