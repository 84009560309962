// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // apiURL: 'https://localhost:44338',
  apiURL: 'https://app.socialite.beer',
  URLSocialiteManager: 'https://socialite-manager.dev',
  URLAdvertiser: 'https://socialite.beer',
  URLResy: 'https://resy.pub',
  URLPos: 'https://socialite-pos.beer',
  enableDebug: true,
  firebase: {
    apiKey: "AIzaSyCWr8HkFH6Rq2nsECMW6VPgu6xfVXFEse4",
    authDomain: "socialite-manager-dev.firebaseapp.com",
    projectId: "socialite-manager-dev",
    storageBucket: "socialite-manager-dev.appspot.com",
    messagingSenderId: "431638114421",
    appId: "1:431638114421:web:36e64343688536773b083c",
    vapidKey: "BGNliHN37v34qVKGOT3kLAXthPJIBOWrhcpoxpba3kx0zDMNsimOmHatFbH0FvAKUxpRwPBs7UvtvGNrhNi697Y"
  },
  meta: {
    appId: "963038864931141",
    secret: "c9e64d9b883153a4d81ab7fce5174ce9"
  },
  stripe: {
    publishableKey: "pk_test_51KxhyeJ0WqFVa2NLGb0K7As6wN8HBN8aTWDQ9l1yKb0p8PXXdMnxBIj4ADhdhwjOcB8zHeXzQhZrXcY4L9wyIoio00N3iYG6Wy"
  },
  googleMaps:{
    apiKey: "AIzaSyDIA1FFQNkj8fRg_AMx_b2vsdJJp9GOl88"
  }
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
