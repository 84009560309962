import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subject, takeUntil } from 'rxjs';
import { ProductService } from 'src/app/socialite-manager-panel/services/products/products.service';

@Component({
  selector: 'app-trial-dialog',
  templateUrl: './trial-dialog.component.html',
  styleUrls: ['./trial-dialog.component.scss']
})
export class TrialDialogComponent implements OnInit {

   //#region Fields
 
   public spinner: boolean = false;
   public unsubscribe: Subject<void> = new Subject<void>();
   //#endregion
   //#region Constructor
  constructor(
    private config: DynamicDialogConfig,
    private ref: DynamicDialogRef,
    public productService: ProductService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
  }
 //#endregion
  //#region Public Methods
  startTrial() {
    this.spinner = true;
    this.productService.startTrial()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: (response) => {
          this.spinner = false;
        
          this.ref.close(response);
        },
        error: errorResponse => {
          this.spinner = false;
          this.messageService.add({ severity: 'error', summary: "System error", life: 2000 });
          this.ref.close(false);
        }
      });
  }

  cancel(): void {
    this.ref.close(false);
  }
   //#endregion
}
