import { Component, ElementRef, OnInit } from '@angular/core';
import { Subject, takeUntil } from "rxjs";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { MessageService } from "primeng/api";
import { AddOrEditHostModel } from "../../../models/hosts/add-or-edit-host.model";
import { HostService } from "../../../services/hosts/hosts.service";

@Component({
  selector: 'app-add-host',
  templateUrl: './add-host.component.html',
  styleUrls: ['./add-host.component.scss']
})
export class AddHostComponent implements OnInit {

  //#region Fields
  public unsubscribe: Subject<void> = new Subject<void>();
  public addOrEditHostModel: AddOrEditHostModel = new AddOrEditHostModel();
  public errorModel: { [k: string]: any } = {};
  public spinner: boolean = false;
  public requiredFieldErrorMessage = "INFO.REQUIRED_FIELD";
  //#endregion

  //#region Constructor
  constructor(private openFile: ElementRef,
              private ref: DynamicDialogRef,
              private config: DynamicDialogConfig,
              public hostService: HostService,
              public messageService: MessageService) {
    if (this.config.data != undefined && this.config.data.id && this.config.data.companyId) {
      this.addOrEditHostModel.venueIds.push(this.config.data.id);
      this.addOrEditHostModel.companyId = this.config.data.companyId;
    }
  }

  ngOnInit(): void {
  }

  //#endregion

  //#region Public Methods
  public createHost(model: AddOrEditHostModel) {
    this.errorModel = {};

    if (!model.firstName || model.firstName == "") {
      this.errorModel['FirstName'] = this.requiredFieldErrorMessage;
    }
    if (!model.lastName || model.lastName == "") {
      this.errorModel['LastName'] = this.requiredFieldErrorMessage;
    }
    if (!model.email || model.email == "") {
      this.errorModel['Email'] = this.requiredFieldErrorMessage;
    }
    if (!model.phone || model.phone == "") {
      this.errorModel['Phone'] = this.requiredFieldErrorMessage;
    }

    if (Object.keys(this.errorModel).length === 0) {
      model.languageId = this.languageIdFromLocalStorage();
      model.phone = model.phone.toString();
      this.spinner = true;

      this.hostService.createHost(model)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe({
          next: (response) => {
            this.spinner = false;
            this.ref.close(true);
          },
          error: errorResponse => {
            this.spinner = false;
            this.messageService.add({severity: 'error', summary: "System error", life: 2000});
          }
        });
    }
  }

  public languageIdFromLocalStorage(): number {
    const langIdFromStorage = localStorage.getItem('languageId');

    if (langIdFromStorage) {
      return parseInt(langIdFromStorage);
    } else {
      return 1;
    }
  }

  cancel(): void {
    this.ref.close(false);
  }
  //#endregion
}
