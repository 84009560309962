import {HttpService} from "../../../share-modul/services/common/http-service";
import {HttpClient} from "@angular/common/http";
import {map} from "rxjs";
import {environment} from "../../../../environments/environment";
import {Injectable} from "@angular/core";

const PRODUCT_API = environment.apiURL + '/api/business/Product/';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(private _httpService: HttpService, private _httpClient: HttpClient) {
  }

  getProductDetails(projectId: string, userId: string) {
      var url = PRODUCT_API + "getproducts?ProjectId=" + projectId + "&UserId=" + userId;
    return this._httpService.httpGet(url)
      .pipe(map(response => {
        return response;
      }));
  }

  getAllProjectIds() {
    var url = PRODUCT_API + "getallprojectids";
    return this._httpService.httpGet(url)
      .pipe(map(response => {
        return response;
      }));
  }

  getSpecialOffer() {
    var url = PRODUCT_API + "getspecialproduct";
    return this._httpService.httpGet(url)
      .pipe(map(response => {
        return response;
      }));
  }
  startTrial() {
    var url = PRODUCT_API + "startusingtrialperiod";
    return this._httpService.httpPost(url)
      .pipe(map(response => {
        return response;
      }));
  }
}
