import {Injectable} from "@angular/core";
import {ProjectEnum} from "../../enums/project-ids.enum";
import {environment} from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AccessProjectsService {

  private static PRODUCT_LIST = [
    {
      id: ProjectEnum.Pos.toString(),
      name: "Socialite POS",
      pictureUrl: "./assets/images/temporary/socialite-pos-logo.png",
      description: "Is an application designed specifically to manage restaurant and bars operations. It enables restaurants to manage all aspects of their business from a single platform, from taking orders and managing tables to inventory management and reporting.",
      url: environment.URLPos + "/login/"
    },
    {
      id: ProjectEnum.Resy.toString(),
      name: "Socialite Resy",
      pictureUrl: "./assets/images/temporary/socialite-resy-logo.png",
      description: "Socialite Resy is a restaurant and bars reservation software. Restaurant reservation software lets guests reserve a table online and alerts owners to new reservations, cancellations, or no-shows.",
      url: environment.URLResy + "/login/"
    },
    {
      id: ProjectEnum.Events.toString(),
      name: "Socialite Events",
      pictureUrl: "./assets/images/temporary/socialite-logo.png",
      description: "",
      url: environment.URLAdvertiser + "/login/"
    },
    {
      id: ProjectEnum.QrMenu.toString(),
      name: "Socialite QR menu",
      pictureUrl: "./assets/images/temporary/socialite-qr-menu-logo.png",
      description: "",
      url: ""
    },
    {
      id: ProjectEnum.TimelyWork.toString(),
      name: "Socialite Timely Work",
      pictureUrl: "./assets/images/temporary/socialite-logo.png",
      description: "",
      url: ""
    }];

    getAllProjectsDetails(){
      return AccessProjectsService.PRODUCT_LIST;
    }
    getProjectDetails(ids: any){
      return AccessProjectsService.PRODUCT_LIST.filter(product => ids.includes(product.id));
    }

}
