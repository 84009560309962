import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs";


@Injectable({
  providedIn: 'root'
})

export class HttpService {

  //#region Fields

  //#endregion

  //#region Constructor
  constructor(private _httpClient: HttpClient) {
  }
  //#endregion

  //#region Public Methods
  public httpPost(url: string, model?: any) {
    const authToken = localStorage.getItem("access_token") as string;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    if (authToken) {
      headers = headers.set('Authorization', `Bearer ${authToken}`)
    }

    return this._httpClient.post<any>(`${url}`, model, {
      headers
    }).pipe(map(response => {
      return response;
    }));
  }

  public httpGet(url: string) {
    const authToken = localStorage.getItem("access_token") as string;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    if (authToken) {
      headers = headers.set('Authorization', `Bearer ${authToken}`)
    }

    return this._httpClient.get<any>(url, {
      headers
    })
      .pipe(map(response => {
        return response;
      }));
  }
  public externalHttpGet(url: string) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this._httpClient.get<any>(url, {
      headers
    }).pipe(map(response => {
        return response;
      }));
  }
  //#endregion
}
