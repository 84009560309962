<div class="div-products-container">
  <div class="back-to-products"><a (click)="goBackToProduct()">
    <i class="pi pi-arrow-left me-2"></i><span>{{'PRODUCT.BACK' | translate}}</span></a>
  </div>
  <div style="width: 70vw; align-self: center;">
    <div class="products-header">
      <span class="title">{{'PRODUCT.UPGRADE_PLAN' | translate | uppercase}}</span>
    </div>
    <ng-container>
      <div class="w-100 special-offer-container" *ngIf="specialOffer">
        <app-special-offer [specialOffer]="specialOffer"></app-special-offer>
      </div>
      <div class="div-list-plans">
        <div *ngFor="let p of productDetails.plans" class="vertical-component-card" style="position: relative;">
            <ng-container *ngIf="p.userActivated">
              <img src="assets/icons/current-icon.svg" style="margin-left: -70px; position: absolute">
            </ng-container>
          <div class="component-list-card h-100 d-flex flex-column">
            <div class="text-center plan-text mb-2">
              <label class="lbl-name">{{p.name}}</label>
              <label class="lbl-price">${{p.defaultMonthlyPrice}}</label>
              <label class="lbl-price">${{p.defaultYearlyPrice}}</label>
              <div class="min-h-title">
                <label class="lbl-title  w-100">{{p.title}}</label>
                <label class="lbl-desc">{{p.description}}</label>
              </div>
              <label class="lbl-num">{{'PRODUCT.VENUES' | translate | titlecase}}</label>
              <label class="lbl-num-of-ven">{{p.minNumberOfVenues}} - {{p.maxNumberOfVenues}}</label>
              <div *ngFor="let country of p.countryNames">
                <label class="lbl-title w-100">{{country}}</label>
              </div>
            </div>
            <div class="mt-auto">
              <button class="create-btn buy-btn" (click)="openPaymentDialog(p)">{{'PRODUCT.BUY' |translate}}</button>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
