<div class="d-flex p-3 image-cropper-container">
  <div class="cropper-wrapper d-flex flex-row align-items-center justify-content-center">
    <div *ngIf="!showCropper" class="upload-img">
      <div (click)="openAddImage()" class="d-flex flex-row">
        <img src="assets/icons/add-image-plus-icon.svg" class="add-img-plus-icon">
      </div>
    </div>
    <input style="display: none;" type="text" #eventImagePath/>
    <input (change)="onSelectFile($event)" class="open-file" style="display: none;" type="file" id="openFile" #openFile
           name="openFile" accept="image/png, image/jpg, image/jpeg"/>
    <image-cropper class="cropper-container"
                   [imageChangedEvent]="imageChangedEvent"
                   [maintainAspectRatio]="true"
                   [aspectRatio]="200 / 130"
                   [transform]="transform"
                   [canvasRotation]="canvasRotation"
                   (imageCropped)="imageCropped($event)"
                   (imageLoaded)="imageLoaded()"
                   (cropperReady)="cropperReady()"
                   (loadImageFailed)="loadImageFailed()"
                   [style.display]="showCropper ? null : 'none'"
                   backgroundColor="white">
    </image-cropper>

  </div>
  <div class="d-flex justify-content-between preview-wrapper">
    <div class="container-img">
      <div class="inner-preview-container">
        <img class="img" [src]="imagePath" alt="img-preview" *ngIf="imageName">
        <div *ngIf="!imageName" class="d-flex flex-row preview-img-placeholder">
          <img src="assets/icons/add-image-placeholder-icon.svg" class="add-img-placeholder-icon">
        </div>
      </div>
      <p class="preview-label mt-3 mb-0 hide_mobile_wide">{{'INFO.PREVIEW' | translate}}</p>
    </div>
    <div class="cropper-btn-container mt-4">
      <div class="d-flex align-items-center cropper-btn-inner-container">
        <button class="btn_rotate_image w-100" type="button" (click)="rotateRight()">
          <img src="assets/icons/rotate-image-icon.svg">{{'INFO.ROTATE' | translate}}
        </button>
        <button class="btn_in_color w-100" type="button" (click)="save()">{{'SAVE' | translate}}</button>
        <button class="btn_without_color w-100" type="button" (click)="cancel()">{{'CANCEL' | translate}}</button>
      </div>
    </div>
  </div>
</div>

