import { HttpClient, HttpHeaders } from "@angular/common/http";
import { EventEmitter, Injectable } from "@angular/core";
import { JwtHelperService } from "@auth0/angular-jwt";
import { BehaviorSubject, map } from "rxjs";
import { environment } from "src/environments/environment";
import { LoginModel } from "../../models/login/login.model";
import { HttpService } from "../common/http-service";
import { SignupModel } from "../../models/signup/signup.model";
import { UserModel } from "../../models/user/user.model";
import { ResetPasswordModel } from "../../models/reset-password/reset-password.model";
import { ForgotPasswordModel } from "../../models/forgot-password/forgot-password.model";
import { ChangePasswordModel } from "../../models/change-password/change-password.model";
import { EditProfileModel } from "../../models/edit-profile/edit-profile.model";
import { AddCompanyInfoModel } from "../../models/company/add-company-info.model";
import { UpdateProfileModel } from "../../models/edit-profile/update-profile-model";
import { RefreshTokenModel } from "../../models/refresh-token/refresh-token.model";
import { TermsAndConditionModel } from "../../models/terms-and-conditions/terms-and-condtions.model";
import { SocialLoginModel } from "../../models/login/social-login.model";
import { SocialRegisterModel } from "../../models/signup/social-register.model";
import { VerifyEmailModel } from "../../models/verify-email/verify-email.model";
import { EditCompanyInfoModel } from "../../models/company/edit-company-info";

const AUTH_API = environment.apiURL + '/api/business/User/';
const ADV_AUTH_API = environment.apiURL + '/api/adv/User/';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  //#region Fields
  _isLoggedIn$ = new BehaviorSubject<boolean>(false);
  _isSuperAdmin$ = new BehaviorSubject<boolean>(false);
  private readonly access_token = 'access_token';
  public isLoggedIn$ = this._isLoggedIn$.asObservable();

  get token(): any {
    return localStorage.getItem(this.access_token);
  }
  //#endregion

  //#region Constructor
  constructor(private _httpService: HttpService, private _httpClient: HttpClient, private _jwtHelperService: JwtHelperService) {
    if (this.token && !_jwtHelperService.isTokenExpired(this.token)) {
      this._isLoggedIn$.next(!!this.token);
      if (this.token != null) {
        const tokenDecoded = this._jwtHelperService.decodeToken(this.token);
        this.loginUser.next(new UserModel(tokenDecoded.id, tokenDecoded.rlnm?.split(',').map((x: string) => +x)))
      }
      else {
        this.loginUser.next(null);
      }
    }
  }

  //#region Public Methods
  login(model: LoginModel) {
    let url = AUTH_API + "authenticate";
    return this._httpService.httpPost(url, model)
      .pipe(map(response => {
        return response;
      }));
  }

  authenticateSocialLogin(socialLoginModel: SocialLoginModel) {
    let url = AUTH_API + "authenticatesociallogin";
    return this._httpService.httpPost(url, socialLoginModel)
      .pipe(map(response => {
        return response;
      }));
  }

  refreshToken(refreshToken: RefreshTokenModel){
    let url = AUTH_API + "refresh-token";
    return this._httpService.httpPost(url, refreshToken)
      .pipe(map(response => {
        return response;
      }));

  }

  confirmPassword(model: ResetPasswordModel) {
    let url = AUTH_API + "confirmpassword";
    return this._httpService.httpPost(url, model)
      .pipe(map(response => {
        return response;
      }));
  }

  forgotPassword(model: ForgotPasswordModel) {
    let url = AUTH_API + "forgotpassword";
    return this._httpService.httpPost(url, model)
      .pipe(map(response => {
        return response;
      }));
  }

  signup(model: SignupModel) {
    let url = AUTH_API + "register";
    return this._httpService.httpPost(url, model)
      .pipe(map(response => {
        return response;
      }));
  }

  registerWithSocialLogin(socialRegisterModel: SocialRegisterModel) {
    let url = AUTH_API + "registersociallogin";
    return this._httpService.httpPost(url, socialRegisterModel)
      .pipe(map(response => {
        return response;
      }));
  }

  logout() {
    let url = AUTH_API + "signout";
    return this._httpService.httpPost(url)
      .pipe(map(response => {
        return response;
      }));
  }

  getAllCountryNames() {
    let url = AUTH_API + "getallcountrynames";
    return this._httpService.httpGet(url)
      .pipe(map(response => {
        return response;
      }));
  }

  getProfile() {
    let url = AUTH_API + 'getuserprofile';

    return this._httpService.httpGet(url)
      .pipe(map(response => {
        return response;
      }));
  }

  updateProfile(model: UpdateProfileModel) {
    let url = AUTH_API + 'updateuserprofile';

    const formData: FormData = new FormData();
    formData.append('firstName',model.firstName as string);
    formData.append('lastName',model.lastName as string);
    formData.append('phone',model.phone as string);
    formData.append('dateOfBirth',model.dateOfBirth as string);
    return this._httpClient.post(url, formData, {
      headers: new HttpHeaders().set('Authorization', `Bearer ${localStorage.getItem("access_token") as string}`)
    })
      .pipe(map(response => {
        return response;
      }));
  }

  uploadProfilePicture(file: any) {
    let url = AUTH_API + 'uploadprofilepicture';

    const formData: FormData = new FormData();
    formData.append('File', file as string);
    return this._httpClient
      .post<any>(url, formData, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${localStorage.getItem("access_token") as string}`)
      })
      .pipe(map(response => {
        return response;
      }));
  }

  deleteProfile(){
    let url = AUTH_API + "deleteaccount";
    return this._httpService.httpPost(url)
    .pipe(map(response => {
      return response;
    }));
  }

  changePasswordManually(model: ChangePasswordModel) {
    let url = AUTH_API + "changepassword";
    return this._httpService.httpPost(url, model)
      .pipe(map(response => {
        return response;
      }));
  }

  getAllBusinessTypes() {
    let url = AUTH_API + "getallbusinesstypes";
    return this._httpService.httpGet(url)
      .pipe(map(response => {
        return response;
      }));
  }

  addCompanyInfo(model: AddCompanyInfoModel) {
    let url = AUTH_API + "addcompany";
    return this._httpService.httpPost(url, model)
      .pipe(map(response => {
        return response;
      }));
  }
  getCompanyDetails() {
    let url = AUTH_API + "getcompanydetails";
    return this._httpService.httpGet(url)
      .pipe(map(response => {
        return response;
      }));
  }
  updateCompany(model: EditCompanyInfoModel) {
    let url = AUTH_API + "updatecompany";
    return this._httpService.httpPost(url, model)
      .pipe(map(response => {
        return response;
      }));
  }
  getTermsAndConditions(model: TermsAndConditionModel) {
    var url = ADV_AUTH_API + "gettermsandconditions?LanguageId=" + model.languageId + "&CountryCode=" + model.countryId;
    return this._httpService.httpGet(url)
      .pipe(map(resposne => {
        return resposne;
      }));
  }

  verifyEmail(model: VerifyEmailModel){
    var url = AUTH_API + "verifyemail?verificationToken=" + model.verificationToken + "&languageId=" + model.languageId;
    return this._httpService.httpGet(url)
      .pipe(map(resposne => {
        return resposne;
      }));
  }
  //#endregion

  //# Subject EventEmitter
  loginUser = new BehaviorSubject<UserModel | null>(null);
  showHideMenu = new EventEmitter<boolean>()
  //#endregion
}
