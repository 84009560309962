import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Subject, takeUntil } from 'rxjs';
import { AuthenticationService } from './share-modul/services/authentication/authentication.service';
import { TranslationHelperService } from './share-modul/services/translation-helper/translation-helper.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UserModel } from './share-modul/models/user/user.model';

const _jwtHelper = new JwtHelperService();

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [MessageService]
})

export class AppComponent implements OnInit {

  public unsubscribe: Subject<void> = new Subject<void>();
  private roles: string[] = [];
  public isLoggedin: boolean = false;
  public isSuperAdmin: boolean = false;
  private user: UserModel | null = null;
  constructor(
    public _router: Router,
    private translationHelperService: TranslationHelperService,
    public authenticationService: AuthenticationService
  ) {
    this.authenticationService.loginUser.subscribe(it => {
      this.user = it;
    });
  }

  ngOnInit(): void {
    this.initTranslate();
    this.authenticationService.loginUser.pipe(takeUntil(this.unsubscribe)).subscribe(response => {
      this.isLoggedin = !!response;
    })
    this.authenticationService.isLoggedIn$.subscribe((result) =>
      this.isLoggedin = result
    );

    if (this.user !== null) {
      const token = localStorage.getItem('access_token');
      const decodedToken = _jwtHelper.decodeToken(token as string);
      const cmid = decodedToken.cmid;
      const isAdvertiserPaid = decodedToken.iap != undefined ? JSON.parse(decodedToken.iap.toLowerCase()) : false;
      const isResyPaid = decodedToken.irp != undefined ? JSON.parse(decodedToken.irp.toLowerCase()) : false
      const IsPOSPaid = decodedToken.ipp != undefined ? JSON.parse(decodedToken.ipp.toLowerCase()) : false
      if (cmid) {
        if (!isAdvertiserPaid || !isResyPaid || !IsPOSPaid) {
          this._router.navigate(['products']);
        }
      }
      else {
        this._router.navigate(['company-info']);
      }
    }
  }

  private initTranslate(): void {
    this.translationHelperService.initializeTranslate();
  }

  public isCompanyInfoRelated(): boolean {
    return this._router.url.indexOf('/company-info') >= 0;
  }

  public isProductsRelated(): boolean {
    return this._router.url.indexOf('/products') >= 0;
  }
}
