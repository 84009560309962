<div class="special-offer-div">
    <div class="background-image">
        <img src="assets/icons/logo-transparent-special-offer.svg">
    </div>
    <div class="background-image">
        <img src="assets/icons/special-offer-icon-2.svg" style="margin-left: 320px;">
    </div>
    <div class="text-overlay d-flex justify-content-between flex-row">
        <div class="w-50">
            <div class="offer-name">{{'TRY socialite for free' | uppercase}}</div>
            <button class="create-btn float-left mt-4" (click)="openTrialDialog()">{{'PRODUCT.TRY_NOW' | translate | uppercase}}</button>
        </div>
        <div class="w-50 offer">
            <div class="question">{{'PRODUCT.WHAT_YOU_GET' | translate}}</div>
            <div class="project-name "style="display: contents">
                <label>Socialite POS </label>
                <label>Socialite Resy</label>
                <label>Socialite Advertiser </label>
            </div>
            <div class="number-venues"> 1 month </div>
            <div class="number-venues">1 venue
            </div>
        </div>
    </div>
</div>