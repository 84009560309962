import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "src/app/guard/guard.component";
import { PageNotFoundComponent } from "src/app/share-modul/components/page-not-found/page-not-found.component";
import { ManageVenuesComponent } from "../../socialite-manager-panel/components/manage-venues/manage-venues.component";
import { ProductsComponent } from "../../socialite-manager-panel/components/products/products.component";
import {
  ProductDetailsComponent
} from "../../socialite-manager-panel/components/products/product-details/product-details.component";
import {
  UpgradePlanComponent
} from "../../socialite-manager-panel/components/products/upgrade-plan/upgrade-plan.component";
import {
  InstagramRegisterSupportComponent
} from "../../socialite-manager-panel/components/manage-venues/instagram-register-support/instagram-register-support.component";
import {
  InstagramPostsComponent
} from "../../socialite-manager-panel/components/manage-venues/instagram-posts/instagram-posts.component";

const routes: Routes = [
  { path: 'products', component: ProductsComponent, canActivate: [AuthGuard] },
  { path: 'products/:id/details', component: ProductDetailsComponent, canActivate: [AuthGuard] },
  { path: 'products/:id/upgrade-plan', component: UpgradePlanComponent, canActivate: [AuthGuard] },
  { path: 'manage-venues', component: ManageVenuesComponent, canActivate: [AuthGuard] },
  { path: 'instagram-support', component: InstagramRegisterSupportComponent, canActivate: [AuthGuard ] },
  { path: 'venues/:venue-id/instagram-posts', component: InstagramPostsComponent, canActivate: [AuthGuard] },
  { path: 'page-not-found', component: PageNotFoundComponent, canActivate: [AuthGuard] },
  { path: '**', redirectTo: '/page-not-found' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class SocialiteManagerRoutingModule { }
