<div class="bg_login d-flex">
  <div class="w-100 company_info_div">
    <div class="w-100 logo_div">
      <div class="logo"></div>
    </div>
    <div class="input_fields row">
      <h3 class="company_info_info d-flex justify-content-center w-100">{{'INFO.COMPANY_INFO' | translate}}</h3>
      <div class="col-6">
        <label class="lbl_field_small col-12">
          <div *ngIf="errorModel!=null && errorModel['Name']!=null" class="invalid_input_text">
            {{errorModel['Name'] | translate}}
          </div>
        </label>
        <input type="text" class="input_field w-100" [(ngModel)]="addCompanyInfoModel.companyName"
               placeholder="{{'COMPANY_INFO.NAME' |translate}}">
      </div>
      <div class="col-6">
        <label class="lbl_field_small col-12">
          <div *ngIf="errorModel!=null && errorModel['Address']!=null" class="invalid_input_text">
            {{errorModel['Address'] | translate}}
          </div>
        </label>
        <input type="text" class="input_field w-100" [(ngModel)]="addCompanyInfoModel.address"
               placeholder="{{'COMPANY_INFO.ADDRESS' |translate}}">
      </div>
      <div class="col-6">
        <label class="lbl_field_small col-12">
          <div *ngIf="(errorModel!=null && errorModel['Email']!=null) && (!email.touched || !email.errors?.pattern)"
               class="invalid_input_text">
            {{errorModel['Email'] | translate}}
          </div>
          <div *ngIf="email.touched && email.errors?.pattern" class="invalid_input_text">
            {{'INFO.INVALID_EMAIL' | translate}}
          </div>
        </label>
        <input type="email" class="input_field col-12" [(ngModel)]="addCompanyInfoModel.email"
               placeholder="{{'COMPANY_INFO.EMAIL'|translate}}"
               #email=ngModel required pattern="^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$">
      </div>
      <div class="col-6">
        <label class="lbl_field_small col-12">
          <div *ngIf="errorModel!=null && errorModel['Phone']!=null" class="invalid_input_text">
            {{errorModel['Phone'] | translate}}
          </div>
        </label>
        <input type="number" class="input_field w-100 custom_number_input" [(ngModel)]="addCompanyInfoModel.phone"
               placeholder="{{'COMPANY_INFO.PHONE'|translate}}">
      </div>
      <div class="col-6">
        <label class="lbl_field_small col-12">
          <div *ngIf="errorModel!=null && errorModel['Country']!=null" class="invalid_input_text">
            {{errorModel['Country'] | translate}}
          </div>
        </label>
        <p-dropdown class="src_dropdown country_dropdown m-0" [autoDisplayFirst]=false [options]="countries"
                    placeholder="{{'COMPANY_INFO.COUNTRY'|translate}}" [styleClass]="'m-0'"
                    [(ngModel)]="addCompanyInfoModel.countryId" optionLabel="countryName" optionValue="id"></p-dropdown>
      </div>
      <div class="col-6">
        <label class="lbl_field_small col-12">
          <div *ngIf="errorModel!=null && errorModel['BusinessType']!=null" class="invalid_input_text">
            {{errorModel['BusinessType'] | translate}}
          </div>
        </label>
        <p-multiSelect class="men_dropdown" [options]="businessTypes" [styleClass]="'business_type mb-4'"
                       [(ngModel)]="addCompanyInfoModel.businessTypesCompanies" optionLabel="name" optionValue="id"
                       [showHeader]="false"
                       [maxSelectedLabels]="1" placeholder="{{'COMPANY_INFO.BUSINESS_TYPE'|translate}}"
                       selectedItemsLabel="{0} items selected"></p-multiSelect>
      </div>
      <div class="col-6 mt-2">
        <button class="btn_login_manager w-100" (click)="addCompanyInfo(addCompanyInfoModel)"
                [disabled]="spinner || email.errors?.pattern">{{'COMPANY_INFO.CONTINUE' | translate}}</button>
        <div class="small_spinner_wrapper_no_auth mb-3 mt-2" *ngIf="spinner">
          <p-progressSpinner class="w-100 text-center spinner_small"></p-progressSpinner>
        </div>
      </div>
    </div>
  </div>
</div>
