import { Attribute, Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { Subject, Subscription, takeUntil } from "rxjs";
import { Message, MessageService } from "primeng/api";
import { AuthenticationService } from "../../services/authentication/authentication.service";
import { SignupModel } from "../../models/signup/signup.model";
import { NgbTooltip } from "@ng-bootstrap/ng-bootstrap";
import { DropdownHelperModel } from 'src/app/share-modul/models/helpers/dropdown-helper.model';
import { Router } from "@angular/router";
import { UserModel } from "../../models/user/user.model";
import { JwtHelperService } from "@auth0/angular-jwt";
import { DialogService } from 'primeng/dynamicdialog';
import { TermsAndConditionsDialogComponent } from '../terms-and-conditions-dialog/terms-and-conditions-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { SocialAuthenticationHelperService } from '../../services/social-authentication-helper/social-authentication-helper.service';
import { SocialRegisterModel } from '../../models/signup/social-register.model';
import { SocialUserModel } from '../../models/user/social-user.model';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit, OnDestroy {

  //#region Fields
  public unsubscribe: Subject<void> = new Subject<void>()
  public signupModel: SignupModel = new SignupModel();
  public errorModel: { [k: string]: any } = {};
  public errorMsg: Message[];
  public requiredFieldErrorMessage = "INFO.REQUIRED_FIELD";
  public passwordMatchErrorMessage = "INFO.PASSWORD_MATCH";
  public invalidPasswordErrorMessage = "INFO.INVALID_PASSWORD";
  public user: UserModel;
  public subscription: Subscription;
  public spinner: boolean = false;
  //#endregion

  //#region Constructor
  @HostBinding('attr.autocomplete') auto: any;
  constructor(
    @Attribute('autocomplete') autocomplete: string,
    public authenticationService: AuthenticationService,
    public router: Router,
    private jwtHelperService: JwtHelperService,
    public socialAuthenticationHelperService: SocialAuthenticationHelperService,
    public dialogService: DialogService,
    public translate: TranslateService,
    public messageService: MessageService) {
    this.auto = autocomplete || 'off'
  }

  ngOnInit(): void {
    this.subscription = this.authenticationService.loginUser.subscribe(user => {
      this.user = user!;
    })
  }
  //#endregion

  //#region Public Methods
  public signup(model: SignupModel): void {

    model.languageId = this.languageIdFromLocalStorage();

    this.errorModel = {};
    this.errorMsg = [];

    if (!model.firstName || model.firstName == "") {
      this.errorModel['FirstName'] = this.requiredFieldErrorMessage;
    }
    if (!model.lastName || model.lastName == "") {
      this.errorModel['LastName'] = this.requiredFieldErrorMessage;
    }
    if (!model.password || model.password == "") {
      this.errorModel['Password'] = this.requiredFieldErrorMessage;
    } else if (!this.isPasswordValid()) {
      this.errorModel['Password'] = this.invalidPasswordErrorMessage;
    }
    if (!model.confirmPassword || model.confirmPassword == "") {
      this.errorModel['ConfirmPassword'] = this.requiredFieldErrorMessage;
    }
    if (model.confirmPassword && model.password && model.confirmPassword !== model.password) {
      this.errorModel['ConfirmPassword'] = this.passwordMatchErrorMessage;
    }
    if (!model.email || model.email == "") {
      this.errorModel['Email'] = this.requiredFieldErrorMessage;
    }
    if (!model.phone || model.phone == "") {
      this.errorModel['Phone'] = this.requiredFieldErrorMessage;
    }
    if (!model.termsAndConditionsAccepted) {
      this.errorModel['TermsAndConditions'] = this.requiredFieldErrorMessage;
    }

    if (Object.keys(this.errorModel).length === 0) {
      model.phone = model.phone.toString();
      this.spinner = true;

      this.authenticationService.signup(model)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe({
          next: (response) => {
            this.spinner = false;
            localStorage.setItem('access_token', response.jwtToken);
            localStorage.setItem('refresh_token', response.refreshToken);
            const decodedToken = this.jwtHelperService.decodeToken(response.jwtToken);
            const loggedInUser = new UserModel(decodedToken.id, decodedToken.rlnm.split(',').map((x: string) => +x));
            this.authenticationService.loginUser.next(loggedInUser);
            this.router.navigateByUrl('company-info');
          },
          error: errorResponse => {
            this.spinner = false;

            if (errorResponse.error.errors && errorResponse.error.errors.FirstName) {
              errorResponse.error.errors.FirstName.forEach((error: string) => {
                this.errorModel['FirstName'] = error;
              });
            }
            if (errorResponse.error.errors && errorResponse.error.errors.LastName) {
              errorResponse.error.errors.LastName.forEach((error: string) => {
                this.errorModel['LastName'] = error;
              });
            }
            if (errorResponse.error.errors && errorResponse.error.errors.Password) {
              errorResponse.error.errors.Password.forEach((error: string) => {
                this.errorModel['Password'] = error;
              });
            }
            if (errorResponse.error.errors && errorResponse.error.errors.ConfirmPassword) {
              errorResponse.error.errors.ConfirmPassword.forEach((error: string) => {
                this.errorModel['ConfirmPassword'] = error;
              });
            }
            if (errorResponse.error.errors && errorResponse.error.errors.Email) {
              errorResponse.error.errors.Email.forEach((error: string) => {
                this.errorModel['Email'] = error;
              });
            }
            if (errorResponse.error.errors && errorResponse.error.errors.Phone) {
              errorResponse.error.errors.Phone.forEach((error: string) => {
                this.errorModel['Phone'] = error;
              });
            }
            if (errorResponse.error.errors && errorResponse.error.errors.TermsAndConditions) {
              errorResponse.error.errors.TermsAndConditions.forEach((error: string) => {
                this.errorModel['TermsAndConditions'] = error;
              });
            } else if (errorResponse.error && errorResponse.error.Message) {
              this.messageService.add({ severity: 'error', summary: errorResponse.error.Message, life: 2000 });
            }
          }
        });
    }
  }

  public hasUppercaseLetter(): boolean {
    return this.signupModel.password !== undefined && (/[A-Z]/).test(this.signupModel.password);
  }

  public hasLowercaseLetter(): boolean {
    return this.signupModel.password !== undefined && (/[a-z]/).test(this.signupModel.password);
  }

  public hasSpecialCharacter(): boolean {
    return this.signupModel.password !== undefined && (/[!$%@#£€*?&.,:]/).test(this.signupModel.password);
  }

  public has8Characters(): boolean {
    return this.signupModel.password !== undefined && (/.{8,}/).test(this.signupModel.password);
  }

  public isPasswordValid(): boolean {
    return this.signupModel.password !== undefined && (/^(?=.*[A-Z])(?=.*[a-z])(?=.*[!$%@#£€*?&.,:])(?!.*\s).{8,}$/).test(this.signupModel.password);
  }

  public openTooltip(tipContent: NgbTooltip): void {
    if (!tipContent.isOpen()) {
      tipContent.open();
    }
  }

  public closeTooltip(tipContent: NgbTooltip): void {
    tipContent.close();
  }

  public languageIdFromLocalStorage(): number {
    const langIdFromStorage = localStorage.getItem('languageId');

    if (langIdFromStorage) {
      return parseInt(langIdFromStorage);
    } else {
      return 1;
    }
  }

  public openTermsAndConditionDialog(): void {
    const ref = this.dialogService.open(TermsAndConditionsDialogComponent, {
      header: this.translate.instant('INFO.TERMS_AND_CONDITIONS'),
      styleClass: 'dialog_long, ml_200, mb_15 dialog-cyan-header-text',
      width: '100vh'
    })
    ref.onClose.subscribe((item: any) => {
      // if (item) {
      // }
    });
  }

  public signUpWithFacebook(): void {
    this.socialAuthenticationHelperService.facebookAuth().then((result) => {
      this.spinner = true;
      const socialRegisterCredentials = new SocialRegisterModel();
      //@ts-ignore
      socialRegisterCredentials.token = result.user._delegate.accessToken;
      let obj = result.additionalUserInfo?.profile?.valueOf() as SocialUserModel;
      socialRegisterCredentials.firstName = obj.first_name;
      socialRegisterCredentials.lastName = obj.last_name;
      socialRegisterCredentials.email = obj.email;
      // @ts-ignore
      socialRegisterCredentials.pictureUrl = obj.picture.data.url;
      socialRegisterCredentials.phone = result.user?.phoneNumber;
      socialRegisterCredentials.languageId = this.languageIdFromLocalStorage();
      this.authenticationService.registerWithSocialLogin(socialRegisterCredentials)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: (response) => {
          this.spinner = false;
          localStorage.setItem('access_token', response.jwtToken);
          localStorage.setItem('refresh_token', response.refreshToken);
          const decodedToken = this.jwtHelperService.decodeToken(response.jwtToken);
          const loggedInUser = new UserModel(decodedToken.id, decodedToken.rlnm.split(',').map((x: string) => +x));
          this.authenticationService.loginUser.next(loggedInUser);
          this.router.navigateByUrl('company-info');
        },
        error: (errorResponse) => {
          this.spinner = false;
          if (errorResponse.error && errorResponse.error.Message) {
            this.messageService.add({ severity: 'error', summary: errorResponse.error.Message ,life: 2000  });
          }
          else if (errorResponse.error && !errorResponse.error.errors) {
            this.messageService.add({ severity: 'error', summary: errorResponse.error.title, life: 2000 });
          }
        }
      })
    },(error:HttpErrorResponse)=>{
      this.spinner = false;
      if(!error.message.includes('popup has been closed by the user')){
        this.messageService.add({ severity: 'warn', summary: error.message, life: 4000 });
      }
    });
  }

  public signUpWithApple(): void {

  }

  public ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  //#endregion
}
