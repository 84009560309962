import { Component, ElementRef, OnInit } from '@angular/core';
import { Subject, take, takeUntil } from "rxjs";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { AuthenticationService } from 'src/app/share-modul/services/authentication/authentication.service';
import { MessageService } from 'primeng/api';
import { UserModel } from "../../models/user/user.model";
import { CompressImageService } from "../../services/common/compress-image.service";
import {
  ChangePasswordDialogComponent
} from "../change-password-dialog/change-password-dialog.component";
import { EditProfileModel } from "../../models/edit-profile/edit-profile.model";
import { UpdateProfileModel } from '../../models/edit-profile/update-profile-model';
import { DeleteAccountDialogComponent } from '../deactive-account-dialog/deactive-account-dialog.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss']
})
export class EditProfileComponent implements OnInit {

  //#region Fields
  public unsubscribe: Subject<void> = new Subject<void>();
  public pictureIsWrongFormat: boolean = false;
  public editProfileModel: EditProfileModel = new EditProfileModel();
  public filePicture: any;
  public pictureFormat: Array<string>;
  public imagePath: string = './assets/images/logo/profile-picture-placeholder.png';
  public errorModel: { [k: string]: any } = {};
  public spinner: boolean = false;
  public user: UserModel | null;
  public requiredFieldErrorMessage = "INFO.REQUIRED_FIELD";
  public imageChanged: boolean = false;
  //#endregion

  //#region Constructor
  constructor(private openFile: ElementRef,
    private ref: DynamicDialogRef,
    private authenticationService: AuthenticationService,
    private dialogService: DialogService,
    private messageService: MessageService,
    private compressImage: CompressImageService,
    public _router: Router) {

    this.pictureFormat = ['image/png', 'image/jpg', 'image/jpeg'];
  }

  public ngOnInit(): void {
    this.authenticationService.loginUser.pipe(takeUntil(this.unsubscribe)).subscribe(user => {
      this.user = user;
    });
    this.getUserProfile();
  }
  //#endregion

  //#region Public Methods
  public getUserProfile(): void {
    this.spinner = true;
    this.authenticationService.getProfile()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: (response) => {
          this.spinner = false;
          this.editProfileModel = response.user;
          this.editProfileModel.email = response.user.name;
        },
        error: _ => {
          this.spinner = false;
          this.messageService.add({ severity: 'error', summary: "System error", life: 2000 });
        }
      });
  }

  public updateProfile(model: EditProfileModel): void {
    let updateProfileModel = new UpdateProfileModel();
    updateProfileModel.firstName = model.firstName;
    updateProfileModel.lastName = model.lastName;
    updateProfileModel.phone = model.phone;
    if (this.filePicture != undefined) {
      this.spinner = true;
      this.authenticationService.uploadProfilePicture(this.filePicture).pipe(takeUntil(this.unsubscribe))
        .subscribe({
          next: _ => {
            this.imageChanged = true;
            this.authenticationService.updateProfile(updateProfileModel)
              .pipe(takeUntil(this.unsubscribe))
              .subscribe({
                next: _ => {
                  this.spinner = false;
                  this.ref.close(true);
                },
                error: _ => {
                  this.spinner = false;
                  this.messageService.add({ severity: 'error', summary: "System error", life: 2000 });
                }
              });
          },
          error: _ => {
            this.spinner = false;
            this.messageService.add({ severity: 'error', summary: "System error", life: 2000 });
          }
        });
    } else {
      this.spinner = true;
      this.authenticationService.updateProfile(updateProfileModel)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe({
          next: _ => {
            this.spinner = false;
            this.ref.close(true);
          },
          error: _ => {
            this.spinner = false;
            this.messageService.add({ severity: 'error', summary: "System error", life: 2000 });
          }
        });
    }
  }

  public cancel(): void {
    this.ref.close(this.imageChanged);
  }

  public openAddImage(): void {
    let element = this.openFile.nativeElement.querySelector(".open-file");
    element.click();
  }

  public onFileSelected(event: any): void {
    this.pictureIsWrongFormat = false;
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      if (!this.pictureFormat.includes(event.target.files[0].type)) {
        this.pictureIsWrongFormat = true;
        return;
      }
      let image: File = event.target.files[0]
      reader.readAsDataURL(image);// read file as data url
      this.compressImage.compress(image)
        .pipe(take(1))
        .subscribe(compressedImage => {
          this.filePicture = compressedImage;
          // this.changeProfilePicture();
        })
      reader.onload = (item) => { // called once readAsDataURL is completed
        if (item.target?.result) {
          this.editProfileModel.profilePictureUrl = item.target?.result as string;
        }
      }
    }
  }

  // public changeProfilePicture(): void {
  //   this.spinner = true;
  //   this.authenticationService.uploadProfilePicture(this.filePicture).pipe(takeUntil(this.unsubscribe))
  //     .subscribe({
  //       next: _ => {
  //         this.imageChanged = true;
  //         this.spinner = false;
  //       },
  //       error: _ => {
  //         this.spinner = false;
  //         this.messageService.add({ severity: 'error', summary: "System error", life: 2000 });
  //       }
  //     });
  // }

  public changePassword(): void {
    this.dialogService.open(ChangePasswordDialogComponent, {
      closable: false,
      styleClass: 'small-header-dialog',
      width: '400px'
    });
  }

  public deactiveAccount(): void {
    const ref = this.dialogService.open(DeleteAccountDialogComponent, {
      closable: false,
      styleClass: 'dialog_long small-header-dialog',
    });
    ref.onClose.subscribe((item: any) => {
      if (item) {
        this.ref.close("Delete");
      }
    });
  }

  public deleteProfile(): void {

  }
  //#endregion
}
