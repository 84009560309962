<div class="bg_login d-flex">
  <div class="w-100 h-100 login_div flex-column">
    <div class="w-100 logo_div">
      <div class="logo"></div>
    </div>
    <div class="login_form">
      <h3 class="sign_up d-flex justify-content-between mb-5">{{'INFO.FORGOT_PASSWORD_QUESTION' | translate}}</h3>
      <p class="text-center">{{'INFO.FORGOT_PASSWORD_MESSAGE' | translate}}</p>
      <div class="login_field">
        <label class="lbl_field">
          <i *ngIf="(errorModel!=null && errorModel['Email']!=null) && !email.errors?.pattern"
             class="pi pi-exclamation-circle float-end me-1 error_color pt-1"
             pTooltip="{{errorModel['Email']|translate}}" tooltipPosition="top"></i>
          <i *ngIf="email.touched && email.errors?.pattern"
             class="pi pi-exclamation-circle float-end me-1 error_color pt-1"
             pTooltip="{{'INFO.INVALID_EMAIL'|translate}}" tooltipPosition="top"></i>
        </label>
        <input type="email" class="input_field" [(ngModel)]="forgotPasswordModel.email" #email=ngModel
               required pattern="^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$" placeholder="{{'SIGN_UP.EMAIL'|translate}}">
        <button class="btn_login_manager mt-3"
                [disabled]="email.errors?.pattern || spinner"
                (click)="sendForgotPasswordRequest(forgotPasswordModel)">{{'INFO.SEND_MAIL'|translate}}</button>
        <a type="button" routerLink="/login" class="signup_link text-center mt-2">{{'INFO.SIGN_IN' | translate }}</a>
        <div class="small_spinner_wrapper" *ngIf="spinner">
          <p-progressSpinner class="w-100 text-center spinner_small"></p-progressSpinner>
        </div>
      </div>
    </div>
  </div>
</div>
