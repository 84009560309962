import { Component, OnInit } from '@angular/core';
import { VenueModel } from "../../models/venues/venue.model";
import { Subject, takeUntil } from "rxjs";
import { MenuItem, MessageService } from "primeng/api";
import { DialogService } from "primeng/dynamicdialog";
import { ManageVenuesService } from "../../services/venues/venues.service";
import { TranslateService } from "@ngx-translate/core";
import { AddOrEditVenuesComponent } from "./add-or-edit-venues/add-or-edit-venues.component";
import { DeleteVenueComponent } from "./delete-venue/delete-venue.component";
import { AddOrEditVenuesStepTwoComponent } from "./add-or-edit-venues-step-two/add-or-edit-venues-step-two.component";
import { SearchVenueHelperModel } from '../../models/venues/search-venue-helper.model';
import { CompanyService } from '../../services/company/company.service';
import { Router} from "@angular/router";

@Component({
  selector: 'app-manage-venues',
  templateUrl: './manage-venues.component.html',
  styleUrls: ['./manage-venues.component.scss']
})
export class ManageVenuesComponent implements OnInit {

  //#region Fields
  public listAllVenues: Array<VenueModel> = new Array<VenueModel>();
  public searchVenueModel: SearchVenueHelperModel = new SearchVenueHelperModel();
  public unsubscribe: Subject<void> = new Subject<void>();
  public imagePath: string = './assets/images/logo/venuelogo.png'
  public items: { [name: string]: MenuItem[] } = {};
  public spinner: boolean;
  public totalPages: number;
  public totalRecords: number;
  public companyId: string;
  //#endregion

  //#region Constructor
  constructor(
    public _router: Router,
    public dialogService: DialogService,
    public venueService: ManageVenuesService,
    public companyService: CompanyService,
    private messageService: MessageService,
    public translate: TranslateService
  ) {
    this.translate.stream('HEADER.APP_LANGUAGE').subscribe( () => {
      this.addFunctionToAllVenues();
    });
  }


  ngOnInit():void {
    this.getCompanyDetails();
    this.fetchAllVenueAndCheckValidityOfInstagramTokens(this.searchVenueModel);
  }
  //#endregion

  //#region Public Methods
  public getCompanyDetails(): void {
    // this.spinner = true;
    this.companyService.getCompanyDetials()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: (response) => {
          // this.spinner = false;
          this.companyId = response.id;
        },
        error: (errorResponse) => {
          // this.spinner = false;
          this.messageService.add({ severity: 'error', summary: "System error", life: 2000 });
        }
      })
  }
  fetchAllVenueAndCheckValidityOfInstagramTokens(searchModel: SearchVenueHelperModel){
    this.spinner = true;
    this.venueService.listvenues(searchModel)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: (response) => {
          response.data.forEach((v: VenueModel) => {
            if(v.instagramToken) {
              this.venueService.checkValidityAccessToken(v.instagramToken).subscribe({
                error: error => {
                  this.venueService.disconnectVenueFromInstagram(v.id).subscribe();
                }
              });
            }
          });
        }, complete: () => {
          this.getAllVenues(this.searchVenueModel);
        }
      });
  }
  public getAllVenues(searchModel: SearchVenueHelperModel): void {
    this.spinner = true;
    this.venueService.listvenues(searchModel)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: (response) => {
          this.spinner = false;
          this.totalPages = response.totalPages;
          this.totalRecords = response.totalRecords;
          if(this.searchVenueModel.pageNumber > 1) {
            this.listAllVenues = this.listAllVenues.concat(response.data);
          } else {
            this.listAllVenues = response.data;
          }
          this.addFunctionToAllVenues();
        },
        error: errorResponse => {
          this.spinner= false;
          this.messageService.add({ severity: 'error', summary: "System error", life: 2000 });
        }
      });
  };

  public loadMoreVenues(): void {
    if (!(this.searchVenueModel.pageNumber >= this.totalPages)) {
      this.searchVenueModel.pageNumber = this.searchVenueModel.pageNumber + 1;
      this.fetchAllVenueAndCheckValidityOfInstagramTokens(this.searchVenueModel);
    }
  }

  public createVenue(): void {
    const ref = this.dialogService.open(AddOrEditVenuesComponent, {
      header: this.translate.instant('VENUE.CREATE_VENUE'),
      width: '650px'
    });
    ref.onClose.subscribe((item: any) => {
      if (item) {
        
        const ref2 = this.dialogService.open(AddOrEditVenuesStepTwoComponent, {
          data: {
            venueId: item.id,
            companyId: this.companyId
          },
          header: 'Step 2',
          width: '360px',
          closable: false
        });
        ref2.onClose.subscribe((item: any) => {
          if (item){
          
            if (item.error){
              
              if (item.error.StatusCode == -306){
                this.messageService.add({severity: 'error', summary: item.error.Message, life: 2000});
              }
            }
            else {
              this.getAllVenues(this.searchVenueModel);
              this.messageService.add({severity:'success', summary: this.translate.instant('VENUE.VENUE_ADDED'), life:3000});
            }
          }
        });
      }
    });
  }

  public editVenue(venue: VenueModel): void {
    const ref = this.dialogService.open(AddOrEditVenuesComponent, {
      data: {
        id: venue.id,
        companyId: this.companyId
      },
      header: this.translate.instant('VENUE.EDIT_VENUE'),
      width: '650px'
    });
    ref.onClose.subscribe((item: any) => {
        this.getAllVenues(this.searchVenueModel)
        if(item) {
          this.messageService.add({
            severity: 'success',
            summary: this.translate.instant('VENUE.VENUE_EDITED'),
            life: 3000
          });
      }
    });
  }

  public deleteVenue(id: string): void {
    const ref = this.dialogService.open(DeleteVenueComponent, {
      data: {
        id: id
      },
      header: this.translate.instant('VENUE.DELETE_VENUE'),
      styleClass: 'dialog_long'
    });
    ref.onClose.subscribe((item: any) => {
      if (item) {
        this.getAllVenues(this.searchVenueModel);
        this.messageService.add({severity:'error', summary: this.translate.instant('VENUE.VENUE_DELETED'), life:3000});
      }
    });
  }
  //#endregion

  //#Private Method
  private addFunctionToAllVenues(): void {
    this.listAllVenues.forEach(venue => {
      this.items[venue.id] = [{
        label: this.translate.instant('EDIT'),
        command: () => {
          this.editVenue(venue);
        }
      },
        {
          label: this.translate.instant('DELETE'),
          command: () => {
            this.deleteVenue(venue.id);
          }
        }];
    });
  }
  public showInstagramPosts(venueId: string){
    this._router.navigate(['/venues', venueId, 'instagram-posts']);
  }
  //#endregion
}
