<p-progressSpinner class="soc-manager-spinner" *ngIf="spinner"></p-progressSpinner>
<div class="panel-wrapper">
  <div class="display-block-mbl d-flex flex-row justify-content-between pb-2">
    <div class="w-50 w-100-mbl">
      <label class="lbl-soc-manager-field">{{'HOST.NAME' | translate}}
        <i *ngIf="errorModel!=null && errorModel['FirstName']!=null"
           class="pi pi-exclamation-circle me-1 mt-1 error-color float-end" pTooltip="{{errorModel['FirstName']|translate}}"
           tooltipPosition="top"></i>
      </label>
      <input type="text" class="soc-manager-input-field" [(ngModel)]="addOrEditHostModel.firstName">
    </div>
    <div class="w-50 w-100-mbl">
      <label class="lbl-soc-manager-field">{{'HOST.SURNAME' | translate}}
        <i *ngIf="errorModel!=null && errorModel['LastName']!=null"
           class="pi pi-exclamation-circle me-1 mt-1 error-color float-end"
           pTooltip="{{errorModel['LastName']|translate}}" tooltipPosition="top"></i>
      </label>
      <input type="text" class="soc-manager-input-field" [(ngModel)]="addOrEditHostModel.lastName">
    </div>
  </div>
  <div class="display-block-mbl d-flex flex-row justify-content-between pb-2">
    <div class="w-50 w-100-mbl">
      <label class="lbl-soc-manager-field">{{'HOST.EMAIL' | translate}}
        <i *ngIf="errorModel!=null && errorModel['Email']!=null && !email.errors?.pattern"
           class="pi pi-exclamation-circle float-end me-1 error-color pt-1"
           pTooltip="{{errorModel['Email']|translate}}" tooltipPosition="top"></i>
        <i *ngIf="email.errors?.pattern"
           class="pi pi-exclamation-circle float-end me-1 error-color pt-1"
           pTooltip="{{'INFO.INVALID_EMAIL'|translate}}" tooltipPosition="top"></i>
      </label>
      <input type="text" class="soc-manager-input-field" [(ngModel)]="addOrEditHostModel.email"
             #email=ngModel pattern="^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$">
    </div>
    <div class="w-50 w-100-mbl">
      <label class="lbl-soc-manager-field">{{'HOST.PHONE' | translate}}
        <i *ngIf="errorModel!=null && errorModel['Phone']!=null"
           class="pi pi-exclamation-circle me-1 mt-1 error-color float-end" pTooltip="{{errorModel['Phone']|translate}}"
           tooltipPosition="top"></i>
      </label>
      <input type="number" class="soc-manager-input-field custom_number_input" [(ngModel)]="addOrEditHostModel.phone">
    </div>
  </div>
  <div class="d-flex flex-row justify-content-between mt-3">
    <button class="create-btn w-50" [disabled]="email.errors?.pattern" (click)="createHost(addOrEditHostModel)">{{ 'SAVE' | translate}}</button>
    <button class="btn-outline-transparent w-50" (click)="cancel()">{{ 'CANCEL' | translate}}</button>
  </div>
</div>
<p-toast position="bottom-center" [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'"></p-toast>
