<p-progressSpinner class="soc-manager-spinner spinner-in-front" *ngIf="spinner"></p-progressSpinner>
<div class="panel-wrapper">
  <div class="d-flex flex-row justify-content-between pb-2">
    <div class="d-flex flex-row w-50 w-100-mbl">
      <input (change)="onFileSelected($event)" class="open-file" style="display: none;" type="file" id="openFile"
        #openFile name="openFile" accept="image/png, image/jpg, image/jpeg" />
      <img class="edit-picture"
        (click)="openAddImage()"
        [src]="!editProfileModel.profilePictureUrl ? imagePath : editProfileModel.profilePictureUrl"
        alt="profile-picture">
      <div class="picture-edit mt-2">
        <p class="hide-mobile mt-3 primary-medium-text-16">
          {{'EDIT_PROFILE.UPLOAD_IMAGE_MESSAGE_MOBILE' | translate}}
          <label *ngIf="pictureIsWrongFormat">
            <i class="pi pi-exclamation-circle me-1 error-color"
              pTooltip="{{'EDIT_PROFILE.WRONG_FORMAT' | translate}}" tooltipPosition="top"></i>
          </label>
        </p>
      </div>
    </div>
    <div class="w-50 w-100-mbl align-self-center">
      <button class="btn-outline-transparent magenta-color fz-16"
              (click)="deactiveAccount()">{{'INFO.DELETE_ACCOUNT' | translate}}</button>
    </div>
  </div>
  <div class="display-block-mbl d-flex flex-row justify-content-between pb-2">
    <div class="w-50 w-100-mbl">
      <label class="lbl-soc-manager-field">{{'EDIT_PROFILE.NAME' | translate}}
        <i *ngIf="errorModel!=null && errorModel['Name']!=null"
          class="pi pi-exclamation-circle me-1 mt-1 error-color float-end" pTooltip="{{errorModel['Name']|translate}}"
          tooltipPosition="top"></i>
      </label>
      <input type="text" class="soc-manager-input-field" [(ngModel)]="editProfileModel.firstName">
    </div>
    <div class="w-50 w-100-mbl">
      <label class="lbl-soc-manager-field">{{'EDIT_PROFILE.SURNAME' | translate}}
        <i *ngIf="errorModel!=null && errorModel['Surname']!=null"
          class="pi pi-exclamation-circle me-1 mt-1 error-color float-end"
          pTooltip="{{errorModel['Surname']|translate}}" tooltipPosition="top"></i>
      </label>
      <input type="text" class="soc-manager-input-field" [(ngModel)]="editProfileModel.lastName">
    </div>
  </div>
  <div class="display-block-mbl d-flex flex-row justify-content-between pb-2">
    <div class="w-50 w-100-mbl">
      <!-- <label class="lbl-soc-manager-field">{{'EDIT_PROFILE.ADDRESS' | translate}}
        <i *ngIf="errorModel!=null && errorModel['Address']!=null"
           class="pi pi-exclamation-circle me-1 mt-1 error-color float-end"
           pTooltip="{{errorModel['Address']|translate}}" tooltipPosition="top"></i>
      </label>
      <input type="text" class="soc-manager-input-field" [(ngModel)]="editProfileModel.address"> -->
      <label class="lbl-soc-manager-field">{{'EDIT_PROFILE.EMAIL' | translate}}
        <i *ngIf="errorModel!=null && errorModel['Email']!=null"
          class="pi pi-exclamation-circle me-1 mt-1 error-color float-end" pTooltip="{{errorModel['Email']|translate}}"
          tooltipPosition="top"></i>
      </label>
      <input type="text" class="soc-manager-input-field" [(ngModel)]="editProfileModel.email" disabled>
    </div>
    <div class="w-50 w-100-mbl">
      <label class="lbl-soc-manager-field">{{'EDIT_PROFILE.PHONE' | translate}}
        <i *ngIf="errorModel!=null && errorModel['Phone']!=null"
          class="pi pi-exclamation-circle me-1 mt-1 error-color float-end" pTooltip="{{errorModel['Phone']|translate}}"
          tooltipPosition="top"></i>
      </label>
      <input type="text" class="soc-manager-input-field" [(ngModel)]="editProfileModel.phone">
    </div>
  </div>
  <div class="display-block-mbl d-flex flex-row justify-content-between pb-2">
    <div class="w-50 w-100-mbl">
      <!-- <label class="lbl-soc-manager-field">{{'EDIT_PROFILE.EMAIL' | translate}}
        <i *ngIf="errorModel!=null && errorModel['Email']!=null"
          class="pi pi-exclamation-circle me-1 mt-1 error-color float-end" pTooltip="{{errorModel['Email']|translate}}"
          tooltipPosition="top"></i>
      </label>
      <input type="text" class="soc-manager-input-field" [(ngModel)]="editProfileModel.email" disabled> -->
    </div>
    <div class="w-50 w-100-mbl">
      <button class="change-password-btn" (click)="changePassword()">{{'INFO.CHANGE_PASSWORD' | translate}}</button>
    </div>
  </div>
  <div class="display-block-mbl d-flex flex-row justify-content-between pb-2 mt-3">
    <button class="create-btn w-50 w-100-mbl" (click)="updateProfile(editProfileModel)">{{'SAVE' | translate}}</button>
    <button class="btn-outline-transparent w-50 w-100-mbl mt-4-mlb" (click)="cancel()">{{'CANCEL' | translate}}</button>
  </div>
</div>
<p-toast position="bottom-center" [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'"></p-toast>
