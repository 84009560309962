import { Component, OnInit } from '@angular/core';
import { NgbTooltip } from "@ng-bootstrap/ng-bootstrap";
import { Subject, takeUntil } from "rxjs";
import { ChangePasswordModel } from "../../models/change-password/change-password.model";
import { DynamicDialogRef } from "primeng/dynamicdialog";
import { AuthenticationService } from "../../services/authentication/authentication.service";
import { Message, MessageService } from "primeng/api";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-change-password-dialog',
  templateUrl: './change-password-dialog.component.html',
  styleUrls: ['./change-password-dialog.component.scss']
})
export class ChangePasswordDialogComponent implements OnInit {

  //#region Fields
  public unsubscribe: Subject<void> = new Subject<void>()
  public changePasswordModel: ChangePasswordModel = new ChangePasswordModel();
  public errorModel: { [k: string]: any } = {};
  public errorMsg: Message[];
  public spinner = false;
  public requiredFieldErrorMessage = "INFO.REQUIRED_FIELD";
  public invalidPasswordErrorMessage = "INFO.INVALID_PASSWORD";
  public passwordMatchErrorMessage = "INFO.PASSWORD_MATCH";
  public invalidOldPassword = "INFO.INVALID_OLD_PASSWORD"
  //#endregion

  //#region Constructor
  constructor(
    public ref: DynamicDialogRef,
    public authenticationService: AuthenticationService,
    public messageService: MessageService,
    public translate: TranslateService
  ) {
  }

  ngOnInit(): void {

  }
  //#endregion

  //#region Public Methods
  public changePassword(model: ChangePasswordModel): void {
    this.errorModel = {};
    this.errorMsg = [];

    if (model && (!model.oldPassword || model.oldPassword.trim() == "")) {
      this.errorModel['OldPassword'] = this.requiredFieldErrorMessage;
    }
    if (model && (!model.newPassword || model.newPassword.trim() == "")) {
      this.errorModel['NewPassword'] = this.requiredFieldErrorMessage;
    } else if (!this.isPasswordValid()) {
      this.errorModel['NewPassword'] = this.invalidPasswordErrorMessage;
    }
    if (model && (!model.confirmNewPassword || model.confirmNewPassword.trim() == "")) {
      this.errorModel['ConfirmPassword'] = this.requiredFieldErrorMessage;
    }
    if (model && model.confirmNewPassword !== model.newPassword) {
      this.errorModel['ConfirmPassword'] = this.passwordMatchErrorMessage;
    }

    if (Object.keys(this.errorModel).length === 0) {
      this.spinner = true;
      this.authenticationService.changePasswordManually(model)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe({
          next: _ => {
            this.spinner = false;
            const summary = this.translate.instant('INFO.PASSWORD_CHANGED');
            this.messageService.add({severity: 'success', summary, life: 2000});
            this.ref.close(true);
          },
          error: errorResponse => {
            this.spinner = false;
            if (errorResponse.error.StatusCode === -302) {
              this.errorModel['OldPassword'] = this.invalidOldPassword;
            }
          }
        });
    }
  }

  public cancel(): void {
    this.ref.close(false);
  }

  public hasUppercaseLetter(): boolean {
    return this.changePasswordModel.newPassword !== undefined && (/[A-Z]/).test(this.changePasswordModel.newPassword);
  }

  public hasLowercaseLetter(): boolean {
    return this.changePasswordModel.newPassword !== undefined && (/[a-z]/).test(this.changePasswordModel.newPassword);
  }

  public hasSpecialCharacter(): boolean {
    return this.changePasswordModel.newPassword !== undefined && (/[!$%@#£€*?&.,:]/).test(this.changePasswordModel.newPassword);
  }

  public has8Characters(): boolean {
    return this.changePasswordModel.newPassword !== undefined && (/.{8,}/).test(this.changePasswordModel.newPassword);
  }

  public openTooltip(tipContent: NgbTooltip): void {
    if (!tipContent.isOpen()) {
      tipContent.open();
    }
  }

  public closeTooltip(tipContent: NgbTooltip): void {
    tipContent.close();
  }

  public isPasswordValid(): boolean {
    return this.changePasswordModel.newPassword !== undefined && (/^(?=.*[A-Z])(?=.*[a-z])(?=.*[!$%@#£€*?&.,:])(?!.*\s).{8,}$/).test(this.changePasswordModel.newPassword);
  }
  //#endregion
}
