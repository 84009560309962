import { RoleNameEnum } from "../../enums/role-name.enum";

export class UserModel {
  id: string;
  roles: Array<RoleNameEnum>;

  constructor(id: string, roles: Array<RoleNameEnum>) {
    this.id = id;
    this.roles = roles ? roles : [];
  }
}
