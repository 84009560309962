import { Component, ElementRef, OnInit, NgZone, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogComponent, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subject, take, takeUntil, tap } from 'rxjs';
import { VenueModel } from "../../../models/venues/venue.model";
import { BusinessType } from "src/app/share-modul/models/company/business-type.model";
import { UploadImageModel } from "src/app/share-modul/models/image/upload-image.model";
import { CompressImageService } from "src/app/share-modul/services/common/compress-image.service";
import { ManageVenuesService } from "../../../services/venues/venues.service";
import {
  MultipleImagesUploadComponent
} from "src/app/share-modul/components/multiple-images-upload/multiple-images-upload.component";
import { EditHostsComponent } from "../../hosts/edit-hosts/edit-hosts.component";
import { TranslateService } from "@ngx-translate/core";
import { AuthenticationService } from "../../../../share-modul/services/authentication/authentication.service";
import { ApplicationId } from 'src/app/share-modul/enums/applicationid.enum';
import { UpdateGaleryImageModel } from 'src/app/socialite-manager-panel/models/venues/update-gallery.model';
import { AccessProjectsService } from "../../../services/products/access-projects.service";
import { environment } from 'src/environments/environment';

declare const google: any;

@Component({
  selector: 'app-add-or-edit-venues',
  templateUrl: './add-or-edit-venues.component.html',
  styleUrls: ['./add-or-edit-venues.component.scss']
})
export class AddOrEditVenuesComponent implements OnInit, OnDestroy, AfterViewInit {

  //#region Fields
  @ViewChild('search') searchElementRef: ElementRef;
  public unsubscribe: Subject<void> = new Subject<void>();
  public pictureIsWrongFormat: boolean = false;
  public venueModel: VenueModel = new VenueModel();
  public filePicture: any;
  public pictureFormat: Array<string>;
  public imagePath: string = './assets/images/logo/venuelogo.png';
  public errorModel: { [k: string]: any } = {};
  public spinner: boolean = false;
  public venueId: string;
  public enableEdit: boolean = false;
  public requiredFieldErrorMessage = "INFO.REQUIRED_FIELD";
  public invalidAddressMessage = "INFO.INVALID_ADDRESS";
  public invalidPhoneNumber = "INFO.INVALID_PHONE_NUMBER";
  public businessTypes: Array<BusinessType> = [];
  public venueImageGaleryPhotos: Array<UploadImageModel> = [];
  public autocomplete: google.maps.places.Autocomplete;
  public productList: Array<any> = new Array<any>();
  public companyId: string;
  public showAddWebSites : boolean = false;
  private _listener = () => {
    let instagramLongLivedAccessToken = localStorage.getItem("instagram_long_lived_token_" + this.venueId);
    let instagramAccessToken = localStorage.getItem("instagram_access_token_" + this.venueId);
    localStorage.removeItem("instagram_long_lived_token_" + this.venueId)
    localStorage.removeItem("instagram_access_token_" + this.venueId);

    if (instagramLongLivedAccessToken != null) {
      this.venueModel.instagramToken = instagramLongLivedAccessToken;
      this.venueService.getInstagramId(instagramLongLivedAccessToken).subscribe(response => {
        this.venueModel.instagramId = response.data[0]['instagram_business_account']['id'];
        this.venueModel.instagramAppId = environment.meta.appId;
        if (this.venueModel.instagramId && this.venueModel.instagramToken) {
          this.venueService.getInstagramUsername(this.venueModel.instagramId, this.venueModel.instagramToken).subscribe(r =>
            this.venueModel.instagramName = r['username']
          )
        }

      });
    } else {
      if (instagramAccessToken != null) {
        this.venueService.exchangeShortTermTokenForLongTerm(environment.meta.appId, environment.meta.secret, instagramAccessToken).subscribe(
          responseAccessToken => {
            if (responseAccessToken['access_token']) {
              this.venueModel.instagramToken = responseAccessToken['access_token'];
              this.venueService.getInstagramId(responseAccessToken['access_token']).subscribe(response => {
                this.venueModel.instagramId = response.data[0]['instagram_business_account']['id'];
                this.venueModel.instagramAppId = environment.meta.appId;
                if (this.venueModel.instagramId && this.venueModel.instagramToken) {
                  this.venueService.getInstagramUsername(this.venueModel.instagramId, this.venueModel.instagramToken).subscribe(r =>
                    this.venueModel.instagramName = r['username']
                  )
                }
              });
            }
          })
      }
    }
  };
  //#endregion

  //#region Constructor
  constructor(
    private openFile: ElementRef,
    private config: DynamicDialogConfig,
    private ref: DynamicDialogRef,
    private refDialogComponent: DynamicDialogComponent,
    private dialogService: DialogService,
    private venueService: ManageVenuesService,
    private messageService: MessageService,
    private compressImage: CompressImageService,
    private ngZone: NgZone,
    public translate: TranslateService,
    public authenticationService: AuthenticationService,
    public service: AccessProjectsService
  ) {
    this.pictureFormat = ['image/png', 'image/jpg', 'image/jpeg'];

    if (this.config.data != undefined && this.config.data.id) {
      this.venueId = this.config.data.id
      this.enableEdit = true;
    }

    if (this.config.data != undefined && this.config.data.companyId) {
      this.companyId = this.config.data.companyId;
    }
  }

  ngOnInit(): void {
    if (window.addEventListener) {
      window.addEventListener("storage", this._listener, false);
    }
    this.getBusinessTypesForCompany();
    this.getProducts();
    if (this.enableEdit) {
      this.getVenueById(this.venueId);
    }
  }

  ngAfterViewInit() {
    this.findAddress();
  }
  //#endregion

  //#region Public Methods
  addVenue(model: VenueModel) {
    this.errorModel = {};
    if (model && model.name == "") {
      this.errorModel['name'] = this.requiredFieldErrorMessage;
    }
    if (model && !model.phone) {
      this.errorModel['phone'] = this.requiredFieldErrorMessage;
    }
    if(model && model.phone && (!model.phone.startsWith("+") || model.phone.replace(/\s/g,'').substring(1).match(/^\d+$/) == null)){
      this.errorModel['phone'] = this.invalidPhoneNumber;
    }
    if (model && model.businessTypeIds?.length === 0) {
      this.errorModel['businessType'] = this.requiredFieldErrorMessage;
    }
    if (model && model.address == "") {
      this.errorModel['adress'] = this.requiredFieldErrorMessage;
    }
    if (model && (!model.latitude || !model.longitude)) {
      this.errorModel['adress'] = this.invalidAddressMessage;
    }
    if (model && model.email == "") {
      this.errorModel['email'] = this.requiredFieldErrorMessage;
    }
    if (model && (model.description == "" || model.description == undefined)) {
      this.errorModel['description'] = this.requiredFieldErrorMessage;
    }
    if (Object.keys(this.errorModel).length === 0) {
      this.spinner = true;
      this.venueService.addvenue(model)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe({
          next: (response) => {
            this.spinner = false;
            this.ref.close(response);
          },
          error: error => {

            this.spinner = false;
            if(error.error){
              this.messageService.add({ severity: 'error', summary: error.error.Message, life: 2000 });
            }
            else{
            this.messageService.add({ severity: 'error', summary: "System error", life: 2000 });
            }
          }
        });
    }
  }

  updateVenue(model: VenueModel) {
    this.errorModel = {};
    if (model && model.name == "") {
      this.errorModel['name'] = this.requiredFieldErrorMessage;
    }
    if (model && !model.phone) {
      this.errorModel['phone'] = this.requiredFieldErrorMessage;
    }
    if(model && model.phone && (!model.phone.startsWith("+") || model.phone.replace(/\s/g,'').substring(1).match(/^\d+$/) == null)){
      this.errorModel['phone'] = this.invalidPhoneNumber;
    }
    if (model && model.businessTypeIds?.length === 0) {
      this.errorModel['businessType'] = this.requiredFieldErrorMessage;
    }
    if (model && model.address == "") {
      this.errorModel['adress'] = this.requiredFieldErrorMessage;
    }
    if (model && (!model.latitude || !model.longitude)) {
      this.errorModel['adress'] = this.invalidAddressMessage;
    }
    if (model && model.email == "") {
      this.errorModel['email'] = this.requiredFieldErrorMessage;
    }
    if (model && model.description == "") {
      this.errorModel['description'] = this.requiredFieldErrorMessage;
    }

    if (Object.keys(this.errorModel).length === 0) {
      this.spinner = true;
      this.venueService.updatevenue(model, this.filePicture)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe({
          next: (response) => {
            this.spinner = false;
            this.ref.close(true);
          },
          error: errorResponse => {
            this.spinner = false;
            this.messageService.add({ severity: 'error', summary: "System error", life: 2000 });
          }
        });
    }
  }

  getVenueById(venueId: string) {
    this.spinner = true;
    this.venueService.getvenuedetails(venueId)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: (response) => {
          this.spinner = false;
          this.venueModel = response;
          this.venueModel.businessTypeIds = response.businessTypes?.map((x: BusinessType) => x.id);
          this.venueModel.applicationIds = response.applicationIds;
          if( this.venueModel.applicationIds.includes(ApplicationId.Resy)){
            this.showAddWebSites = true;
          }
        },
        error: errorResponse => {
          this.spinner = false;
          this.messageService.add({ severity: 'error', summary: "System error", life: 2000 });
        }
      });
  }

  cancel(): void {
    this.ref.close(false);
  }

  openAddImage(): void {
    var element = this.openFile.nativeElement.querySelector(".open-file");
    element.click();
  }

  onFileSelected(event: any): void {
    if (this.errorModel != null && this.errorModel['image'] != null) {
      delete this.errorModel['image'];
    }
    this.pictureIsWrongFormat = false;
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      if (!this.pictureFormat.includes(event.target.files[0].type)) {
        this.pictureIsWrongFormat = true;
        return;
      }
      let image: File = event.target.files[0]
      reader.readAsDataURL(image);// read file as data url
      this.compressImage.compress(image)
        .pipe(take(1))
        .subscribe(compressedImage => {
          this.filePicture = compressedImage;
          this.venueModel.VenueImageFile = compressedImage;
        })
      reader.onload = (item) => { // called once readAsDataURL is completed
        if (item.target?.result) {
          this.venueModel.pictureUrl = item.target?.result as string;
        }
      }
    }
  }

  findAddress() {
      this.autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        types: ["address"],
      });
      this.autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          this.errorModel['adress'] = null;
          let place: google.maps.places.PlaceResult = this.autocomplete.getPlace();
          if (!place || !place.geometry) {
            this.errorModel['adress'] = this.invalidAddressMessage;
            return;
          }
          if (place.formatted_address) {
            this.venueModel.address = place.formatted_address;
            this.venueModel.formatedAddress = place.formatted_address;
          }
          this.venueModel.googlePlaceId = place.place_id;
          // @ts-ignore
          this.venueModel.latitude = place.geometry?.location.lat();
          // @ts-ignore
          this.venueModel.longitude = place.geometry?.location.lng();
          for (const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
            if (component.types.includes("postal_code")) {
              this.venueModel.postalCode = `${component.short_name}`;
              continue;
            }
            if (component.types.includes("locality") || component.types.includes("postal_town")) {
              this.venueModel.city = `${component.short_name}`;
              continue;
            }
            if (component.types.includes("country")) {
              this.venueModel.countryCode = `${component.short_name}`;
            }
          }
        });
      });
  }

  public onAddressManualChange(): void {
    this.venueModel.latitude = undefined;
    this.venueModel.longitude = undefined;
  }

  getBusinessTypesForCompany() {
    this.authenticationService.getCompanyDetails().subscribe(response => {
      this.businessTypes = response.businessTypesCompanies;
      this.companyId = response.id
    });
  }

  public getProducts(): void {
    // todo: fetch products
    // test
    this.productList = [{ id: ApplicationId.Pos, name: "Socialite POS" }, { id: ApplicationId.Resy, name: "Socialite Resy" }, { id: ApplicationId.AdvertiserEvents, name: "Socialite Events" }];
  }

  uploadImagesDialog(): void {
    // if (!this.venueModel.galeryImages) {
    //   this.venueModel.galeryImages = [];
    // }
    const ref = this.dialogService.open(MultipleImagesUploadComponent, {
      data: {
        venueId: this.venueModel.id,
      },
      showHeader: true,
      closable: false,
      styleClass: 'dialog-long dialog-img-cropper'
    });
    ref.onClose.subscribe((response: any[]) => {
      if (response) {
        this.messageService.add({ severity: 'success', summary: this.translate.instant('VENUE.VENUE_GALERRY_ADDED'), life: 3000 });
      }
    });
  }

  public openListAndEditHostsPopup(): void {
    this.refDialogComponent.unbindDocumentKeydownListener();

    const ref = this.dialogService.open(EditHostsComponent, {
      data: {
        venue: this.venueModel,
        companyId: this.companyId
      },
      styleClass: 'small-header-dialog',
      width: '650px',
      closable: false
    });

    ref.onClose.subscribe((item: any) => {
      if (this.enableEdit) {
        this.getVenueById(this.venueId);
      }
      if (item) { }
    });

    ref.onDestroy.pipe(
      take(1),
      tap(() => this.refDialogComponent.bindDocumentKeydownListener())).subscribe();
  }
  modifyConnectionToInstagram() {
    if (!this.venueModel.instagramId) {
      var clientId = environment.meta.appId;
      var display = "page";
      var extras = "{\"setup\":{\"channel\":\"IG_API_ONBOARDING\"}}";
      var redirectUri = environment.URLSocialiteManager + "/instagram-support" + "?venueId=" + this.venueId;
      var responseType = "token";
      var scope = "public_profile,email,instagram_basic,instagram_content_publish,pages_show_list,ads_management,business_management,pages_read_engagement,instagram_manage_comments";
      var url = "https://www.facebook.com/dialog/oauth?client_id=" + clientId + "&display=" + display + "&extras=" + extras + "&redirect_uri=" + redirectUri + "&response_type=" + responseType + "&scope=" + scope;
      window.open(url, 'blank');
    } else {
      this.venueService.disconnectVenueFromInstagram(this.venueId).subscribe(response => {
        this.venueModel.instagramId = null;
        this.venueModel.instagramAppId = null;
        this.venueModel.instagramToken = null;
        this.venueModel.instagramName = null;
        this.messageService.add({
          severity: 'success',
          summary: this.translate.instant('VENUE.VENUE_EDITED'),
          life: 3000,
          styleClass: 'z-index-message'
        });
      });
    }
  }

  addVenueWebsite() {
    const isEmpty = (value: string | null | undefined) => value === undefined || value === null || value === '';
    if (this.venueModel.webSites.length >0 && !this.venueModel.webSites.some((item) => isEmpty(item))) {
      this.venueModel.webSites.push('');
    }
    if (this.venueModel.webSites.length == 0) {
      this.venueModel.webSites.push('');
    }
  }

  removeWebsite(index: number) {
    this.venueModel.webSites.splice(index, 1);
  }

  changeTrackBy(index: number, obj: any): any {
    return index;
  }
  isResySelect(productIds : Array<any>){
    if(productIds.includes(ApplicationId.Resy)){
      this.showAddWebSites = true;
    }
    else{
      this.showAddWebSites = false;     
    }
  }
  public ngOnDestroy(): void {
    if (this.autocomplete) {
      this.autocomplete.unbindAll();
    }
    google.maps.event.clearInstanceListeners(this.autocomplete);
    const elements = document.querySelectorAll('.pac-container');
    elements.forEach((element) => {
      element.remove();
    });
    window.removeEventListener("storage", this._listener, false);
  }
  //#endregion
}
