<div [ngClass]="[ !inMenu? 'socialite_manager_navbar_in'  : '', ]" class="socialite_manager_navbar">
  <nav id="sidebar">
    <ul class="nav ul_menu" *ngIf="inMenu">
      <li class="manager_info w-100 m-0">
        <span>{{'NAVBAR.MY_PRODUCTS' | translate | uppercase}}</span>
        <hr>
      </li>
      <li class="nav-item w-100" *ngFor="let product of productList" title="{{product.name | uppercase}}">
        <a [routerLink]="'/products/' + product.id +'/details'">
          <span [innerHTML]="product.name | uppercase | remove_highlight" class="spam-text"></span>
        </a>
      </li>
    </ul>
  </nav>
</div>
