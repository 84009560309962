<p-progressSpinner class="change-password-spinner spinner-in-front" *ngIf="spinner" ></p-progressSpinner>
<div class="panel-wrapper">
  <div class="d-flex justify-content-left">
    <span class="change-password-message">{{'INFO.CHANGE_PASSWORD' | translate}}</span>
  </div>
  <div class="d-flex justify-content-start mb-1">
    <span class="primary-medium-text-16">{{'INFO.RESET_PASSWORD_MESSAGE' | translate}}</span>
  </div>
  <div class="pb-2">
    <label class="lbl-soc-manager-field">{{'LOGIN.PASSWORD' | translate}}
      <i *ngIf="errorModel!=null && errorModel['OldPassword']!=null"
         class="pi pi-exclamation-circle float-end me-1 error-color"
         pTooltip="{{errorModel['OldPassword']|translate}}" tooltipPosition="top"></i>
    </label>
    <p-password [(ngModel)]="changePasswordModel.oldPassword" [feedback]="false" [toggleMask]="true"
                [styleClass]="'p-password p-component p-inputwrapper p-input-icon-right'"
                [id]="'sign_up_password_filed'"
                [inputStyleClass]="'soc-manager-input-field password-input-field'">
    </p-password>
  </div>
  <div class="pb-2">
    <label class="lbl-soc-manager-field">{{'INFO.RESET_PASSWORD' | translate}}
      <i *ngIf="errorModel!=null && errorModel['NewPassword']!=null"
         class="pi pi-exclamation-circle float-end me-1 error-color"
         pTooltip="{{errorModel['NewPassword']|translate}}" tooltipPosition="top"></i>
      <i *ngIf="(changePasswordModel.newPassword !== undefined && !isPasswordValid()) && (errorModel==null || errorModel['NewPassword']==null)"
         class="pi pi-exclamation-circle float-end me-1 error-color"
         pTooltip="{{'INFO.INVALID_PASSWORD' | translate}}" tooltipPosition="top"></i>
    </label>
    <p-password [(ngModel)]="changePasswordModel.newPassword" [toggleMask]="true" [feedback]="false"
                [styleClass]="'p-password p-component p-inputwrapper p-input-icon-right'"
                [id]="'sign_up_password_filed'"
                (click)="openTooltip(t1)" (mouseleave)="closeTooltip(t1)" (mouseenter)="openTooltip(t1)"
                [inputStyleClass]="'soc-manager-input-field password-input-field'" [ngbTooltip]="passwordInfoTooltipContent"
                [animation]="false" [placement]="['right', 'left']" triggers="manual"
                tooltipClass="change-pass-password-tooltip signup-password-tooltip" #t1="ngbTooltip"
                [autoClose]="false"></p-password>
  </div>
  <div class="pb-2">
    <label class="lbl-soc-manager-field">{{'SIGN_UP.CONFIRM_PASSWORD'|translate}}
      <i *ngIf="errorModel!=null && errorModel['ConfirmPassword']!=null"
         class="pi pi-exclamation-circle float-end me-1 error-color"
         pTooltip="{{errorModel['ConfirmPassword']|translate}}" tooltipPosition="top"></i>
    </label>
    <p-password [(ngModel)]="changePasswordModel.confirmNewPassword" [feedback]="false" [toggleMask]="true"
                [styleClass]="'p-password p-component p-inputwrapper p-input-icon-right'"
                [id]="'sign_up_password_filed'"
                [inputStyleClass]="'soc-manager-input-field password-input-field'">
    </p-password>
  </div>
  <div class="d-flex flex-column w-100" style="gap: 10px;">
    <button class="create-btn mt-3 mb-3 w-100" (click)="changePassword(changePasswordModel)">{{'SAVE' | translate}}</button>
    <button class="btn-outline-transparent w-100 m-0 mb-2" style="font-size: 14px;" (click)="cancel()">{{'CANCEL' | translate}}</button>
  </div>
</div>
<ng-template #passwordInfoTooltipContent>
  <div class="password-tooltip-content">
    <span>{{'INFO.PASSWORD_PATTERN_MESSAGE_1' | translate}} </span>
    <span [class.password-rule-checked]="has8Characters()">{{'INFO.PASSWORD_PATTERN_MESSAGE_2' | translate}} </span>
    <span>{{'INFO.PASSWORD_PATTERN_MESSAGE_3' | translate}}</span>
    <ul class="m-0">
      <li [class.password-rule-checked]="hasUppercaseLetter()">{{'INFO.PASSWORD_1_UPPERCASE' | translate}}</li>
      <li [class.password-rule-checked]="hasLowercaseLetter()">{{'INFO.PASSWORD_1_LOWERCASE' | translate}}</li>
      <li [class.password-rule-checked]="hasSpecialCharacter()">{{'INFO.PASSWORD_SPECIAL_CHAR' | translate}}
      </li>
    </ul>
  </div>
</ng-template>
