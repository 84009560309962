<div class="custom-dialog-header d-flex justify-content-between p-3">
  <div class="magenta-color custom-dialog-header-txt">{{'DELETE' | translate}}?</div>
  <div class="custom-dialog-close-container">
    <button type="button" class="custom-dialog-close-btn" (click)="close()">
      <span class="pi pi-times"></span>
    </button>
  </div>
</div>
<p-progressSpinner class="w-100 text-center soc-manager-spinner" *ngIf="spinner"></p-progressSpinner>
<div class="panel-wrapper">
  <div class="pb-4 text-center delete-message p-0">
    <h4>{{'VENUE.DELETE_IMAGES_MESSAGE'|translate}}</h4>
  </div>
  <div class="d-flex flex-row justify-content-between">
    <button class="create-btn w-45 mt-0" (click)="deleteImages()">{{'DELETE' | translate}}</button>
    <button class="btn-cancel-no-border w-45" (click)="close()">{{'CANCEL' | translate}}</button>
  </div>
</div>
