import { GaleryImageModel } from "./galery-image.model";

export class VenueModel {
  public id: string;
  public companyId: string;
  public name: string = "";
  public description: string;
  public address: string = "";
  public formatedAddress: string = "";
  public city: string = "";
  public postalCode: string = "";
  public countryCode: string = "";
  public googlePlaceId: string | undefined;
  public maximumCapacity: number = 0;
  public email: string = "";
  public phone: string = "";
  public pictureUrl: any;
  public VenueImageFile: any;
  public latitude: number | undefined;
  public longitude: number | undefined;
  public businessTypeIds: Array<number> = new Array<number>();
  // public venueImageGaleryPhotos: Array<string | ArrayBuffer | File | undefined> = [];
  public galeryImages: Array<GaleryImageModel> = new Array<GaleryImageModel>();
  public userRules: string;
  public sendLocationNotifications: boolean = false;
  public applicationIds: Array<any> = new Array<any>();
  public hostsids: Array<string> = new Array<string>();
  public instagramToken: string | null;
  public instagramName: string | null;
  public instagramId: string | null;
  public instagramAppId: string | null;
  public webSites: Array<string>  = [];
}
