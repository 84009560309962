<div class="bg_login d-flex">
  <div class="w-100 h-100 login_div flex-column">
    <div class="w-100 logo_div">
      <div class="logo"></div>
    </div>
    <div class="w-100 logo_div">
      <div class="welcome-text-lg"></div>
    </div>
    <div class="w-75 welcome-message-container">
      <span class="welcome-message">{{'INFO.WELCOME_MESSAGE_1' | translate}}</span>
      <br>
      <span class="welcome-message">{{'INFO.WELCOME_MESSAGE_2' | translate}}</span>
      <div class="d-flex flex-wrap justify-content-center">
        <span *ngFor="let product of productList" class="product-name pe-5">
          {{product.name}}
        </span>
      </div>
    </div>
    <div class="login_form">
      <h3 class="sign_in d-flex justify-content-center w-100">{{'INFO.SIGN_IN' | translate}}</h3>
      <div class="login_field">
        <div class="w-100">
          <label class="w-100">
            <i *ngIf="errorModel!=null && errorModel['Username']!=null"
               class="pi pi-exclamation-circle float-end me-1 error_color pt-1"
               pTooltip="{{errorModel['Username']|translate}}" tooltipPosition="top"></i>
          </label>
          <input type="text" class="input_field w-100" [(ngModel)]="loginModel.username" placeholder="{{'LOGIN.EMAIL'|translate}}">
        </div>
        <div class="w-100">
          <label class="w-100">
            <i *ngIf="errorModel!=null && errorModel['Password']!=null"
               class="pi pi-exclamation-circle float-end me-1 error_color pt-1"
               pTooltip="{{errorModel['Password']|translate}}" tooltipPosition="top"></i>
          </label>
          <input type="password" class="input_field w-100" [(ngModel)]="loginModel.password" placeholder="{{'LOGIN.PASSWORD'|translate}}">
        </div>
        <div class="page-links mt-1 mb-1">
          <a type="button" routerLink="/forgot-password" class="right signup_link">{{'INFO.FORGOT_PASSWORD'|translate}}?</a>
        </div>
        <button class="btn_login_manager" (click)="login(loginModel)">{{'INFO.SIGN_IN'|translate}}</button>
        <p class="p_sign_up mt-3 mb-3">{{'INFO.GO_TO_REGISTER' | translate}} <a type="button" routerLink="/signup" class="signup_link">{{'INFO.SIGN_UP' | translate }}</a></p>
        <div class="w-100 mb-3">
          <button class="btn-outline-transparent" (click)="signInWithFacebook()">
            <i class="pi social-icon fb-icon me-3"></i>
            {{'INFO.SIGN_IN_FB' | translate}}</button>
        </div>
        <!-- <div class="w-100">
          <button class="btn-outline-transparent" (click)="signInWithApple()" >
            <i class="pi social-icon apple-icon me-3"></i>
            {{'INFO.SIGN_IN_APPLE' | translate}}</button>
        </div> -->
      </div>
    </div>
  </div>
</div>
