<div class="instagram-posts">
  <div class="back-btn"><a (click)="goBack()">
    <i class="pi pi-arrow-left me-2"></i><span>{{'BACK' | translate}}</span></a>
  </div>
  <div class="instagram-posts-header w-100">{{'VENUE.INSTAGRAM_POSTS' | translate | uppercase}}</div>
  <div class="instagram-posts-wrapper">
  <ng-container *ngFor="let p of instagramPosts">
    <div class="instagram-post-card d-flex flex-column justify-content-start">
      <label class="post-username">{{p.username}}</label>
      <img class="image-wrapper" src="{{p.media_url}}" alt="img-preview">
      <div class="post-caption" [innerHTML]="p.caption | highlight_hashtags"></div>
    </div>
  </ng-container>
  </div>
</div>
