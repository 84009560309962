<div class="div-products-container">
  <div style="width: 70vw; align-self: center;">
    <div class="products-header mt-4">
      <span class="title">{{'PRODUCT.WELCOME_TITLE' | translate | uppercase}}</span>
      <span class="desc">{{'PRODUCT.SOCIALITE_DESC' | translate}}</span>
    </div>
    <div class="w-100 special-offer-container" *ngIf="specialOffer">
      <app-special-offer [specialOffer]="specialOffer"></app-special-offer>
    </div>
    <div class="products-title">
      <span class="title">{{'PRODUCT.OUR_PRODUCTS' | translate | uppercase}}</span>
      <hr class="horizontal-line">
    </div>
    <div class="div-products">
      <div class="products-container" style="overflow: visible;">
        <p-progressSpinner class="w-50 text-center spinner-relative" *ngIf="spinner"></p-progressSpinner>
        <div *ngFor="let product of productList" class="mb-3">
          <ng-container *ngIf="product.id != ProjectEnum.TimelyWork.toString()">
          <div class="div-card-product">
            <div class="d-flex flex-wrap justify-content-center logo-container">
              <img class="product-logo" [src]="product.pictureUrl" alt="profile-picture">
            </div>
          </div>
          <div class="w-100 text-center">
            <a [routerLink]="'/products/' + product.id +'/details'" class="details-link">
              <span [innerHTML]="product.name | uppercase | remove_highlight"></span>
            </a>
          </div>         
          </ng-container>        
        </div>
       
      </div>
      <div class="w-100 special-offer-container mb-3 " >
      <app-trial-offer ></app-trial-offer>
      </div>
    </div>
    <div class="timelywork-container">
        <span class="desc">{{'PRODUCT.TRY_TIMELYWORK' | translate}}</span>
      <div class="logo-container">
        <a [routerLink]="'/products/' + ProjectEnum.TimelyWork  +'/details'" class="details-link">
          <img src="assets/images/temporary/timelywork-logo.png">
        </a>
      </div>
    </div>
  </div>
</div>
