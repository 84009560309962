import {Component, OnInit} from '@angular/core';
import {StripeService} from 'ngx-stripe';
import {switchMap} from 'rxjs';
import {PaymentType} from 'src/app/socialite-manager-panel/enums/payment-type.enum';
import {ProductPlanModel} from 'src/app/socialite-manager-panel/models/products/product-plan.model';
import {StripeCheckoutModel} from 'src/app/socialite-manager-panel/models/products/stripe-checkout.model';
import {PaymentService} from "../../../services/payments/payment.service";
import {DynamicDialogConfig} from "primeng/dynamicdialog";
import {SpecialOfferModel} from "../../../models/special-offer/special-offer.model";

@Component({
  selector: 'app-payment-dialog',
  templateUrl: './payment-dialog.component.html',
  styleUrls: ['./payment-dialog.component.scss']
})
export class PaymentDialogComponent implements OnInit {

   //#region Fields
  public spinner : boolean = false;
  public productPlan: ProductPlanModel;// | SpecialOfferModel;
  public currencyInfo: string = "den";
  public basis: number;
  public isSpecialOffer: boolean = false;
  //#endregion

  //#region Constructor
  constructor(
    private config: DynamicDialogConfig,
    private stripeService: StripeService,
    private paymentService: PaymentService,
  ) {
      if(this.config.data != null && this.config.data.productPlan) {
        this.productPlan = this.config.data.productPlan;
        this.isSpecialOffer = this.config.data.isSpecialOffer;
      }
  }

  ngOnInit(): void {
  }
  //#endregion

  //#region Public Methods
  public checkout(){
    if(this.basis == 1 || this.basis == 2){
      this.oneTimePayment();
    }
    if(this.basis == 3 || this.basis == 4){
      this.subscriptionBasedPayment()
    }
  }

  oneTimePayment(){
    const paymentCredentials = new StripeCheckoutModel();
    paymentCredentials.productId = this.productPlan.id;
    paymentCredentials.recurringTypeId = this.basis == 1 ? PaymentType.Monthly : PaymentType.Yearly;
    this.spinner=true;

    this.paymentService.getSessionForPayment(paymentCredentials)
      .pipe(
        switchMap((session: string) => {
          return this.stripeService.redirectToCheckout({ sessionId: session })
        })
      )
      .subscribe((result: any) => {
        this.spinner = false;
        if (result.error) {
          alert(result.error.message);
        }
      },error => {
         alert(error.message);
      });
  }

  subscriptionBasedPayment(){
    const paymentCredentials = new StripeCheckoutModel();
    paymentCredentials.productId = this.productPlan.id;
    paymentCredentials.recurringTypeId = this.basis == 3 ? PaymentType.Monthly : PaymentType.Yearly;
    this.spinner=true;

    this.paymentService.getSessionForSubscription(paymentCredentials)
      .pipe(
        switchMap((session: any) => {
          return this.stripeService.redirectToCheckout({ sessionId: session.id })
        })
      )
      .subscribe((result: any) => {
        this.spinner = false;
        if (result.error) {
          alert(result.error.message);
        }
      },error => {
        alert(error.message);
      });
  }
    //#endregion

}
