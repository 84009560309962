import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs";
import { HttpService } from "src/app/share-modul/services/common/http-service";
import { environment } from "src/environments/environment";
import { VenueModel } from "../../models/venues/venue.model";
import { DeleteGaleryImageModel } from "../../models/venues/delete-galery-image.model";
import { SearchVenueHelperModel } from "../../models/venues/search-venue-helper.model";
import { UpdateGaleryImageModel } from "../../models/venues/update-gallery.model";
import { CreateGaleryImageModel } from "../../models/venues/create-gallery.model";
import { ApplicationId } from "src/app/share-modul/enums/applicationid.enum";


const VENUE_API = environment.apiURL + '/api/business/Venue/';
const USER_API = environment.apiURL + '/api/business/User/';

@Injectable({
  providedIn: 'root'
})

export class ManageVenuesService {
  //#region Fields
  //#endregion

  //#region Constructor
  constructor(private _httpService: HttpService, private _httpClient: HttpClient) {
  }

  //#endregion

  //#region Public Methods
  listvenues(model: SearchVenueHelperModel) {
    var url = VENUE_API + "listvenues?PageNumber=" + model.pageNumber + "&PageSize=" + model.pageSize;
    return this._httpService.httpGet(url)
      .pipe(map(resposne => {
        return resposne;
      }));
  }

  addvenue(model: VenueModel) {

    var url = VENUE_API + "addvenue";
    const formData: FormData = new FormData();
    formData.append('Name', model.name);
    formData.append('Address', model.address);
    formData.append('FormatedAddress', model.address);
    formData.append('Phone', model.phone as string);
    formData.append('Email', model.email)
    formData.append('SendLocationNotifications', String(model.sendLocationNotifications))
    formData.append('City', model.city);
    formData.append('PostalCode', model.postalCode);
    formData.append('CountryCode', model.countryCode);
    formData.append('GooglePlaceId', model.googlePlaceId as string);
    formData.append('MaximumCapacity', model.maximumCapacity.toString())

    if (model.VenueImageFile) {
      formData.append('VenueImageFile', model.VenueImageFile as string);
    }
    if (model.description) {
      formData.append('Description', model.description);
    }
    if (model.userRules) {
      formData.append('UserRules', model.userRules);
    }
    if (model.latitude) {
      formData.append('Location.Latitude', model.latitude.toString());
    }
    if (model.longitude) {
      formData.append('Location.Longitude', model.longitude.toString());
    }
    if (model.instagramId) {
      formData.append('InstagramId', model.instagramId.toString());
    }
    if (model.instagramAppId) {
      formData.append('InstagramAppId', model.instagramAppId.toString());
    }
    if (model.instagramName) {
      formData.append('InstagramName', model.instagramName.toString());
    }
    if (model.instagramToken) {
      formData.append('InstagramToken', model.instagramToken.toString());
    }

    for (let i = 0; i < model.businessTypeIds?.length; i++) {
      formData.append(`BusinessTypesIds[${i}]`, model.businessTypeIds[i].toString());
    }
    for (let i = 0; i < model.applicationIds?.length; i++) {
      formData.append(`ApplicationIds[${i}]`, model.applicationIds[i].toString());
    }
    if(model.webSites){
      for (let i = 0; i < model.webSites.length; i++) {
        formData.append(`WebSites`, model.webSites[i] as string);
      }
    }
    return this._httpClient
      .post<any>(url, formData, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${localStorage.getItem("access_token") as string}`)
      })
      .pipe(map(response => {
        return response;
      }));
  }

  updatevenue(model: VenueModel, file: any) {
    var url = VENUE_API + "updatevenue";
    const formData: FormData = new FormData();
    formData.append('Id', model.id);
    formData.append('Name', model.name);
    formData.append('Address', model.address);
    formData.append('FormatedAddress', model.address);
    formData.append('Phone', model.phone as string);
    formData.append('Email', model.email)
    formData.append('SendLocationNotifications', String(model.sendLocationNotifications))
    formData.append('City', model.city);
    formData.append('PostalCode', model.postalCode);
    formData.append('CountryCode', model.countryCode);
    formData.append('GooglePlaceId', model.googlePlaceId as string);
    formData.append('MaximumCapacity', model.maximumCapacity.toString());

    if (file) {
      formData.append('VenueImageFile', file as string);
    }
    if (model.description) {
      formData.append('Description', model.description);
    }
    if (model.userRules) {
      formData.append('UserRules', model.userRules);
    }
    if (model.latitude) {
      formData.append('Location.Latitude', model.latitude.toString());
    }
    if (model.longitude) {
      formData.append('Location.Longitude', model.longitude.toString());
    }
    if (model.instagramId) {
      formData.append('InstagramId', model.instagramId.toString());
    }
    if (model.instagramAppId) {
      formData.append('InstagramAppId', model.instagramAppId.toString());
    }
    if (model.instagramName) {
      formData.append('InstagramName', model.instagramName.toString());
    }
    if (model.instagramToken) {
      formData.append('InstagramToken', model.instagramToken.toString());
    }
    for (let i = 0; i < model.businessTypeIds?.length; i++) {
      formData.append(`BusinessTypesIds[${i}]`, model.businessTypeIds[i].toString());
    }
    for (let i = 0; i < model.applicationIds?.length; i++) {
      formData.append(`ApplicationIds[${i}]`, model.applicationIds[i].toString());
    }
    if(model.applicationIds.includes(ApplicationId.Resy) && model.webSites){
      for (let i = 0; i < model.webSites.length; i++) {
        formData.append(`WebSites`, model.webSites[i] as string);
      }
    }
    return this._httpClient
      .post<any>(url, formData, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${localStorage.getItem("access_token") as string}`)
      })
      .pipe(map(response => {
        return response;
      }));
  }

  deletevenue(id: string) {
    var url = VENUE_API + 'deletevenue?venueId=' + id;
    return this._httpService.httpPost(url)
      .pipe(map(resposne => {
        return resposne;
      }));
  }

  getvenuedetails(id: string) {
    let url = VENUE_API + 'getvenuedetails?venueId=' + id;
    return this._httpService.httpGet(url)
      .pipe(map(response => {
        return response;
      }));
  }

  getallbusinesstypes() {
    var url = USER_API + "getallbusinesstypes";
    return this._httpService.httpGet(url)
      .pipe(map(resposne => {
        return resposne;
      }));
  }

  viewVenueGallery(venueId: string){
    let url = VENUE_API + 'viewvenuegallery?venueId=' + venueId;
    return this._httpService.httpGet(url)
      .pipe(map(resposne => {
        return resposne;
      }));
  }

  addVenueGalery(model: CreateGaleryImageModel){
    let url = VENUE_API + 'addvenuegalery';
    const formData: FormData = new FormData();
    formData.append('VenueId', model.venueId);
    for (let i = 0; i < model.Attachments?.length; i++) {
      formData.append(`Attachments`, model.Attachments[i] as string);
    }
    return this._httpClient
      .post<any>(url, formData, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${localStorage.getItem("access_token") as string}`)
      })
      .pipe(map(resposne => {
        return resposne;
      }));
  }

  updateVenueGalery(model: UpdateGaleryImageModel){
    let url = VENUE_API + 'updatevenuegalery';
    const formData: FormData = new FormData();
    formData.append('VenueId', model.venueId);

    for (let i = 0; i < model.ExistingPictureUrls?.length; i++) {
      formData.append(`ExistingPictureUrls`, model.ExistingPictureUrls[i] as string);
    }
    for (let i = 0; i < model.Attachments?.length; i++) {
      formData.append(`Attachments`, model.Attachments[i] as string);
    }
    return this._httpClient
      .post<any>(url, formData, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${localStorage.getItem("access_token") as string}`)
      })
      .pipe(map(resposne => {
        return resposne;
      }));
  }

  deleteImagesFromVenueGalery(model: DeleteGaleryImageModel) {
    var url = VENUE_API + 'deleteimagesfromvenuegalery';
    return this._httpService.httpPost(url, model)
      .pipe(map(resposne => {
        return resposne;
      }));
  }

  getInstagramId(accessToken: string){
    let url = "https://graph.facebook.com/v17.0/me/accounts?fields=id%2Cname%2Cinstagram_business_account&access_token=" + accessToken;
    return this._httpService.externalHttpGet(url)
      .pipe(map(response => {
        return response;
      }));
  }
  getInstagramUsername(instagramId: string, accessToken: string){
    let url = "https://graph.facebook.com/v17.0/" + instagramId + "?fields=username&access_token=" + accessToken;
    return this._httpService.externalHttpGet(url)
      .pipe(map(response => {
        return response;
      }));
  }

  getInstagramPostsForUser(instagramId: string, accessToken: string, limit?: number, after?: string){
    let url = "https://graph.facebook.com/v17.0/" + instagramId + "/tags?fields=caption%2Cmedia_url%2Cusername&access_token=" + accessToken;
    if (limit){
      url += "&limit=" + limit;
    }
    if (after){
      url += "&after=" + after;
    }
    return this._httpService.externalHttpGet(url)
      .pipe(map(response => {
        return response;
      }));
  }
  checkValidityAccessToken(accessToken: string){
    let url = "https://graph.facebook.com/debug_token?input_token=" + accessToken + "&access_token=" + accessToken;
    return this._httpService.externalHttpGet(url)
      .pipe(map(response => {
        return response;
      }));
  }
  exchangeShortTermTokenForLongTerm(clientId: string, clientSecret: string, shortTermAccessToken: string){
    let url = "https://graph.facebook.com/v17.0/oauth/access_token?grant_type=fb_exchange_token&client_id=" + clientId + "&client_secret=" + clientSecret + "&fb_exchange_token=" + shortTermAccessToken;
    return this._httpService.externalHttpGet(url)
      .pipe(map(response => {
        return response;
      }));
  }

  disconnectVenueFromInstagram(venueId: string){
    var url = VENUE_API + 'removeinstagramaccountfromvenue?venueId=' + venueId;
    return this._httpService.httpPost(url)
      .pipe(map(response => {
        return response;
      }));
  }

  //#endregion
}
